import React from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Table, Container, Nav, Form, Row, Col, Modal } from "react-bootstrap";
import {updateUser} from "../../../actions/auth";
import { getCustom } from "@actions/Administrator/management";

class UserRecomendations extends React.Component {

    state = {
        type: [],
        optionModal: false,
        error: null,
        is_loading: false
    };

    componentDidMount  = async () =>{
        await this.props.getCustom();
        this.setState({type: this.props.custom.brands});
    }

    onSelect = (e) =>{
        this.setState({type: this.props.custom[e]});
    }

    handleOptionModal = () =>{this.setState({optionModal: false})}

    openOptionModal = () =>{
        this.setState({optionModal: true});
    }

    render() {
        const { messages } = this.props;
        let custom = [];
        if(this.state.type !== null){
            custom = this.state.type;
        }

        return (
            <div className="flex-center full-height position-ref">
                <Container>
                    <h1>{messages['user_recomendations']}</h1>
                    <Nav fill variant="tabs" className="Custom_Card">
                      <Nav.Item>
                        <Nav.Link eventKey="brands" onSelect={this.onSelect}>{messages['brand']}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="equipments" onSelect={this.onSelect}>{messages['equipment']}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="models" onSelect={this.onSelect}>{messages['model']}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="services" onSelect={this.onSelect}>{messages['service']}</Nav.Link>
                      </Nav.Item>
                      </Nav> 
                    <Table hover variant="dark" className="Custom_Table" responsive>
                        <thead>
                                <tr>
                                <th>{messages['name']}</th>
                                <th>{messages['options']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {custom.map(item =>{
                                    return(
                                    <tr>
                                        <td>{item}</td>
                                        <td>
                                            <Button 
                                                className="Button_Padding"
                                                variant="outline-light"
                                                onClick={() => this.openOptionModal()}>
                                                    {messages['delete']}
                                            </Button>
                                        </td>
                                    </tr>
                                    );
                                })}
                            </tbody>
                        </Table>

                    </Container>

                    <Modal show={this.state.optionModal} onHide={this.handleOptionModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{messages['options']}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                <p>Do you want to aproove this?</p>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    custom: state.admin.management.custom,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    getCustom
})(UserRecomendations);



                        