import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";

const statusList = [
    { value: "CANCELED", key: "Canceled" },
    { value: "OPEN", key: "Open" },
    { value: "ADMIN_PENDING", key: "Admin pending" },
    { value: "CONFIRMED", key: "Confirmed" },
    { value: "IN_PROGRESS", key: "In progress" },
    { value: "TECHNICIAN_FINISHED", key: "Technician finished" },
    { value: "CLIENT_FINISHED", key: "Client finished" },
    { value: "COMPLETED", key: "Completed" },
    { value: "EXPIRED", key: "Expired" }
];

const ServiceChangeStatusTableModal = ({ messages, show, onHide, initialStatus, updateStatus }) => {
    const [serviceStatus, setServiceStatus] = useState(initialStatus);

    console.log("serviceStatus", serviceStatus);
    return (
        <Modal show={show} onHide={() => onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>Change service status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label>{messages["status"]}</Form.Label>
                <Form.Control
                    as="select"
                    name="serviceStatus"
                    onChange={e => setServiceStatus(e.target.value)}
                    value={serviceStatus}
                >
                    {statusList.map((Data, key) => {
                        return (
                            <option value={Data.value} key={Data.value}>
                                {Data.key}
                            </option>
                        );
                    })}
                </Form.Control>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide()}>
                    {messages["close"]}
                </Button>
                <Button
                    variant="primary"
                    disabled={initialStatus !== serviceStatus ? false : true}
                    onClick={() => {
                        updateStatus(serviceStatus);
                        onHide();
                    }}
                >
                    save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ServiceChangeStatusTableModal;
