import React from "react";
import { Form } from "react-bootstrap";

class Dashboard extends React.Component {
    render() {
        return (
            <div className="flex-center full-height position-ref">
                <Form>
                    <h1>Client</h1>
                </Form>
            </div>
        );
    }
}

export default Dashboard;
