import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Form, Button, Modal } from "react-bootstrap";
import {updateUser} from "../../../actions/auth";
import {create_form_data} from "../../../utils/routes";
import { API_CONSTANT_MAP } from "@utils/routes";
import { addPopper } from "@actions/popper";

class InviteClient extends React.Component {

    state = {
        email: null,
        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleClose = () =>{
        this.setState({show: false});
    };
    
    onMakeRequest = async () => {
        var re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        if (re.test(String(this.state.email))){       
            var data = {
                emails: [this.state.email],
                type: 1
            };  
            try {
                const response = await axios.post(API_CONSTANT_MAP.invite, data);
                if(response.status === 200){
                    this.setState({show: true});
                    this.props.addPopper({
                        title: "success",
                        message: "addedClient",
                        time: 6000,
                        level: "success"
                    });
                }
            } catch (err) {
                console.log(err.message);
            }
        } 
    };

    render() {
        const { messages, publicToken } = this.props;
        return (
            <div className="flex-center full-height position-ref">
                <Form>
                    <Form.Group>
                    <h3>{messages['invite_client']}</h3>
                      <Form.Control type="email" name="email" onChange={this.onChange} placeholder="Enter email" />
                        <div className="text-right">
                          <Button
                            variant="outline-light"
                            size="sm"
                            className="Button_Padding"
                            onClick={this.onMakeRequest}
                          >
                            {messages['invite']}
                          </Button>
                        </div>
                    </Form.Group>
                </Form>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Excess</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{messages['email_sent']}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        {messages['close']}
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    securityRequirements: state.auth.user,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    updateUser,
    addPopper
})(InviteClient);



                        