import React from "react";
import { connect } from "react-redux";
import { Button, Container, Table, Modal, Form, ButtonGroup, Col, Row } from "react-bootstrap";
import { getTechnicianCoefficients, putTechnicianCoefficients } from "@actions/Administrator/payments";
import { getSpecialty } from "@actions/Administrator/management";


class PaymentAdministration extends React.Component {

    state = {
      coefficient: null,
      idCoefficient: null,
      editCoefficientModal: false
    };

    componentDidMount = async () =>{
      if(this.props.coefficients.loaded == false){
        await this.props.getTechnicianCoefficients(this.props.coefficients.specialty_id,1);
        await this.props.getSpecialty();
      }
    };

    handleEditCoefficientModalOpen = (id) =>{    this.setState({ editCoefficientModal: true, idCoefficient: id }); };
    handleEditCoefficientModalClose = () =>{   this.setState({ editCoefficientModal: false });    };

    onChange = e => {
      this.setState({
          [e.target.name]: e.target.value
      });
    };

    changePage = async (symbol) =>{
      if(symbol === "+" && this.props.coefficients.meta.last_page !== this.props.coefficients.meta.current_page){
          await this.props.getTechnicianCoefficients((this.props.coefficients.meta.current_page + 1));
      }
      if(symbol === "-" && this.props.coefficients.meta.current_page > 1){
          await this.props.getTechnicianCoefficients((this.props.coefficients.meta.current_page - 1));
      }
  };

  editCoefficient = async () =>{
    let data = {
        coefficient: parseInt(this.state.coefficient) / 100
    };
    await this.props.putTechnicianCoefficients(this.state.idCoefficient, data);
    this.setState({editCoefficientModal: false});
};



    render() {
        const { messages, coefficients, specialties } = this.props;
        console.log(specialties);
        return (
            <div className="min-h-full flex flex-col justify-center align-items-center">
              <Container>    
                <Table hover variant="dark" className="Custom_Table" responsive>
                  <thead>
                    <tr>
                      <th>{messages['id']}</th>
                      <th>{messages['username']}</th>
                      <th>{messages['first_name']}</th>
                      <th>{messages['last_name']}</th>
                      <th>{messages['daily_rate']}</th>
                      <th>{messages['coefficient']}</th>
                      <th>{messages['options']}</th>
                    </tr>
                  </thead>
                  <tbody>
                    { coefficients.data.map((technician) => {
                      return(
                        <tr key={technician.id}>
                          <td>{technician.id}</td>
                          <td>{technician.username}</td>
                          <td>{technician.firstName}</td>
                          <td>{technician.lastName}</td>
                          <td>{technician.daily_rate}</td>
                          <td>{(technician.coefficient * 100).toFixed(0)}%</td>
                          <td>
                            <Button 
                              className="Button_Padding"
                              variant="outline-light"
                              onClick={() => this.handleEditCoefficientModalOpen(technician.id)}>
                              {messages['edit']}
                            </Button>
                          </td>
                        </tr>
                        )})}

                  </tbody>
                </Table>
                <Row>
                  <Col>
                    <ButtonGroup>
                      <Button variant="outline-light" onClick={() => this.changePage("-")}>{messages['prev']}</Button>
                      <Button variant="outline-light" onClick={() => this.changePage("+")}>{messages['next']}</Button>
                    </ButtonGroup>
                  </Col>
                  <Col>
                    <Form.Row>
                      <Form.Label className="white_title">{messages['specialties']}</Form.Label>
                          <Form.Control as="select" name="specialty" onChange={this.onChange}>
                            {specialties.data.map((Data) =>{
                              return(
                                <option value={Data.id} key = {Data.id}> {Data.name}</option>
                              )})}
                      </Form.Control>
                      <Button 
                        className="Button_Padding"
                        variant="outline-light"
                        onClick={() => this.handleEditCoefficientModalOpen(2)}>
                        {messages['edit']}
                      </Button>
                    </Form.Row>
                  </Col>
                </Row>

                    <Modal show={this.state.editCoefficientModal} onHide={this.handleEditCoefficientModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Tpm-Experts</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Label>{messages['coefficient']}</Form.Label>
                                <Form.Control type="number" name="coefficient" placeholder="0 - 100" min="0" max="100" onChange={this.onChange}/>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.editCoefficient}>
                                {messages['edit']}
                            </Button>
                            <Button variant="secondary" onClick={this.handleEditCoefficientModalClose}>
                                {messages['close']}
                            </Button>
                        </Modal.Footer>
                    </Modal>
            </Container>
          </div>
        );
    }
}

const mapStateToProps = state => ({
    coefficients: state.admin.payments.technician_coefficients,
    specialties: state.admin.management.specialties,
});

export default connect(mapStateToProps, {
  getTechnicianCoefficients,
  putTechnicianCoefficients,
  getSpecialty
})(PaymentAdministration);
 