import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import PopperCard from "./PopperCard";
import { POPPER } from "@actions/popper";
import { v4 } from "uuid";

const PopperStyled = styled.div`
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    max-width: 225px;
`;

const PopperWrapper = () => {
    const poppers = useSelector(state => state.popper.poppers);
    const messages = useSelector(state => state.lang.messages);
    const dispatch = useDispatch();

    const onClose = id => {
        dispatch({ type: POPPER.REMOVE_POPPER, id });
    };

    return (
        <PopperStyled>
            {poppers.map(p => {
                return (
                    <PopperCard
                        key={p.id}
                        title={messages[p.title]}
                        level={p.level}
                        message={messages[p.message]}
                        timeout={p.time}
                        onClose={() => onClose(p.id)}
                    />
                );
            })}
        </PopperStyled>
    );
};

export default PopperWrapper;
