import { combineReducers } from "redux";
import popperReducer from './popper';
import authReducer from "./auth";
import langReducer from "./lang";
import technicianReducer from "./technician";
import clientReducer from "./Client";
import admin from "./Administrator";

export default combineReducers({
    auth: authReducer,
    lang: langReducer,
    popper: popperReducer,
    tech: technicianReducer,
    client: clientReducer,
    admin: admin
});
