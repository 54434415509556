import axios from "axios";


export const LANGUAGE = {
    SET_LANGUAGES: "SET_LANGUAGES",
    ADD_LANGUAGE: "ADD_LANGUAGE",
    EDIT_LANGUAGE: "EDIT_LANGUAGE",
    DELETE_LANGUAGE: "DELETE_LANGUAGE",
    GET_PAGE: "GET_PAGE",
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: LANGUAGE.ERROR,
    error
});

export const getLanguages = (page) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_languages(page));
        dispatch({
            type: LANGUAGE.SET_LANGUAGES,
            languages: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const addLanguage = (data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.add_language, data);
        dispatch({
            type: LANGUAGE.ADD_LANGUAGE,
            language: response.data,
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};

export const editLanguage = (id, data) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.update_delete_language(id), data);
        dispatch({
            type: LANGUAGE.EDIT_LANGUAGE,
            language: response.data,
            id: id,
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};

export const deleteLanguage = (id) => async dispatch => {
    try {
        const response = await axios.delete(API_CONSTANT_MAP.update_delete_language(id));
        console.log(response.data);
        dispatch({
            type: LANGUAGE.DELETE_LANGUAGE,
            id: id,
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};



