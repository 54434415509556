import React from "react";
import Pdf from "@components/shared/icons/pdf";
const Document = ({ messages, link, type }) => {
    return (
        <div className="mb-4">
            <a href={link} target="_blank">
                <Pdf />
            </a>
        </div>
    );
};

export default Document;
