import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Form, Button, Container, Spinner, Row, Col } from "react-bootstrap";
import DataListInput from "react-datalist-input";
import { API_CONSTANT_MAP } from "@utils/routes";
import { addExperience } from "@actions/technician";
import { addPopper } from "@actions/popper";

class AddExperience extends React.Component {
    state = {
        initial_fetch: true,
        equipments: [],
        equipment_id: null,
        equipment_str: "",
        technologies: {},
        technology_id: null,
        technology_str: "",
        models: {},
        model_id: null,
        model_str: "",
        brands: {},
        brand_id: null,
        brand_str: "",
        is_loading: false,
        redirect: false
    };

    async componentDidMount() {
        try {
            const response = await axios.get(API_CONSTANT_MAP.equipment);
            const equipments = response.data.data;
            const [equipment] = equipments;

            this.setState({
                equipments,
                equipment_id: equipment.id
            });
            await this.fetchTechnologiesAndBrands(equipment.id);
        } catch (err) {}
        this.setState({ initial_fetch: false });
    }

    fetchTechnologiesAndBrands = async id => {
        try {
            const response = await axios.get(API_CONSTANT_MAP.tech_brands(id));
            const technologies = response.data.technologies;
            const [technology] = technologies;
            const brands = response.data.brands;
            const [brand] = brands;
            this.setState(prevState => ({
                technologies: {
                    ...prevState.technologies,
                    [id]: technologies
                },
                technology_id: technology && technology.id || null,
                brands: {
                    ...prevState.brands,
                    [id]: brands
                },
                brand_id: brand && brand.id || null
            }));
            await this.fetchModels(id, brand.id);
        } catch (err) {}
    };

    fetchModels = async (eid, bid) => {
        try {
            const response = await axios.get(API_CONSTANT_MAP.models(eid, bid));
            const models = response.data;
            const [model] = models;
            this.setState(prevState => ({
                models: {
                    ...prevState.models,
                    [`${eid}-${bid}`]: models
                },
                model_id: (model && model.id) || undefined
            }));
        } catch (err) {}
    };

    onChange = e => {
        this.setState({
            [e.type]: e.id
        });
    };

    onEquipmentChange = async e => {
        this.onChange(e);
        const { brands } = this.state;
        if (!brands[e.id]) {
            await this.fetchTechnologiesAndBrands(e.id);
            await this.fetchModels(this.state.equipment_id, this.state.brand_id);
        } else {
            const { technologies, models } = this.state;
            const [brand] = brands[e.id];
            const [technology] = technologies[e.id];
            const [model] = models[`${e.id}-${brand.id}`];

            this.setState({
                brand_id: (brand && brand.id) || undefined,
                technology_id: (technology && technology.id) || undefined,
                model_id: (model && model.id) || undefined
            });
        }
    };

    onBrandChange = async e => {
        this.onChange(e);
        const { models, equipment_id } = this.state;
        if (!models[`${equipment_id}-${e.id}`]) {
            await this.fetchModels(this.state.equipment_id, e.id);
        } else {
            const [model] = models[`${equipment_id}-${e.id}`];
            this.setState({
                model_id: (model && model.id) || undefined
            });
        }
    };

    onEquipmentInput = e => this.setState({ equipment_str: e, equipment_id: null });
    onTechnologyInput = e => this.setState({ technology_str: e, technology_id: null });
    onBrandInput = e => this.setState({ brand_str: e, brand_id: null });
    onModelInput = e => this.setState({ model_str: e, model_id: null });

    onMakeRequest = async () => {
        this.setState({ is_loading: true });
        const {
            equipment_id,
            equipment_str,
            technology_id,
            technology_str,
            brand_id,
            brand_str,
            model_id,
            model_str
        } = this.state;

        const data = {};

        if (equipment_id) data.equipment_id = equipment_id;
        else data.equipment_name = equipment_str;

        if (technology_id) data.technology_id = technology_id;
        else data.technology_name = technology_str;

        if (brand_id) data.brand_id = brand_id;
        else data.brand_name = brand_str;

        if (model_id) data.model_id = model_id;
        else data.model_name = model_str;


        await this.props.addExperience(data);
        this.props.addPopper({
            title: "success",
            message: "addedExperience",
            time: 6000,
            level: "success"
        });
        this.setState({ is_loading: false, redirect: true });
    };

    render() {
        const {
            initial_fetch,
            equipments,
            equipment_id,
            technologies,
            technology_id,
            brands,
            brand_id,
            models,
            model_id,
            is_loading,
            redirect
        } = this.state;
        const { messages } = this.props;
        const t = technologies[equipment_id] || [];
        const b = brands[equipment_id] || [];
        const m = models[`${equipment_id}-${brand_id}`] || [];

        if (redirect) {
            return <Redirect to="/t/skill" />;
        }

        return (
            <div className="min-h-full flex flex-col justify-center align-items-center">
                <h2 className="pt-4">{messages["newExperience"]}</h2>

                <Form>
                    {initial_fetch && <Spinner animation="border" variant="light" />}
                    {!initial_fetch && (
                        <Container className="form-fixed-width">
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="text-white">
                                            {messages["equipment"]}
                                        </Form.Label>
                                        <DataListInput
                                            items={equipments.map(item => {
                                                return {
                                                    label: item.name,
                                                    key: item.name,
                                                    id: item.id,
                                                    type: "equipment_id"
                                                };
                                            })}
                                            inputClassName="form-control form-control-md"
                                            onSelect={this.onEquipmentChange}
                                            onInput={this.onEquipmentInput}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="text-white">
                                            {messages["technology"]}
                                        </Form.Label>
                                        <DataListInput
                                            items={t.map(item => {
                                                return {
                                                    label: item.name,
                                                    key: item.name,
                                                    id: item.id,
                                                    type: "technology_id"
                                                };
                                            })}
                                            inputClassName="form-control form-control-md"
                                            onSelect={this.onChange}
                                            onInput={this.onTechnologyInput}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="text-white">
                                            {messages["brand"]}
                                        </Form.Label>
                                        <DataListInput
                                            items={b.map(item => {
                                                return {
                                                    label: item.name,
                                                    key: item.name,
                                                    id: item.id,
                                                    type: "brand_id"
                                                };
                                            })}
                                            inputClassName="form-control form-control-md"
                                            onSelect={this.onBrandChange}
                                            onInput={this.onBrandInput}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="text-white">
                                            {messages["model"]}
                                        </Form.Label>
                                        <DataListInput
                                            items={m.map(item => {
                                                return {
                                                    label: item.name,
                                                    key: item.name,
                                                    id: item.id,
                                                    type: "model_id"
                                                };
                                            })}
                                            inputClassName="form-control form-control-md"
                                            onSelect={this.onChange}
                                            onInput={this.onModelInput}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-right">
                                    {is_loading && <Spinner variant="light" animation="border" />}
                                    {!is_loading && (
                                        <Button
                                            variant="outline-light"
                                            size="sm"
                                            type="submit"
                                            onClick={this.onMakeRequest}
                                        >
                                            {messages["addExperience"]}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    )}
                </Form>
            </div>
        );
    }
}

export default connect(null, {
    addExperience,
    addPopper
})(AddExperience);
