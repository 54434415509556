import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Container, Table, Nav, Modal, Form, ButtonGroup } from "react-bootstrap";
import useService from "@hooks/useService";
import useExperience from "@hooks/useExperience";
import { useLocation } from "react-router";
import Moment from "moment";
import { FaMinusCircle, FaEdit } from "react-icons/fa";
import { Star } from "@components/icons/star";

const TechnicianList = props => {
    const messages = useSelector(state => state.lang.messages);

    const { getEng } = useService();

    const {
        equipment,
        fetchBrands,
        fetchBrandsEquipment,
        brand,
        fetchModels,
        model,
        fetchTechnologies,
        technologies,
        addBrand,
        editBrand,
        deleteBrand,
        addTech,
        editTech,
        deleteTech,
        addModel,
        editModel,
        deleteModel,
        loading
    } = useExperience();

    const equipmentId = props.route.match.params.id;
    const EquipmentInfo = equipment.filter(eq => eq.id == equipmentId);
    const [showBrandModal, setShowBrandModal] = useState(false);
    const [showEditBrandModal, setShowEditBrandModal] = useState(false);
    const [showDeleteBrandModal, setShowDeleteBrandModal] = useState(false);
    const [formBrandName, setFormBrandName] = useState("");
    const [formBrandNombre, setFormBrandNombre] = useState("");
    const [formTechName, setFormTechName] = useState("");
    const [formTechNombre, setFormTechNombre] = useState("");
    const [brandId, setBrandId] = useState(null);
    const [showTechnologyModal, setShowTechnologyModal] = useState(false);
    const [showEditTechnologyModal, setShowEditTechnologyModal] = useState(false);
    const [showDeleteTechnologyModal, setShowDeleteTechnologyModal] = useState(false);
    const [showModelModal, setShowModelModal] = useState(false);
    const [showEditModelModal, setShowEditModelModal] = useState(false);
    const [showDeleteModelModal, setShowDeleteModelModal] = useState(false);
    const [formModelName, setFormModelName] = useState("");
    const [formModelNombre, setFormModelNombre] = useState("");
    const [formbrandId, setFormBrandId] = useState(null);
    const [technologyId, setTechnologyId] = useState(null);
    const [modelId, setModelId] = useState(null);

    useEffect(() => {
        if (props.route.history.location) {
            async function fetchData() {
                await fetchBrandsEquipment(equipmentId);
            }
            fetchData();
        }
    }, []);

    async function addBrandFunction() {
        await addBrand({
            es_name: formBrandNombre,
            en_name: formBrandName,
            equipment_id: equipmentId
        });
        setShowBrandModal(false);
    }

    function openEditBrand(id) {
        setBrandId(id);
        setShowEditBrandModal(true);
    }

    function openDeleteBrand(id) {
        setBrandId(id);
        setShowDeleteBrandModal(true);
    }

    async function editBrandFunction() {
        await editBrand(
            {
                es: {
                    name: formBrandNombre
                },
                en: {
                    name: formBrandName
                }
            },
            brandId
        );
        setShowEditBrandModal(false);
    }

    async function deleteBrandFunction() {
        await deleteBrand(brandId);
        setShowDeleteBrandModal(false);
    }

    async function addTechnologyFunction() {
        await addTech({
            es_name: formTechNombre,
            en_name: formTechName,
            equipment_id: equipmentId
        });
        setShowTechnologyModal(false);
    }

    function openEditTechnology(id) {
        setTechnologyId(id);
        setShowEditTechnologyModal(true);
    }

    function openDeleteTechnology(id) {
        setTechnologyId(id);
        setShowDeleteTechnologyModal(true);
    }

    async function editTechnologyFunction() {
        await editTech(
            {
                es: {
                    name: formTechNombre
                },
                en: {
                    name: formTechName
                }
            },
            technologyId
        );
        setShowEditTechnologyModal(false);
    }

    async function deleteTechnologyFunction() {
        await deleteTech(technologyId);
        setShowDeleteModelModal(false);
    }

    async function addModelFunction() {
        await addModel({
            es_name: formModelNombre,
            en_name: formModelName,
            equipment_id: equipmentId,
            brand_id: formbrandId
        });
        setShowModelModal(false);
    }

    function openEditModelFunction(id) {
        setModelId(id);
        setShowEditModelModal(true);
    }

    function openDeleteModal(id) {
        setModelId(id);
        setShowDeleteModelModal(true);
    }

    async function editModelFunction() {
        await editModel(
            {
                es: {
                    name: formModelNombre
                },
                en: {
                    name: formModelName
                }
            },
            modelId
        );
        setShowEditModelModal(false);
    }

    async function deleteModalFunction() {
        await deleteModel(modelId);
        setShowDeleteModelModal(false);
    }

    async function fetchModel() {
        console.log(brandId);
        if (brandId !== null) {
            await fetchModels(equipmentId, brandId);
            console.log(model);
        }
    }

    return (
        <div className="min-h-full flex flex-col justify-center align-items-center">
            <h1>Equipment: {EquipmentInfo[0]?.name}</h1>
            <h1>Brands</h1>
            <div
                style={{
                    height: "auto",
                    minWidth: 500,
                    width: "auto"
                }}
            >
                <Table
                    hover
                    variant="dark"
                    className="Custom_Table"
                    responsive
                    style={{ overflowY: "auto" }}
                >
                    <thead>
                        <tr>
                            <th>{messages["id"]}</th>
                            <th>{messages["name"]}</th>
                            <th>{messages["created_at"]}</th>
                            <th>{messages["updated_at"]}</th>
                            <th>{messages["options"]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {brand.map(tech => (
                            <tr key={tech.id}>
                                <td>{tech.id}</td>
                                <td>{tech.name}</td>
                                <td>{Moment(tech.created_at).format("YYYY/MM/DD")}</td>
                                <td>{Moment(tech.updated_at).format("YYYY/MM/DD")}</td>
                                <td>
                                    <Button
                                        className="Button_Padding"
                                        variant="outline-light"
                                        onClick={() => openDeleteBrand(tech.id)}
                                    >
                                        <FaMinusCircle />
                                    </Button>
                                    <Button
                                        className="Button_Padding"
                                        variant="outline-light"
                                        onClick={() => openEditBrand(tech.id)}
                                    >
                                        <FaEdit />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <Button
                className="Button_Padding"
                variant="outline-light"
                onClick={() => setShowBrandModal(true)}
            >
                Add Brand
            </Button>
            <h1>Technologies</h1>
            <div
                style={{
                    height: "auto",
                    minWidth: 500,
                    width: "auto"
                }}
            >
                <Table hover variant="dark" className="Custom_Table">
                    <thead>
                        <tr>
                            <th>{messages["id"]}</th>
                            <th>{messages["name"]}</th>
                            <th>{messages["created_at"]}</th>
                            <th>{messages["updated_at"]}</th>
                            <th>{messages["options"]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {technologies.map(tech => (
                            <tr key={tech.id}>
                                <td>{tech.id}</td>
                                <td>{tech.name}</td>
                                <td>{Moment(tech.created_at).format("YYYY/MM/DD")}</td>
                                <td>{Moment(tech.updated_at).format("YYYY/MM/DD")}</td>
                                <td>
                                    <Button
                                        className="Button_Padding"
                                        variant="outline-light"
                                        onClick={() => openDeleteTechnology(tech.id)}
                                    >
                                        <FaMinusCircle />
                                    </Button>
                                    <Button
                                        className="Button_Padding"
                                        variant="outline-light"
                                        onClick={() => openEditTechnology(tech.id)}
                                    >
                                        <FaEdit />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            <Button
                className="Button_Padding"
                variant="outline-light"
                onClick={() => setShowTechnologyModal(true)}
            >
                Add Technology
            </Button>
            <h1>Get model</h1>
            <Form>
                <div style={{ display: "flex" }}>
                    <div style={{ padding: 10 }}>
                        <Form.Label style={{ color: "white" }}>Brand</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={value => setBrandId(value.currentTarget.value)}
                        >
                            <option value={null}>Elige una opcion</option>
                            {brand.map(br => (
                                <option key={br.id} value={br.id}>
                                    {br.name}
                                </option>
                            ))}
                        </Form.Control>
                    </div>

                    <Button
                        className="Button_Padding"
                        variant="outline-light"
                        onClick={() => fetchModel()}
                    >
                        Get Models
                    </Button>
                </div>
            </Form>

            <h1>Models</h1>
            <div
                style={{
                    height: "auto",
                    minWidth: 500,
                    width: "auto"
                }}
            >
                <Table
                    hover
                    variant="dark"
                    className="Custom_Table"
                    responsive
                    style={{ overflowY: "auto" }}
                >
                    <thead>
                        <tr>
                            <th>{messages["id"]}</th>
                            <th>{messages["name"]}</th>
                            <th>{messages["created_at"]}</th>
                            <th>{messages["updated_at"]}</th>
                            <th>{messages["options"]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {model.map(mod => (
                            <tr key={mod.id}>
                                <td>{mod.id}</td>
                                <td>{mod.name}</td>
                                <td>{Moment(mod.created_at).format("YYYY/MM/DD")}</td>
                                <td>{Moment(mod.updated_at).format("YYYY/MM/DD")}</td>
                                <td>
                                    <Button
                                        className="Button_Padding"
                                        variant="outline-light"
                                        onClick={() => openDeleteModal(mod.id)}
                                    >
                                        <FaMinusCircle />
                                    </Button>
                                    <Button
                                        className="Button_Padding"
                                        variant="outline-light"
                                        onClick={() => openEditModelFunction(mod.id)}
                                    >
                                        <FaEdit />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <Button
                className="Button_Padding"
                variant="outline-light"
                onClick={() => setShowModelModal(true)}
            >
                Add Model
            </Button>

            <Modal show={showBrandModal} onHide={() => setShowBrandModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{messages["add"]} Brand</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Name</h5>
                            <Form.Control
                                type="text"
                                name="english"
                                placeholder="name"
                                value={formBrandName}
                                onChange={value => setFormBrandName(value.target.value)}
                            />
                        </div>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Nombre</h5>
                            <Form.Control
                                type="text"
                                name="spanish"
                                placeholder="nombre"
                                value={formBrandNombre}
                                onChange={value => setFormBrandNombre(value.target.value)}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowBrandModal(false)}>
                        {messages["close"]}
                    </Button>
                    <Button variant="primary" onClick={() => addBrandFunction()}>
                        {messages["add"]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditBrandModal} onHide={() => setShowEditBrandModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{messages["edit"]} Brand</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Name</h5>
                            <Form.Control
                                type="text"
                                name="english"
                                placeholder="name"
                                value={formBrandName}
                                onChange={value => setFormBrandName(value.target.value)}
                            />
                        </div>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Nombre</h5>
                            <Form.Control
                                type="text"
                                name="spanish"
                                placeholder="nombre"
                                value={formBrandNombre}
                                onChange={value => setFormBrandNombre(value.target.value)}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditBrandModal(false)}>
                        {messages["close"]}
                    </Button>
                    <Button variant="primary" onClick={() => editBrandFunction()}>
                        {messages["add"]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteBrandModal} onHide={() => setShowDeleteBrandModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{messages["delete"]} Brand</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 style={{ color: "black" }}>Eliminar brand</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteBrandModal(false)}>
                        {messages["close"]}
                    </Button>
                    <Button variant="primary" onClick={() => deleteBrandFunction()}>
                        {messages["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showTechnologyModal} onHide={() => setShowTechnologyModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{messages["add"]} Technology</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Name</h5>
                            <Form.Control
                                type="text"
                                name="english"
                                placeholder="name"
                                value={formTechName}
                                onChange={value => setFormTechName(value.target.value)}
                            />
                        </div>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Nombre</h5>
                            <Form.Control
                                type="text"
                                name="spanish"
                                placeholder="nombre"
                                value={formTechNombre}
                                onChange={value => setFormTechNombre(value.target.value)}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowTechnologyModal(false)}>
                        {messages["close"]}
                    </Button>
                    <Button variant="primary" onClick={() => addTechnologyFunction()}>
                        {messages["add"]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditTechnologyModal} onHide={() => setShowEditTechnologyModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{messages["edit"]} Technology</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Name</h5>
                            <Form.Control
                                type="text"
                                name="english"
                                placeholder="name"
                                value={formTechName}
                                onChange={value => setFormTechName(value.target.value)}
                            />
                        </div>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Nombre</h5>
                            <Form.Control
                                type="text"
                                name="spanish"
                                placeholder="nombre"
                                value={formTechNombre}
                                onChange={value => setFormTechNombre(value.target.value)}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditTechnologyModal(false)}>
                        {messages["close"]}
                    </Button>
                    <Button variant="primary" onClick={() => editTechnologyFunction()}>
                        {messages["add"]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showDeleteTechnologyModal}
                onHide={() => setShowDeleteTechnologyModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{messages["delete"]} Technology</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 style={{ color: "black" }}>Eliminar technology</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteTechnologyModal(false)}>
                        {messages["close"]}
                    </Button>
                    <Button variant="primary" onClick={() => deleteTechnologyFunction()}>
                        {messages["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModelModal} onHide={() => setShowModelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{messages["add"]} Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Brand</h5>
                            <Form.Control
                                as="select"
                                onChange={value => setFormBrandId(value.currentTarget.value)}
                            >
                                <option value={null}>Elige una opcion</option>
                                {brand.map(br => (
                                    <option key={br.id} value={br.id}>
                                        {br.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Name</h5>
                            <Form.Control
                                type="text"
                                name="english"
                                placeholder="name"
                                value={formModelName}
                                onChange={value => setFormModelName(value.target.value)}
                            />
                        </div>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Nombre</h5>
                            <Form.Control
                                type="text"
                                name="spanish"
                                placeholder="nombre"
                                value={formModelNombre}
                                onChange={value => setFormModelNombre(value.target.value)}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModelModal(false)}>
                        {messages["close"]}
                    </Button>
                    <Button variant="primary" onClick={() => addModelFunction()}>
                        {messages["add"]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModelModal} onHide={() => setShowEditModelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{messages["edit"]} Technology</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Name</h5>
                            <Form.Control
                                type="text"
                                name="english"
                                placeholder="name"
                                value={formModelName}
                                onChange={value => setFormModelName(value.target.value)}
                            />
                        </div>
                        <div style={{ padding: 10 }}>
                            <h5 className="dark_title">Nombre</h5>
                            <Form.Control
                                type="text"
                                name="spanish"
                                placeholder="nombre"
                                value={formModelNombre}
                                onChange={value => setFormModelNombre(value.target.value)}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModelModal(false)}>
                        {messages["close"]}
                    </Button>
                    <Button variant="primary" onClick={() => editModelFunction()}>
                        {messages["add"]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModelModal} onHide={() => setShowDeleteModelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{messages["delete"]} Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 style={{ color: "black" }}>Eliminar model</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModelModal(false)}>
                        {messages["close"]}
                    </Button>
                    <Button variant="primary" onClick={() => deleteModalFunction()}>
                        {messages["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TechnicianList;
