import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
    fetchSkills,
    addRoot,
    removeRoot,
    addLabel,
    removeLabel,
    addProtocol,
    removeProtocol
} from "@actions/Administrator/technicianInvitation";
import Details from "@components/shared/skills/Details";

class SkillDetail extends React.Component {
    state = {
        skill: {},
        redirect: false
    };

    async componentDidMount() {
        if (!this.props.loaded) await this.props.fetchSkills(this.props.route.match.params.tech_id);
    }

    static getDerivedStateFromProps(props, state) {
        const { loaded } = props;

        if (!loaded) return null;
        const { route, skills } = props;
        const { id } = route.match.params;
        const skill = skills.find(skill => skill.id.toString() === id);

        if (!skill) {
            return {
                redirect: true
            };
        }

        return {
            skill
        };
    }

    handleAddRoot = (payload, data) => this.props.addRoot(payload, data);
    handleRemoveRoot = payload => this.props.removeRoot(payload);
    handleAddLabel = (payload, data) => this.props.addLabel(payload, data);
    handleRemoveLabel = payload => this.props.removeLabel(payload);
    handleAddProtocol = (payload, data) => this.props.addProtocol(payload, data);
    handleRemoveProtocol = payload => this.props.removeProtocol(payload);

    render() {
        const { skill, redirect } = this.state;
        const { loaded } = this.props;
        return (
            <>
                {!loaded && <Spinner animation="border" variant="light" />}
                {loaded && (
                    <Details
                        skill={skill}
                        technician={this.props.route.match.params.tech_id}
                        onAddedRoot={this.handleAddRoot}
                        onDeletedRoot={this.handleRemoveRoot}
                        onAddedLabel={this.handleAddLabel}
                        onDeletedLabel={this.handleRemoveLabel}
                        onAddedProtocol={this.handleAddProtocol}
                        onDeletedProtocol={this.handleRemoveProtocol}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    skills: state.admin.technician_skill.list,
    loaded: state.admin.technician_skill.loaded,
});

export default connect(mapStateToProps, {
    fetchSkills,
    addRoot,
    removeRoot,
    addLabel,
    removeLabel,
    addProtocol,
    removeProtocol
})(SkillDetail);
