import axios from "axios";


export const SERVICE = {
    TECHNICIAN_GET_SERVICES: "TECHNICIAN_GET_SERVICES",
    SET_STATUS: "SET_STATUS",
    GET_REQUEST_OPTIONS: "GET_REQUEST_OPTIONS",
    GET_TECHNICIANS: "GET_TECHNICIANS",
    CANCEL_SERVICE: "CANCEL_SERVICE",
    ERROR: "ERROR"
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: SERVICE.ERROR,
    error
});

export const getServices = (service) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.getServices(service));
        dispatch({
            type: SERVICE.TECHNICIAN_GET_SERVICES,
            service: response.data
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};


export const setStatus = (status) => async dispatch => {
    try {
        dispatch({
            type: SERVICE.SET_STATUS,
            status: status
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};


export const getRequestOptions = () => async dispatch => {
    try {
        const service = await axios.get(API_CONSTANT_MAP.service_options);
        const equipment = await axios.get(API_CONSTANT_MAP.machine_options);
        const specialty = await axios.get(API_CONSTANT_MAP.specialty_options);
        const brand = await axios.get(API_CONSTANT_MAP.brand_options);


        const Data = {
            Service: service.data,
            Equipment: equipment.data,
            Specialty: specialty.data,
            Brand: brand.data
        };
        dispatch({
            type: SERVICE.GET_REQUEST_OPTIONS,
            options: Data
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getTechnicians = (idService) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.technician_list(idService));
        dispatch({
            type: SERVICE.GET_TECHNICIANS,
            technicians: response.data,
            idService: idService
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const cancelService = (id) => async dispatch => {
    try {
        const response = await axios.delete(API_CONSTANT_MAP.cancel_service(id));
        dispatch({
            type: SERVICE.CANCEL_SERVICE,
            responce: response.data
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};
