import React from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Table, Container, Nav, Form, Row, Col } from "react-bootstrap";
import { getProfiles } from "@actions/Administrator/profiles";

class Technician extends React.Component {

    state = {
        error: null,
        document_status: undefined,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentDidMount = async () =>{
        await this.props.getProfiles("technician", 1);
    };

    onMakeRequest = async (id) =>{
        this.props.route.history.push("/a/user_information/technician/" + id);
    };

    changePage = async (symbol) =>{
        if(symbol === "+" &&  this.props.profiles.technician.meta.current_page < this.props.profiles.technician.meta.last_page){
            await this.props.getProfiles("technician", (this.props.profiles.technician.meta.current_page + 1));
        }
        if(symbol === "-" && this.props.profiles.technician.meta.current_page > 1){
            await this.props.getProfiles("technician",(this.props.profiles.technician.meta.current_page - 1));
        }
    };

    changeStatus = async (status) =>{
        if(status !== this.state.document_status){
            this.setState({document_status: status})
            await this.props.getProfiles("technician", (this.props.profiles.technician.meta.current_page), status);
        }
    }

    render() {
        const { messages } = this.props;
        return (
            <div className="flex-center full-height position-ref">
                <Container>
                    <Table hover variant="dark" className="Custom_Table" responsive>
                        <thead>
                                <tr>
                                    <th>{messages['id']}</th>
                                    <th>{messages['first_name']}</th>
                                    <th>{messages['last_name']}</th>
                                    <th>{messages['email']}</th>
                                    <th>{messages['document_status']}</th>
                                    <th>{messages['options']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.props.profiles.technician.data.map((profile, key) =>{
                                return(
                                    <tr key = {profile.id}>
                                        <td>{profile.id}</td>
                                        <td>{profile.firstName}</td>
                                        <td>{profile.lastName}</td>
                                        <td>{profile.email }</td>
                                        <td>{profile.document_status }</td>
                                        <td><Button 
                                            className="Button_Padding"
                                            variant="outline-light"
                                            onClick={() => this.onMakeRequest(profile.id)}>
                                                {messages['view_profile']}
                                            </Button> 
                                        </td>
                                    </tr>
                                )})}
                            </tbody>
                        </Table>
                        <div className="" style={{flex: 1, flexDirection: 'row'}}>
                            <ButtonGroup style={{padding: 5}}>
                                    <Button variant="outline-light" onClick={() => this.changePage("-")}>{messages['prev']}</Button>
                                    <Button variant="outline-light" onClick={() => this.changePage("+")}>{messages['next']}</Button>
                            </ButtonGroup>
                            <ButtonGroup style={{padding: 5}}>
                                    <Button variant="outline-light" onClick={() => this.changeStatus("ON_REVIEW")}>{messages['on_review']}</Button>
                                    <Button variant="outline-light" onClick={() => this.changeStatus("VALIDATED")}>{messages['validated']}</Button>
                                    <Button variant="outline-light" onClick={() => this.changeStatus("EXPIRED")}>{messages['expired']}</Button>
                            </ButtonGroup>
                        </div>

                    </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profiles: state.admin.profiles.profiles
});

export default connect(mapStateToProps, {
    getProfiles
})(Technician);



                        