import React from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import Marker from "@components/Marker";
import MapControl from "@components/MapControl";
import { Button, Spinner } from "react-bootstrap";
import { updateUser } from "@actions/auth";
import { create_form_data } from "@utils/routes";

class Map extends React.Component {
    state = {
        loaded: false,
        lat: null,
        lng: null,
        zoom: 14,
        center: {
            lat: null,
            lng: null
        },
        draggable: true,
        is_saving: false
    };

    static getDerivedStateFromProps(props, state) {
        const { user } = props;

        if (!user) return null;

        const { loaded } = state;

        if (loaded) return null;

        const { latitude, longitude } = user;

        return {
            lat: latitude,
            lng: longitude,
            loaded: true,
            center: {
                lat: latitude,
                lng: longitude
            }
        };
    }

    onSave = async () => {
        const { user } = this.props;
        const { lat, lng } = this.state;

        if (user.latitude !== lat || user.longitude !== lng) {
            this.setState({ is_saving: true });
            const data = {
                latitude: lat,
                longitude: lng
            };
            await this.props.updateUser(create_form_data(data), "technician");
            this.setState({ is_saving: false });
        }
    };

    onDragMarker = (_, __, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    };

    onClickMarker = () => {
        this.setState({ draggable: true });
    };

    render() {
        const { lat, lng, zoom, draggable, center, is_saving } = this.state;
        const { messages } = this.props;
        const key = process.env.MIX_GOOGLE_MAPS_KEY;
        const v = process.env.MIX_GOOGLE_MAPS_VER;

        return (
            <div className="flex-center full-height position-ref">
                <div className="flex flex-1 full-height">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key, v }}
                        center={center}
                        zoom={zoom}
                        onChildMouseDown={this.onDragMarker}
                        onChildMouseMove={this.onDragMarker}
                        onChildMouseUp={this.onClickMarker}
                        draggable={draggable}
                        onGoogleApiLoaded={({ map, maps }) => {
                            this.map = map;
                            this.maps = maps;
                            this.setState({ mapControlShouldRender: true });
                        }}
                        yesIWantToUseGoogleMapApiInternals
                    >
                        <Marker lat={lat} lng={lng} />
                        <MapControl
                            map={this.map || null}
                            controlPosition={
                                this.maps
                                    ? this.maps.ControlPosition.TOP_CENTER
                                    : null
                            }
                        >
                            <Button
                                variant="primary"
                                size="md"
                                onClick={this.onSave}
                            >
                                {messages["saveChanges"]}
                            </Button>
                        </MapControl>
                    </GoogleMapReact>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, {
    updateUser
})(Map);
