import { INVITATION } from "@actions/Administrator/invitationManagement";

const initialState = {
    technician: {}
};

export default function(state = initialState, action) {
    switch (action.type) {

        case INVITATION.CREATE_TECHNICIAN:
            {
                return {
                    ...state,
                    technician: action.technician
                }
            }

        default:
            return state;
    }
}
