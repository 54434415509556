import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Nav, Table, Container } from "react-bootstrap";
import Moment from "moment";

const Service = ({
    services = [],
    status,
    seeTechnicians,
    onSelect,
    cancelService,
    requestService,
    messages
}) => {

    return (
        <>
            <Container>
                <Nav fill variant="tabs" className="Custom_Card">
                    <Nav.Item>
                        <Nav.Link eventKey="0" onSelect={onSelect}>
                            {messages["canceled"]}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="1" onSelect={onSelect}>
                            {messages["open"]}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="2" onSelect={onSelect}>
                            {messages["waiting_confirmation"]}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="3" onSelect={onSelect}>
                            {messages["assigned"]}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="4" onSelect={onSelect}>
                            {messages["finished"]}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>

                <Table hover variant="dark" className="Custom_Table" responsive>
                    <thead>
                        <tr>
                            <th>{messages["model"]}</th>
                            <th>{messages["brand"]}</th>
                            <th>{messages["machine"]}</th>
                            <th>{messages["service"]}</th>
                            <th>{messages["specialty"]}</th>
                            <th>{messages["equipment_year"]}</th>
                            <th>{messages["created_at"]}</th>
                            <th>{messages["seeDetails"]}</th>
                            {status === "1" && <th>{messages["technician_list"]}</th>}
                            {(status === "1" || status === "2" || status === "3") && (
                                <th>{messages["cancel_service"]}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {services &&
                            services.map((service, key) => {
                                return (
                                    <tr key={service.id}>
                                        <td>
                                            {typeof service.model === "object"
                                                ? service.model.name
                                                : service.model}
                                        </td>
                                        <td>
                                            {typeof service.brand === "object"
                                                ? service.brand.name
                                                : service.brand}{" "}
                                        </td>
                                        <td>
                                            {typeof service.machine === "object"
                                                ? service.machine.name
                                                : service.machine}{" "}
                                        </td>
                                        <td>
                                            {typeof service.service === "object"
                                                ? service.service.name
                                                : service.service}{" "}
                                        </td>
                                        <td>
                                            {typeof service.specialty === "object"
                                                ? service.specialty.name
                                                : service.specialty}{" "}
                                        </td>
                                        <td>{service.equipment_year}</td>
                                        <td>
                                            {Moment(service.created_at).format("YYYY/MM/DD")}
                                        </td>
                                        <td>
                                            <Link to={`/c/service/${service.id}`}>
                                                <Button
                                                    variant="outline-light"
                                                    className="Button_Padding"
                                                >
                                                    {messages["seeDetails"]}
                                                </Button>
                                            </Link>
                                        </td>
                                        {status === "1" && (
                                            <td>
                                                <Button
                                                    variant="outline-light"
                                                    className="Button_Padding"
                                                    onClick={() =>
                                                        seeTechnicians(service.id)
                                                    }
                                                >
                                                    {messages["see_technicians"]}
                                                </Button>
                                            </td>
                                        )}
                                        {(status=== "1" ||
                                            status === "2" || status === "3") && (
                                            <td>
                                                <Button
                                                    variant="outline-light"
                                                    className="Button_Padding"
                                                    onClick={() =>
                                                        cancelService(service.id)
                                                    }
                                                >
                                                    {messages["cancel_service"]}
                                                </Button>
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </Container>
            <Button
                variant="outline-light"
                className="Button_Padding"
                onClick={requestService}
            >
                {messages["request_service"]}
            </Button>
        </>);
};

export default Service;