import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";

const UpdateRate = ({ rateDefault, onClose }) => {
    const messages = useSelector(state => state.lang.messages);

    const [show, setShow] = useState(true);
    const [rate, setRate] = useState(rateDefault);

    return (
        <Modal
            show={show}
            animation={true}
            onHide={() => {
                setShow(false);
                onClose();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{messages["updateDailyRate"]}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>{messages["dailyRate"]}</Form.Label>
                        <Form.Control
                            size="md"
                            type="text"
                            placeholder="20, 50, 100..."
                            name="daily_rate"
                            onChange={e => {
                                let value = e.target.value.replace(/\D/, "").replace(/^0*/g, "");
                                value = value === "0" ? "1" : value;
                                setRate(value);
                            }}
                            value={rate}
                        />
                    </Form.Group>
                </Form>
                <Button
                    onClick={() => {
                        setShow(false);
                        onClose({ rate });
                    }}
                >
                    {messages["saveChanges"]}
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateRate;
