import { combineReducers } from "redux";
import managementReducer from "./management";
import documentReducer from "./documents";
import profilesReducer from "./profiles";
import languagesReducer from "./language";
import paymentsReducer from "./payments";
import skillsReducer from "./skills";
import invitationReducer from "./invitationManagement";
import technicianExperienceReducer from "./technicianInvitation/experience";
import technicianSkillReducer from "./technicianInvitation/skill";
import clientSimulatorServiceReducer from "./serviceSimulator/client/service"
import typeOfServiceReducer from "./typeOfServices";

export default combineReducers({
    management: managementReducer,
    documents: documentReducer,
    profiles: profilesReducer,
    languages: languagesReducer,
    payments: paymentsReducer,
    skills: skillsReducer,
    invitation: invitationReducer,
    technician_experience: technicianExperienceReducer,
    technician_skill: technicianSkillReducer,
    client_service_simulator: clientSimulatorServiceReducer,
    type_of_services: typeOfServiceReducer,
});
