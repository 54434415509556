import color from "randomcolor";

/**
 * Convert a provided calendar by the server into the internal format
 * for the Calendar.
 *
 * @param {[{
 *  year: number,
 *  month: number,
 *  days: object
 * }]} calendar
 */
export const toJson = calendar => {
    const json = {};
    calendar.forEach(e => {
        const { year, month, days } = e;

        if (!json[year]) {
            json[year] = {};
        }

        if (!json[year][month]) {
            json[year][month] = days;
        }
    });

    return json;
};

/**
 * Merge two calendars
 *
 * @param {object} src
 * @param {object} over
 */
export const merge = (src, over) => {
    const calendar = Object.assign({}, src);

    Object.keys(over).forEach(year => {
        if (!calendar[year]) {
            calendar[year] = over[year];
            return;
        }

        Object.keys(over[year]).forEach(month => {
            if (!calendar[year][month]) {
                calendar[year][month] = over[year][month];
            } else {
                calendar[year][month] = {
                    ...calendar[year][month],
                    ...over[year][month]
                };
            }
        });
    });

    return calendar;
};

export const isPresent = (calendar, { year, month, day }) => {
    return calendar[year] && calendar[year][month] && calendar[year][month][day];
};

const colors = ["red", "blue", "green", "orange", "purple", "yellow", "pink"];
let colorCounter = 0;

export const randomColor = () => {
    const hue = colors[colorCounter];
    colorCounter += 1 % colors.length;

    return color({
        luminosity: "bright",
        hue
    });
};

export const extractColorsFromServices = schedule => {
    const colors = {};
    const service = /"sid":[0-9]+/g;
    const id = /[0-9]+/g;
    let arr;

    while ((arr = service.exec(schedule)) !== null) {
        const i = id.exec(arr[0]);
        if (colors[i] === undefined) {
            colors[i] = randomColor();
        }
        id.lastIndex = 0;
    }

    return colors;
};
