import React, { useState } from "react";
import { useSelector } from "react-redux";
import Pdf from "@components/shared/Pdf";
import { Image } from "react-bootstrap";

const File = ({ files, documents, keyInfo, publicToken }) => {
    const messages = useSelector(state => state.lang.messages);
    if(documents[keyInfo] != undefined){
        return (
            <div key={keyInfo + '1'} style={{marginTop: 10}}>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                {files.map((file) => {
                    if(file.mime_type == "image/jpeg"){
                        return <Image key={`/api/media/d/${keyInfo}/${file.name}/${publicToken}`} src={`/api/media/d/${keyInfo}/${file.name}/${publicToken}`} className="Passport" />
                    }
                    else{
                        return <Pdf key={`/api/media/d/${keyInfo}/${file.name}/${publicToken}`} link={`/api/media/d/${keyInfo}/${file.name}/${publicToken}`} type={"hello"} messages={messages} />
                    }
                })}
                </div>
                <div style={{marginTop: 10}}>
                    <div style={{color: '#F0F0F0'}}>Status: {documents[keyInfo].status}</div>
                    <div style={{color: '#F0F0F0'}}>Document uploaded at: {formatDate(documents[keyInfo].updated_at)}</div>
                </div>
            </div>
        );
    }
    else{
        return <div></div>
    }
};

const formatDate = date => {
    const newDate = new Date(date);
    return newDate.getFullYear() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getDate();
};

export default File;
