const card = {
    backgroundColor: "slategray",
    padding: "20px",
    borderRadius: "10px",
    margin: "20px",
    display: "flex",
    flexDirection: "column"
};

const flexBetween = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
};

const title = {
    fontSize: "2rem",
    color: "white"
};

const subtitle = {
    fontSize: "1.5rem",
    color: "white",
    marginTop: "1rem"
};

const key = {
    fontSize: "1rem",
    color: "white"
};

const data = {
    fontSize: "1.5rem",
    color: "white"
};

const flexRow = {
    display: "flex",
    flexDirection: "row",
    marginTop: "1rem"
};

const flexColumn = {
    width: "50%"
};

export default { card, flexBetween, title, subtitle, key, data, flexRow, flexColumn };
