import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const MetaButton = ({meta, changePage}) => {

    return (
        <ButtonGroup>
            {meta.current_page > 1 && (
                <>
                    <Button variant="outline-light" onClick={() => changePage(meta.current_page - 1)}>{'<'}</Button>
                    <Button variant="outline-light" onClick={() => changePage(1)}>1</Button>
                </>
            )}
            <Button variant="outline-light" disabled>{meta.current_page}</Button>
            {meta.current_page < meta.last_page && (
                <>
                    <Button variant="outline-light" onClick={() => changePage(meta.current_page + 1)}>{'>'}</Button>
                    <Button variant="outline-light" onClick={() => changePage(meta.last_page)}>{meta.last_page}</Button>
                </>
            )}
        </ButtonGroup>
    );
}

export default MetaButton;