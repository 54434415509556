import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import ServiceFormComponent from "@components/admin/services/request";
import useExperience from "@hooks/useExperience";
import { useHistory } from "react-router-dom";
import useService from "@hooks/useService";

const ServiceFormNew = () => {
    const messages = useSelector(state => state.lang.messages);

    const { equipment, fetchBrands, brand, fetchModels, model, loading } = useExperience();

    const { createService, specialties, fetchSpecialties } = useService();

    const history = useHistory();

    const [location, setLocation] = useState("");
    const [prodLines, setProdLines] = useState("");
    const [typeProduct, setTypeProduct] = useState("");
    const [specialty, setSpecialty] = useState("");
    const [_equipment, setEquipment] = useState("");
    const [_brand, setBrand] = useState("");
    const [_model, setModel] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startDateData, setStartDateData] = useState("");
    const [endDateData, setEndDateData] = useState("");

    useEffect(() => {
        async function fetch() {
            await fetchSpecialties();
        }

        fetch();
    }, []);

    async function updateEquipment(value) {
        setEquipment(value);
        await fetchBrands(value);
    }

    async function updateBrand(value) {
        setBrand(value);
        await fetchModels(_equipment, value);
    }

    async function next() {
        /*
        'brand_id' => 1,
        'model_id' => 1,
        'service_id' => 1,
        'equipment_id' => 1,
        'specialty_id' => 1,
        'equipment_year' => 2008,
        'description' => 'La maquina hace un ruido al prender y luego se apaga',
        'initial_date' => '15-11-2021',
        'final_date' => '20-11-2021',
        'wanted_hourly_rate' => 90,
        'coordinates' => '20.6962053,-103.3069464',
        */
        const data = {
            brand_id: _brand,
            model_id: _model,
            equipment_id: _equipment,
            specialty_id: specialty,
            service_id: 1,
            equipment_year: 2009,
            description: "hola",
            initial_date: startDateData,
            final_date: endDateData,
            wanted_hourly_rate: 10,
            coordinates: location
        };

        const serviceId = await createService(data);
        history.push({
            pathname: "/a/client_simulator/technician_list",
            search: `serviceId=${serviceId}`,
            state: { serviceId: serviceId }
        });
    }

    function formatDate(date, isStart) {
        const newDate = new Date(date);
        const day = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
        const month =
            newDate.getMonth() + 1 < 10 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth();
        const formatedDate = day + "-" + month + "-" + newDate.getFullYear();
        if (isStart) {
            setStartDate(date);
            setStartDateData(formatedDate);
            return;
        }

        setEndDate(date);
        setEndDateData(formatedDate);
    }

    return (
        <div className="min-h-full justify-center align-items-center">
            <ServiceFormComponent
                location={location}
                setLocation={value => setLocation(value)}
                prodLines={prodLines}
                setProdLines={value => setProdLines(value)}
                typeProduct={typeProduct}
                setTypeProduct={setTypeProduct}
                specialties={specialties}
                specialty={specialty}
                setSpecialty={value => setSpecialty(value)}
                equipment={_equipment}
                setEquipment={value => updateEquipment(value)}
                equipments={equipment}
                brand={_brand}
                setBrand={value => updateBrand(value)}
                brands={brand}
                model={_model}
                setModel={value => setModel(value)}
                models={model}
                startDate={startDate}
                setStartDate={date => formatDate(date, true)}
                endDate={endDate}
                setEndDate={date => formatDate(date, false)}
                sendRequest={() => next()}
            />
        </div>
    );
};

export default ServiceFormNew;
