import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const HeaderStyled = styled.header`
    display: flex;
    flex-direction: row;
    padding: 0.5rem;

    .indicators {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .btn {
            background: #3d6dcc;
            color: #fff;
            transition: all 300ms ease;
            border: 2px solid #3d6dcc;
            width: 3.25em;
            height: 3.25em;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            border-radius: 0;

            i {
                font-size: 0.75em;
            }

            &:first-child {
                border-top-left-radius: 0.25em;
                border-bottom-left-radius: 0.25em;
            }

            &:last-child {
                border-top-right-radius: 0.25em;
                border-bottom-right-radius: 0.25em;
            }

            &:hover {
                background: transparent;
                cursor: pointer;
                color: #3d6dcc;

                i {
                    color: #3d6dcc;
                }
            }
        }
    }

    .title {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        h1 {
            color: black;
            width: 100%;
            text-align: center;
            margin: 0;
        }
    }

    @media (max-width: 448px) {
        flex-direction: column;

        .indicators {
            justify-content: space-between;
        }

        .title {
            margin-top: 2em;
        }
    }
`;

const Header = ({ onPrevMonth, onNextMonth, title }) => {
    return (
        <HeaderStyled>
            <div className="indicators">
                <button className="btn" onClick={onPrevMonth}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button className="btn" onClick={onNextMonth}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
            <div className="title">
                <h1>{title}</h1>
            </div>
        </HeaderStyled>
    );
};

export default Header;
