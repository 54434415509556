import React from "react";
import { connect } from "react-redux";
import { Container, Spinner, Form, Row, Col, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { fetchServices, acceptService, rejectService } from "@actions/technician";
import Calendar from "@components/shared/calendar";
import MediaGallery from "@components/MediaGallery";
import VideoGallery from "@components/VideoGallery";
import GoogleMapReact from "google-map-react";
import Marker from "@components/Marker";
import MapControl from "@components/MapControl";

class ServiceDetail extends React.Component {
    state = {
        service: {},
        redirect: false,
        is_loading: false
    };

    async componentDidMount() {
        const { route } = this.props;
        const { status } = route.match.params;
        await this.props.fetchServices(status, this.props.services[status].loaded);
    }

    static getDerivedStateFromProps(props, state) {
        const { route, services } = props;
        const { status, id } = route.match.params;

        if (!services[status].loaded) {
            return null;
        }

        const service = services[status].data.find(s => s.id.toString() === id);

        if (!service) {
            return {
                redirect: true
            };
        }

        return {
            service
        };
    }

    onRejectClick = async () => {
        this.setState({ is_loading: true });
        await this.props.rejectService(this.state.service.id);
        if (this.props.error !== null) {
            alert(this.props.error);
            this.setState({ is_loading: false });
        }
    };

    onAcceptClick = async () => {
        this.setState({ is_loading: true });
        await this.props.acceptService(this.state.service.id);
        if (this.props.error !== null) {
            alert(this.props.error);
            this.setState({ is_loading: false });
        }
    };

    render() {
        const { redirect, service, is_loading } = this.state;
        const { route, services, messages, locale } = this.props;
        const { status } = route.match.params;
        const loaded = services[status].loaded;
        const key = process.env.MIX_GOOGLE_MAPS_KEY;
        const v = process.env.MIX_GOOGLE_MAPS_VER;

        if (redirect) {
            return <Redirect to="/t?status=3" />;
        }

        let year = null;
        let month = null;

        if (loaded) {
            [year] = Object.keys(service.dates);
            [month] = Object.keys(service.dates[year]) + 1;
        }

        return (
            <Container>
                <h1 className="reset-header text-white pt-4 mb-4">{messages["requestDetails"]}</h1>
                {!loaded && <Spinner animation="border" variant="light" />}
                {loaded && (
                    <Form>
                        <Row>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["service"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.service === "object"
                                                ? service.service.name
                                                : service.service
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["equipment"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.machine === "object"
                                                ? service.machine.name
                                                : service.machine
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["brand"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.brand === "object"
                                                ? service.brand.name
                                                : service.brand
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["model"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.model === "object"
                                                ? service.model.name
                                                : service.model
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["specialty"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.specialty === "object"
                                                ? service.specialty.name
                                                : service.specialty
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["equipmentYear"]}
                                    </Form.Label>
                                    <Form.Control value={service.equipment_year} readOnly={true} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["requestDescription"]}
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        value={service.description}
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                )}
                {loaded && (
                    <>
                        <MediaGallery resources={service.images} title={messages["images"]} />
                        <VideoGallery resources={service.videos} title={messages["videos"]} />
                        <h2 className="pt-2 mb-2">{messages["dates"]}</h2>
                        <Calendar
                            locale={locale}
                            dates={service.dates || {}}
                            showSidebar={false}
                            year={year}
                            month={month}
                        />
                        <h2 className="pt-4 mb-2">{messages["map"]}</h2>
                        <div className="map-h">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key, v }}
                                center={{
                                    lat: service.client.location.latitude,
                                    lng: service.client.location.longitude
                                }}
                                zoom={15}
                                draggable={false}
                                onGoogleApiLoaded={({ map, maps }) => {
                                    this.map = map;
                                    this.maps = maps;
                                    this.setState({
                                        mapControlShouldRender: true
                                    });
                                }}
                                yesIWantToUseGoogleMapApiInternals
                            >
                                <Marker
                                    lat={service.client.location.latitude}
                                    lng={service.client.location.longitude}
                                />
                                <MapControl
                                    map={this.map || null}
                                    controlPosition={
                                        this.maps ? this.maps.ControlPosition.TOP_CENTER : null
                                    }
                                />
                            </GoogleMapReact>
                        </div>
                        <br />
                    </>
                )}
                {loaded && status == 2 && (
                    <div className="text-right mb-4">
                        {is_loading && <Spinner variant="light" animation="border" size="sm" />}
                        {!is_loading && (
                            <>
                                <Button
                                    variant="outline-light"
                                    size="sm"
                                    className="mr-2"
                                    onClick={this.onRejectClick}
                                >
                                    {messages["rejectService"]}
                                </Button>
                                <Button
                                    variant="outline-light"
                                    size="sm"
                                    onClick={this.onAcceptClick}
                                >
                                    {messages["acceptService"]}
                                </Button>
                            </>
                        )}
                    </div>
                )}
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    services: state.tech.req_ser.services,
    locale: state.lang.locale,
    error: state.tech.req_ser.error
});

export default connect(mapStateToProps, {
    fetchServices,
    acceptService,
    rejectService
})(ServiceDetail);
