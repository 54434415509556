import axios from "axios";


export const SKILLS = {
    GET_SPECIALTY: "GET_SPECIALTY",
    ADD_SPECIALTY: "ADD_SPECIALTY",
    EDIT_SPECIALTY: "EDIT_SPECIALTY",
    DELETE_SPECIALTY: "DELETE_SPECIALTY",

    GET_ROOTS: "GET_ROOTS",
    ADD_ROOTS: "ADD_ROOTS",
    EDIT_ROOTS: "EDIT_ROOTS",
    DELETE_ROOTS: "DELETE_ROOTS",

    GET_LABELS: "GET_LABELS",
    ADD_LABELS: "ADD_LABELS",
    EDIT_LABELS: "EDIT_LABELS",
    DELETE_LABELS: "DELETE_LABELS",

    GET_PROTOCOLS: "GET_PROTOCOLS",
    ADD_PROTOCOLS: "ADD_PROTOCOLS",
    EDIT_PROTOCOLS: "EDIT_PROTOCOLS",
    DELETE_PROTOCOLS: "DELETE_PROTOCOLS",
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: SKILLS.ERROR,
    error
});

export const getSpecialty = (page) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_specialty(page));
        dispatch({
            type: SKILLS.GET_SPECIALTY,
            specialty: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const addSpecialty = (data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.add_specialty, data);
        dispatch({
            type: SKILLS.ADD_SPECIALTY,
            specialty: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const editSpecialty = (data, id) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.edit_specialty(id), data);
        dispatch({
            type: SKILLS.EDIT_SPECIALTY,
            specialty: response.data,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const deleteSpecialty = (id) => async dispatch => {
    try {
        await axios.delete(API_CONSTANT_MAP.delete_specialty(id));

        dispatch({
            type: SKILLS.DELETE_SPECIALTY,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getRoots = (id, page) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_roots(id, page));
        dispatch({
            type: SKILLS.GET_ROOTS,
            roots: response.data
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const addRoots = (data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.add_root, data);
        dispatch({
            type: SKILLS.ADD_ROOTS,
            root: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const editRoots = (id, data) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.edit_root(id), data);

        dispatch({
            type: SKILLS.EDIT_ROOTS,
            root: response.data,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const deleteRoots = (id) => async dispatch => {
    try {
        await axios.delete(API_CONSTANT_MAP.delete_root(id));

        dispatch({
            type: SKILLS.DELETE_ROOTS,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getLabels = (id, page) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_labels(id, page));
        dispatch({
            type: SKILLS.GET_LABELS,
            labels: response.data,
        });

    } catch (err) { dispatch(error(err.message)); }
};

export const addLabels = (data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.add_label, data);

        dispatch({
            type: SKILLS.ADD_LABELS,
            label: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const editLabels = (id, data) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.edit_label(id), data);

        dispatch({
            type: SKILLS.EDIT_LABELS,
            label: response.data,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const deleteLabels = (id) => async dispatch => {
    try {
        await axios.delete(API_CONSTANT_MAP.delete_label(id));

        dispatch({
            type: SKILLS.DELETE_LABELS,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getProtocols = (type, id, page) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_protocols(type, id, page));
        dispatch({
            type: SKILLS.GET_PROTOCOLS,
            protocols: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const addProtocols = (data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.add_protocol, data);

        dispatch({
            type: SKILLS.ADD_PROTOCOLS,
            protocol: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const editProtocols = (id, data) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.edit_protocol(id), data);

        dispatch({
            type: SKILLS.EDIT_PROTOCOLS,
            protocol: response.data,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const deleteProtocols = (id) => async dispatch => {
    try {
        await axios.delete(API_CONSTANT_MAP.delete_protocol(id));

        dispatch({
            type: SKILLS.DELETE_PROTOCOLS,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};



