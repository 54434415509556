import { EXPERIENCE_ADMINISTRATOR } from "@actions/Administrator/technicianInvitation/experience";

const initialState = {
    loaded: false,
    list: [],
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case EXPERIENCE_ADMINISTRATOR.FETCH_EXPERIENCES:
            return {
                ...state,
                list: action.experiences,
                loaded: true
            };
        case EXPERIENCE_ADMINISTRATOR.ADD_EXPERIENCE:
            return {
                ...state,
                list: [...state.list, action.experience]
            };
        case EXPERIENCE_ADMINISTRATOR.REMOVE_EXPERIENCE:
            return {
                ...state,
                list: state.list.filter(e => e.id.toString() !== action.id.toString())
            };
        case EXPERIENCE_ADMINISTRATOR.RESET:
            return {
                ...state,
                list: [],
                loaded: false
            };
        default:
            return state;
    }
}
