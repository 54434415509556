import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingScreen = () => {
    return (
        <main className="min-h-full flex justify-center align-items-center flex-col">
            <h1 className="text-white">Ingenear</h1>
            <Spinner animation="border" variant="light" />
        </main>
    );
};

export default LoadingScreen;
