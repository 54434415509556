import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner, Button, CardColumns, Card, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import UpdateRate from "@components/shared/skills/UpdateRate";
import AddSkill from "@components/shared/skills/AddSkill";
import { API_CONSTANT_MAP } from "@utils/routes";

const Skills = ({
    skills,
    loading,
    technician,
    onAddedSkill,
    onUpdatedRate,
    onDeletedSkill,
    onDetails
}) => {
    const messages = useSelector(state => state.lang.messages);

    // State to Add Skill
    const [addSkillModal, setAddSkillModal] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    // Share state
    const [id, setId] = useState(null);

    // State to Update Rate from Skill
    const [updateRateModal, setUpdateRateModal] = useState(false);
    const [rate, setRate] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);

    // State to Delete Skill
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async id => {
        setIsDeleting(true);
        await axios.delete(API_CONSTANT_MAP.skills.delete(id));
        onDeletedSkill(id);
        setIsDeleting(false);
    };

    const handleUpdateRate = async payload => {
        if (payload) {
            setIsUpdating(true);
            const { rate: daily_rate } = payload;
            const response = await axios.put(API_CONSTANT_MAP.skills.put_rate(id), { daily_rate });
            onUpdatedRate(payload, response.data);
            setIsUpdating(false);
        }

        setUpdateRateModal(false);
    };

    const handleAddSkill = async (payload = null) => {
        if (payload) {
            setIsAdding(true);
            const { specialty: specialty_id, root: root_id, rate: daily_rate, years } = payload;
            const response = await axios.post(API_CONSTANT_MAP.skills.post(technician), {
                specialty_id,
                root_id,
                daily_rate,
                years
            });
            onAddedSkill(payload, response.data);
            setIsAdding(false);
        }

        setAddSkillModal(false);
    };

    return (
        <>
            <div>
                <div className="flex flex-row justify-between align-items-center">
                    <h1 className="reset-header text-white pt-4 mb-4">{messages["specialties"]}</h1>

                    {isAdding && <Spinner variant="light" animation="border" />}
                    {!isAdding && (
                        <Button
                            variant="outline-light"
                            size="sm"
                            onClick={() => setAddSkillModal(true)}
                        >
                            {messages["addNewSpecialty"]}
                        </Button>
                    )}
                </div>
                {!loading && <Spinner animation="border" variant="light" />}
                <CardColumns>
                    {loading &&
                        skills &&
                        skills.map(skill => {
                            return (
                                <Card key={skill.id} border="light">
                                    <Card.Body>
                                        <Card.Title>{skill.specialty.name}</Card.Title>
                                        <Card.Text className="flex flex-row justify-between mb-0">
                                            <span className="mb-0">
                                                {messages["dailyRate"]}: {skill.daily_rate}
                                            </span>
                                            <Button
                                                size="sm"
                                                variant="success"
                                                onClick={() => {
                                                    setId(skill.id);
                                                    setRate(skill.daily_rate);
                                                    setUpdateRateModal(true);
                                                }}
                                            >
                                                {isUpdating &&
                                                id.toString() === skill.id.toString() ? (
                                                    <Spinner
                                                        size="sm"
                                                        variant="light"
                                                        animation="border"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon icon={faPencilAlt} />
                                                )}
                                            </Button>
                                        </Card.Text>
                                        <Card.Text>
                                            {messages["yearsOfExperience"]}: {skill.years}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Body className="flex justify-between pt-2">
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => {
                                                setId(skill.id);
                                                handleDelete(skill.id);
                                            }}
                                        >
                                            {isDeleting && id.toString() === skill.id.toString() ? (
                                                <Spinner
                                                    size="sm"
                                                    variant="light"
                                                    animation="border"
                                                />
                                            ) : (
                                                <FontAwesomeIcon icon={faTrash} />
                                            )}
                                        </Button>
                                        {onDetails && onDetails(skill)}
                                    </Card.Body>
                                </Card>
                            );
                        })}
                </CardColumns>
            </div>

            {updateRateModal && <UpdateRate rateDefault={rate} onClose={handleUpdateRate} />}
            {addSkillModal && <AddSkill technician={technician} onClose={handleAddSkill} />}
        </>
    );
};

export default Skills;
