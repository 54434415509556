import React from "react";
import axios from "axios";
import { Form, Col, Row, Button, Spinner, Container } from "react-bootstrap";
import { API_CONSTANT_MAP } from "@utils/routes";

class ForgotPassword extends React.Component {
    state = {
        sent: false,
        is_loading: false,
        email: "",
        error: null
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onMakeRequest = async e => {
        e.preventDefault();
        this.setState({ is_loading: true, error: null });

        const data = {
            email: this.state.email
        };

        const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

        if (regex.test(data.email)) {
            try {
                const response = await axios.post(API_CONSTANT_MAP.forgot, data);
                this.setState({ sent: true });
            } catch (err) {
                console.log(err.response.data);
            }
        } else {
            this.setState({ error: "The email provided is not valid" });
        }

        this.setState({ is_loading: false });
    };

    render() {
        const { sent, is_loading, error } = this.state;
        return (
            <Form>
                <h1 className="text-center text-white mb-4">Forgot Password</h1>
                <Container className="form-fixed-width">
                    <Row>
                        <Col
                            md={{
                                span: 6,
                                offset: 3
                            }}
                        >
                            <Form.Group>
                                <Form.Label className="text-white">Email Address</Form.Label>
                                <Form.Control
                                    size="md"
                                    type="text"
                                    name="email"
                                    onChange={this.onChange}
                                />
                                {error && <p className="text-white mt-2">{error}</p>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            md={{
                                span: 6,
                                offset: 3
                            }}
                            className="text-right"
                        >
                            {is_loading && <Spinner variant="light" animation="border" />}
                            {!is_loading && !sent && (
                                <Button
                                    variant="outline-light"
                                    size="sm"
                                    type="submit"
                                    onClick={this.onMakeRequest}
                                >
                                    Send e-mail
                                </Button>
                            )}
                            {sent && (
                                <p className="text-white">
                                    If the email exists, you should receive an email in the next few
                                    minutes.
                                </p>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Form>
        );
    }
}

export default ForgotPassword;
