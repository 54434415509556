import { SERVICES } from "@actions/Administrator/typeOfServices";

const initialState = {
    services: {
        data: [],
        meta: [],
        loaded: false
    },
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {

            case SERVICES.ADMIN_GET_TYPE_SERVICE:
            {
                return {
                    ...state,
                    services:{
                        ...state.services,
                        data: action.services.data,
                        meta: action.services.meta,
                        loaded: true
                    }
                };
            }

            case SERVICES.ADMIN_ADD_TYPE_SERVICE:
                {
                    if(state.services.data.length < 5){
                        return{
                            ...state,
                            services: {
                                ...state.services,
                                data: [...state.services.data, action.services]
                            }
                        }
                    }
                    else{
                        if(state.services.meta.current_page === state.services.meta.last_page){
                            return{
                                ...state,
                                services: {
                                    ...state.services,
                                    meta : {
                                        ...state.services.meta,
                                        last_page: state.services.meta.last_page + 1
                                    }
                                }
                            }
                        }
                        else{
                            return{
                                ...state
                            }
                        }   
                    }
                }

            case SERVICES.ADMIN_EDIT_TYPE_SERVICE:
                {
                    const updateIndex = state.services.data.findIndex(data => data.id == action.id);
                    let tempData = Array.from(state.services.data);
                    tempData[updateIndex] = action.services;
                    return{
                        ...state,
                        services: {
                            ...state.services,
                            data: tempData
                        }
                    }
                }

            case SERVICES.DELETE_SPECIALTY:
                {
                    return{
                        ...state,
                        services: {
                            ...state.services,
                            data: state.services.data.filter(data => data.id !== action.id)
                        }
                    }
                }            
              
        default:
            return state;
    }
}
