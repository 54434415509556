import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";
import { useSelector } from "react-redux";

export default function useRequestService(id) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const publicToken = useSelector(state => state.auth.publicToken);

    useEffect(() => {
        fetchRequestService();
    }, [id]);

    const fetchRequestService = useCallback(async () => {
        try {
            const response = await axios.get(API_CONSTANT_MAP.request_services.find(id));
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [id]);

    const updateStatus = useCallback(
        async status => {
            try {
                const response = await axios.put(
                    API_CONSTANT_MAP.request_services.update_status(id),
                    { status }
                );
                console.log("DATA on update status", response.data);
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        },
        [id]
    );

    return {
        loading,
        data,
        updateStatus,
        publicToken
    };
}
