import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Stars = ({ stars, onClick, ...props }) => {
    let active = props.active || "#F2B600",
        inactive = props.inactive || "#F2F2F2",
        size = props.size || "1x";

    return Array(5)
        .fill(null)
        .map((_, i) => {
            const pos = i + 1;
            const color = pos <= stars ? active : inactive;
            return (
                <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    color={color}
                    size={size}
                    className="cursor-pointer"
                    onClick={() => {
                        if (onClick) {
                            onClick(pos);
                        }
                    }}
                />
            );
        });
};

export default Stars;
