import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Button, Spinner } from "react-bootstrap";
import { updateToken } from "@actions/auth";
import { setLanguage } from "@actions/lang";

class Language extends React.Component {
    state = {
        langs: [
            {
                key: 0,
                abbr: "en"
            },
            {
                key: 1,
                abbr: "es"
            }
        ],
        lang: {
            changed: false,
            current: 0,
            initial: 0
        },
        is_saving: false,
        disabled: true
    };

    componentDidMount() {
        const initial = this.state.langs.findIndex(
            lang => lang.abbr === this.props.locale
        );

        this.setState(prevState => ({
            lang: {
                ...prevState.lang,
                initial,
                current: initial
            }
        }));
    }

    setLanguage = async pos => {
        const { lang } = this.state;
        const changed = parseInt(lang.initial) !== parseInt(pos);
        this.setState(prevState => ({
            lang: {
                ...prevState.lang,
                current: parseInt(pos),
                changed
            }
        }));
    };

    shouldBeEnable = () => {
        return this.state.lang.changed;
    };

    onChange = e => {
        this.setLanguage(e.target.value);
        this.setState({
            disabled: this.shouldBeEnable()
        });
    };

    onSave = async () => {
        this.setState({ is_saving: true });
        const { lang, langs } = this.state;
        if (lang.changed) {
            await this.props.updateToken(
                this.props.rol,
                this.props.token,
                langs[lang.current].abbr
            );
            await this.props.setLanguage(
                langs[lang.current].abbr,
                this.props.rol
            );
            this.setState(prevState => ({
                lang: {
                    ...prevState.lang,
                    initial: lang.current,
                    changed: false
                },
                disabled: true
            }));
            this.props.onSave();
        }
        this.setState({ is_saving: false });
    };

    render() {
        const { messages } = this.props;
        const { lang, langs, disabled, is_saving } = this.state;
        return (
            <>
                <h1 className="mt-4 mb-2">{messages["chooseYourLanguage"]}</h1>
                <Form>
                    {langs.map(l => {
                        return (
                            <Form.Check
                                key={l.key}
                                type="radio"
                                label={`${messages[`lang.${l.abbr}`]}`}
                                name="lang"
                                className="text-white"
                                value={l.key}
                                checked={l.key === lang.current}
                                onChange={this.onChange}
                            />
                        );
                    })}
                </Form>
                <div className="text-right">
                    {is_saving && (
                        <Spinner variant="light" animation="border" size="sm" />
                    )}
                    {!is_saving && (
                        <Button
                            size="sm"
                            variant="outline-light"
                            disabled={disabled}
                            onClick={this.onSave}
                        >
                            {messages["saveChanges"]}
                        </Button>
                    )}
                </div>
            </>
        );
    }
}

Language.propTypes = {
    onSave: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    messages: state.lang.messages,
    locale: state.lang.locale,
    rol: state.auth.rol,
    token: state.auth.token
});

export default connect(mapStateToProps, {
    updateToken,
    setLanguage
})(Language);
