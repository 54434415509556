import { FY_FORMAT } from "./Calendar";
import moment from "moment";

/**
 * @param {moment.Moment} m Moment Instance
 * @param {boolean} offset Whether the day is part of the current month or not
 *
 * @return {object}
 */
export const createDay = (m, offset) => {
    return {
        day: m.date(),
        month: m.month(),
        year: m.year(),
        dateString: m.format(FY_FORMAT),
        offset
    };
};

/**
 * @param {*} year
 * @param {*} month
 */
export const previousMonthOffset = (year, month) => {
    const arr = [];

    let m = moment().month(month - 1);
    const days = m.daysInMonth();

    const offset = moment()
        .year(year)
        .month(month)
        .startOf("month")
        .day();
    let initial = days - offset + 1;
    m = m.date(initial);
    for (; initial <= days; ++initial) {
        arr.push(createDay(m, true));
        m = m.add(1, "day");
    }

    return arr;
};

/**
 * @param {number} year Current Year
 * @param {number} month Current Month
 */
export const currentMonth = (year, month) => {
    const arr = [];

    let m = moment()
        .year(year)
        .month(month)
        .startOf("month");
    const days = m.daysInMonth();
    for (let i = 1; i <= days; ++i) {
        arr.push(createDay(m, false));
        m = m.add(1, "day");
    }

    return arr;
};

/**
 * @param {number} year Current Year
 * @param {number} month Current Month
 * @param {Array} calendar Current Calendar Array in construction
 */
export const nextMonthOffset = (year, month, calendar) => {
    const arr = [];
    let m = moment()
        .year(year)
        .month(month + 1)
        .date(1);

    while (calendar.length + arr.length < 42) {
        arr.push(createDay(m, true));
        m = m.add(1, "day");
    }

    return arr;
};

/**
 * Check if date is present in a given calendar. Can be from a technician or
 * a service.
 *
 * It returns one of the next values:
 *  null - Date does not exists
 *  false - Date exists and is marked as a open day
 *  true - Date exists and is marked as a working day
 *
 * @param {object} dates Calendar to check against
 * @param {object} date Date to check
 * @return {null|boolean}
 */
export const isMarked = (dates, date) => {
    const { day, month, year } = date;

    if (!dates[year]) return null;
    if (!dates[year][month]) return null;
    if (!dates[year][month][day]) return null;
    if (dates[year][month][day].s === 0) return null;
    if (dates[year][month][day].s === 1) return false;

    return true;
};

export const retrieveColor = (colors, dates, date) => {
    const { day, month, year } = date;
    const { sid } = dates[year][month][day];
    return colors[sid];
};
