import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";

export const SKILL_ADMINISTRATOR = {
    ADD_SKILL: "ADMIN_ADD_SKILL",
    ADD_ROOT: "ADMIN_ADD_ROOT",
    REMOVE_ROOT: "ADMIN_REMOVE_ROOT",
    REMOVE_SKILL: "ADMIN_REMOVE_SKILL",
    ADD_LABEL: "ADMIN_ADD_LABEL",
    REMOVE_LABEL: "ADMIN_REMOVE_LABEL",
    ADD_PROTOCOL: "ADMIN_ADD_PROTOCOL",
    REMOVE_PROTOCOL: "ADMIN_REMOVE_PROTOCOL",
    FETCH_SKILLS: "ADMIN_FETCH_SKILLS",
    UPDATE_DAILY_RATE: "ADMIN_UPDATE_DAILY_RATE",
    RESET: "ADMIN_RESET_SKILLS",
    ERROR: "ADMIN_ERROR"
};

const error = error => ({
    type: SKILL_ADMINISTRATOR.ERROR,
    error
});

export const fetchSkills = id => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.skills.get(id));

        dispatch({
            type: SKILL_ADMINISTRATOR.FETCH_SKILLS,
            skills: response.data
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const addSkill = skill => dispatch =>
    dispatch({
        type: SKILL_ADMINISTRATOR.ADD_SKILL,
        skill
    });

export const updateRate = data => dispatch => dispatch({ type: SKILL_ADMINISTRATOR.UPDATE_DAILY_RATE, data });

export const deleteSkill = id => dispatch => dispatch({ type: SKILL_ADMINISTRATOR.REMOVE_SKILL, id });

export const addRoot = (payload, root) => dispatch =>
    dispatch({
        type: SKILL_ADMINISTRATOR.ADD_ROOT,
        root,
        skill_id: payload.skill_technician_id
    });

export const removeRoot = payload => dispatch =>
    dispatch({
        type: SKILL_ADMINISTRATOR.REMOVE_ROOT,
        skill_id: payload.skill_id,
        root_id: payload.root_id
    });

export const addProtocol = (payload, data) => dispatch =>
    dispatch({
        type: SKILL_ADMINISTRATOR.ADD_PROTOCOL,
        data,
        mode: payload.mode,
        skill_id: payload.skill_id,
        root_id: payload.root_id,
        label_id: payload.label_id
    });

export const removeProtocol = payload => dispatch =>
    dispatch({
        type: SKILL_ADMINISTRATOR.REMOVE_PROTOCOL,
        mode: payload.mode,
        skill_id: payload.skill_id,
        root_id: payload.root_id,
        label_id: payload.label_id,
        protocol_id: payload.protocol_id
    });

export const addLabel = (payload, data) => dispatch =>
    dispatch({
        type: SKILL_ADMINISTRATOR.ADD_LABEL,
        data,
        skill_id: payload.skill_id,
        root_id: payload.root_id
    });

export const removeLabel = payload => dispatch =>
    dispatch({
        type: SKILL_ADMINISTRATOR.REMOVE_LABEL,
        skill_id: payload.skill_id,
        root_id: payload.root_id,
        label_id: payload.label_id
    });

export const resetSkills = () => dispatch => {
    dispatch({ type: SKILL_ADMINISTRATOR.RESET });
};
