import axios from "axios";

export const LINES = {
    GET_LINES: "GET_LINES",
    ERROR: "ERROR"
};

export const PROFILE_LINES = {
    ADD_LINES: "ADD_LINES",
    DEL_LINES: "DEL_LINES",
    ERROR: "ERROR"
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: LINES.ERROR,
    error
});

export const getLines = () => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.lines);
        dispatch({
            type: LINES.GET_LINES,
            lines: response.data
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const addLines = (data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.lines, data);
        dispatch({
            type: PROFILE_LINES.ADD_LINES,
            lines: response.data,
            idBusiness: data.business_id
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};


export const deleteLines = (data) => async dispatch => {
    try {
        const response = await axios.delete(API_CONSTANT_MAP.delLines, {data} );
        dispatch({
            type: PROFILE_LINES.DEL_LINES,
            lines: data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};
