import React from "react";
import styles from "./styles";

const ServiceClientCard = ({ firstName, lastName, email }) => {
    return (
        <div style={styles.card}>
            <div style={styles.title}>Client information</div>
            <div>
                <div style={styles.key}>Name:</div>
                <div style={styles.data}>{`${firstName} ${lastName}`}</div>
            </div>
            <div>
                <div style={styles.key}>Email:</div>
                <div style={styles.data}>{email}</div>
            </div>
        </div>
    );
};

export default ServiceClientCard;
