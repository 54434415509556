import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Form, Button, Accordion, Card, Modal } from "react-bootstrap";
import {create_form_data} from "../../../utils/routes";
import { addBusinessRotation } from "@actions/Client/businessRotation";
import { getBusinessRotation } from "@actions/Client/businessRotation";
import { deleteBusinessRotation} from "@actions/Client/businessRotation";
import { getLines, addLines, deleteLines } from "@actions/Client/lines";
import { FaMinusCircle } from 'react-icons/fa';
import { addPopper } from "@actions/popper";

import { Link } from "react-router-dom";
class BusinessRotation extends React.Component {
    state = {
        linesNumber: null,
        Line: null,
        idAdd: null,
        error: null,
        is_loading: false,
        selects: 0,
        selectDin: {},
        options: [],
        selectedValue: 1,
        show: false,
    };

    handleClose = () => { this.setState({show: false})};
    handleShow = (id) => { 
    this.setState({idAdd: id});
    this.setState({show: true})};

    componentDidMount =  async () =>{

        await this.props.getBusinessRotation();
        await this.props.getLines();
        if(this.props.businessTable !== null){
            this.setState({ options: this.props.businessTable });
            let options = [];
            options = this.state.options.filter(e => e.status === 0);
        }
    };

    extraSelects = () =>{
        if(this.props.businessTable !== null){
            let options = [];
            options = this.state.options.filter(e => e.status === 0);
            if(this.state.selects > 0){
                let selects = []
                for(let i=0;  i < this.state.selects; i++){
                    selects.push(
                        <Form.Control as="select" value={this.state.selectedValue}  className="Button_Padding" name={"selectDin" + (i + 1)} onChange={this.handleChange} custom>
                            <option></option>
                            {options && options.map(function(item,key) {
                                return(
                                    <option value={item.id}>{item.name}</option>
                                    )})}
                        </Form.Control>
                    )
                }
                return selects;
            }
            else{
                return
            }
        }
        else{
            return
        }
    };

    changeNumOfSelects = (simbol) =>{
        let number = this.state.selects;
        const options = this.state.options.filter(e => e.status === 0);
        if(simbol === '-'){
            if(number > 0){
                var data = this.state.selectDin;
                const string = "selectDin" + number;
                delete data[string];
                this.setState({selectDin: data});
                number--;
                this.setState({selects: number});
            }
        }
        if(simbol === '+' && (options.length - 1) > number){
            number++;
            this.setState({selects: number});
        }
    };

    handleChange = (event) =>{
        var data = this.state.selectDin;
        if(event.target.value !== ""){
            data[event.target.name] = event.target.value;
        }
        else{
            delete data[event.target.name];
        }
        this.setState({selectDin: data});
    };

    onMakeRequest = async () => {
        if(Object.entries(this.state.selectDin).length === 0){
            return
        }
        let data = {
            rotations: Object.values(this.state.selectDin)
        };
        let options = this.state.options;
        data.rotations = [...new Set(data.rotations)];
        options.forEach(element => {
            let idElement = String(element.id);
            if(data.rotations.includes(idElement)){
                element.status = 1;   
            }
        })
        this.setState({selectDin: {}});
        this.setState({options: options});
        this.setState({ selects : 0 });
        this.setState({ selectedValue: this.state.selectedValue + 1});
        this.setState({
            is_loading: true,
            error: null
        });
        await this.props.addBusinessRotation(data,data);
    };

    eliminate =  async (id, name) =>{
        let data = this.state.options;
        data.forEach(element =>{
            if(element.name === name){
                element.status = 0;
            }
        })
        this.setState({options : data});
        await this.props.deleteBusinessRotation(id);
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    addLine = async () =>{
        const data = {
            business_id:  this.state.idAdd,
            lines: [
                {
                id: this.state.Line,
                quantity: this.state.linesNumber 
                }
            ],
        };
        await this.props.addLines(data);
    };

    deleteLine = async (idBusiness, idLine) =>{
        const data ={
            business_id: idBusiness,
            id: idLine
        };
        await this.props.deleteLines(data);
    };


    render() {
        const { messages } = this.props;
        let options = [];
        let lines = [];
        if(this.props.businessTable !== null){
            options = this.state.options.filter(e => e.status === 0);
        }

        if(this.props.Lines !== null){
            lines = this.props.Lines.lines
        }
        
        
        return (
            <div className="flex-center full-height position-ref">
                <Form>
                    <Form.Group>
                        <Accordion defaultActiveKey="0">
                            {this.props.businessRotations.company.business_logics && this.props.businessRotations.company.business_logics.map((item, key)  =>{
                                return(
                                    <Card className="Custom_Card">
                                        <Accordion.Toggle as={Card.Header} eventKey={key}>

                                        {item.name}
                                        <Button
                                            variant="outline-light"
                                            size="sm"
                                            className="Button_Padding"
                                            value="ola"
                                            onClick={() => this.eliminate(item.id, item.name)}
                                        >
                                            {messages['eliminate']}
                                        </Button>
                                        <Button 
                                            variant="outline-light"
                                            size="sm"
                                            className="Button_Padding"    
                                            onClick={() => this.handleShow(item.id)}>
                                            {messages['add_line']}
                                        </Button>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={key}>
                                        <Card.Body>{item.lines && item.lines.map((lines,lineskey) =>{
                                            return(
                                            <div>
                                            <Form.Label>{messages['line']}: {lines.name} {messages['quantity']}: {lines.quantity}</Form.Label>
                                            <Button                                                                              
                                                variant="outline-light"
                                                size="sm"
                                                className="Button_Padding"           
                                             onClick={() => this.deleteLine(item.id, lines.id)}>
                                            <FaMinusCircle />
                                            </Button>
                                            </div>
                                            )
                                        })}
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>     
                                );
                            })}
                        </Accordion>
                    </Form.Group>
              
                    <Form.Group>
                        <Form.Label className="white_title">Excess</Form.Label>
                        <Button
                            variant="outline-light"
                            size="sm"
                            onClick={() => this.changeNumOfSelects('+')}
                            className="Button_Padding"
                        >
                            {messages['add']}
                        </Button>
                        <Button
                            variant="outline-light"
                            size="sm"
                            onClick={() => this.changeNumOfSelects('-')}
                            className="Button_Padding"
                        >
                            {messages['delete']}
                        </Button>
                            <Form.Control as="select"  className="Button_Padding" name="selectDin0" onChange={this.handleChange}   key={this.state.selectedValue} custom>
                            <option></option>
                            {options && options.map(function(item,key) {
                            return(
                                <option value={item.id}>{item.name}</option>
                                )})}
                            </Form.Control>
                            {
                                this.extraSelects()
                            }
                            <div className="text-right">
                                <Button
                                    variant="outline-light"
                                    size="sm"
                                    onClick={this.onMakeRequest}
                                    className="Button_Padding"
                                >
                                    {messages['submit']}
                                </Button>
                            </div>
                    </Form.Group>
                </Form>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{messages['add_new_line']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form.Text>{messages['type_of_line']}</Form.Text>
                    <Form.Control as="select"  className="Button_Padding" name="Line" onChange={this.onChange}  custom>
                            <option></option>
                            {lines && lines.map(function(item,key) {
                            return(
                                <option value={item.id}>{item.name}</option>
                                )})}
                    </Form.Control>
                    <Form.Text>{messages['number_of_lines']}</Form.Text>
                    <Form.Control type="number"  min="1" max="99" name="linesNumber" onChange={this.onChange} placeholder="Number of lines" />            
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        {messages['close']}
                    </Button>
                    <Button variant="primary" onClick={this.addLine}>
                        {messages['save_changes']}
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    businessRotations: state.auth.user,
    businessTable: state.client.BR.businessRotation,
    Lines: state.client.Lines.lines,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    addBusinessRotation,
    getBusinessRotation,
    deleteBusinessRotation,
    getLines,
    addLines,
    deleteLines
})(BusinessRotation);
