import React from "react";
import { connect } from "react-redux";
import GoogleMapReact from 'google-map-react';
import Marker from "../../../components/Marker";
import MapControl from "../../../components/MapControl";
import { Button } from "react-bootstrap";
import {updateUser} from "../../../actions/auth";
import {create_form_data} from "../../../utils/routes";

class MyFactory extends React.Component {
    state = {
        error: null,
        is_loading: false,
        draggable: true,
        center:{
            lat: this.props.location.latitude,
            lng: this.props.location.longitude
        },
        lat: this.props.location.latitude,
        lng: this.props.location.longitude,
        zoom: 14,
    };


    onMakeRequest = async () => {

        if(this.props.location.latitude !== this.state.lat || this.props.location.longitude !== this.state.lng){
            const data = {
                latitude: this.state.lat,
                longitude: this.state.lng
            };
            await this.props.updateUser( create_form_data(data), "client");
        }
    };

    onCircleInteraction = (childKey, childProps, mouse) => {
        this.setState({
          draggable: false,
          lat: mouse.lat,
          lng: mouse.lng
        });
      }

      
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };


    onCircleInteractionLock = (childKey, childProps, mouse) => {
        this.setState({draggable: true});        
      }

    render() {
        const { messages } = this.props;
        return (
            <div className="flex-center full-height position-ref">
                
                      <div style={{ height: '100vh', width: '100%' }}>
                        <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyCNFvYD3uhJKrv-yNLBC_OiUqtZE8kV62A",
                                            v: '3.31'
                    }}
                        center={this.state.center}
                        zoom={this.state.zoom}
                        onChildMouseDown={this.onCircleInteraction}
                        onChildMouseUp={this.onCircleInteractionLock}
                        onChildMouseMove={this.onCircleInteraction}    
                        draggable = {this.state.draggable}

                        onGoogleApiLoaded={({map, maps}) => {
                            this.map = map
                            this.maps = maps
                            // we need this setState to force the first mapcontrol render
                            this.setState({mapControlShouldRender: true})
                          }}
                          yesIWantToUseGoogleMapApiInternals
                        
                        >
                        
                        <Marker
                         lat={this.state.lat}
                         lng={this.state.lng}
                        />
                               <MapControl map={this.map || null}
                                controlPosition={this.maps ? this.maps.ControlPosition.TOP_CENTER : null}
                                >
                                      <Button
                                      
                                        variant="primary"
                                        size="lg"
                                        onClick={() => this.onMakeRequest()} 
                                        >
                                            {messages['set_location']}
                                        </Button>
                                </MapControl>
                        </GoogleMapReact>
                   
                    </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    parsedToken: state.auth.parsedToken,
    location: state.auth.user,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    updateUser,
})(MyFactory);
