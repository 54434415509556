import React from "react";
import { connect } from "react-redux";
import { Container, Spinner, Button } from "react-bootstrap";
import { fetchDocuments, saveDocuments } from "@actions/technician";
import DocumentOption from "@components/shared/Document";

const files = [
    { key: "passport", required: true },
    { key: "passport_with_selfie", required: true },
    { key: "proof_of_address", required: true },
    { key: "medical_expense_insurance", required: true },
    { key: "life_insurance", required: true },
    { key: "diploma", required: true },
    { key: "references", required: true },
    { key: "certifications", required: false },
    { key: "licenses", required: false },
    { key: "vaccines", required: false },
    { key: "visas", required: false }
];

class Documents extends React.Component {
    state = {
        files: {},
        is_saving: false
    };

    async componentDidMount() {
        if (!this.props.loaded) this.props.fetchDocuments();
    }

    onDrop = (key, files) => {
        files = files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        );

        let file = [...files];
        if (this.state.files[key]) file = this.state.files[key];
        this.setState(state => ({
            files: {
                ...state.files,
                [key]: file
            }
        }));
    };

    retrieveImages = key => {
        const files = [
            ...(this.state.files[key] || []),
            ...(this.props.documents[key]?.files || [])
        ];

        return files.map(file => {
            return file.preview || `/api/media/d/${key}/${file.name}/${this.props.publicToken}`;
        });
    };

    retrieveData = key => {
        if (this.props.documents[key]) {
            return {
                type: this.props.documents[key].type,
                status: this.props.documents[key].status,
                comment: this.props.documents[key].comment
            };
        }

        return null;
    };

    onSave = async () => {
        this.setState({ is_saving: true });
        const formData = new FormData();
        const keys = Object.keys(this.state.files);
        for (const key of keys) {
            const files = this.state.files[key];
            files.forEach((file, i) => {
                formData.append(`${key}[file][${i}]`, file, file.name);
            });
        }

        await this.props.saveDocuments(formData, this.props.documents);
        this.setState({ is_saving: false, files: {} });
    };

    render() {
        const { messages, loaded, documents } = this.props;
        const { is_saving } = this.state;
        console.log(documents);
        return (
            <Container>
                <h1 className="reset-header text-white pt-4 mb-2">{messages["documents"]}</h1>
                <p className="text-white mb-4">
                    In order to apply for a service, you first need to upload the different
                    documents, some of them are required(*) and some others optional.
                </p>
                {!loaded && <Spinner animation="border" variant="light" />}
                {files.map(file => {
                    return (
                        <DocumentOption
                            key={file.key}
                            titleKey={file.key}
                            required={file.required}
                            messages={messages}
                            onDrop={this.onDrop}
                            images={this.retrieveImages(file.key)}
                            data={this.retrieveData(file.key)}
                        />
                    );
                })}
                <div className="text-right pt-2 pb-4">
                    {is_saving && <Spinner variant="light" animation="border" size="sm" />}
                    {!is_saving && (
                        <Button
                            size="sm"
                            disabled={Object.keys(this.state.files).length === 0}
                            variant="outline-light"
                            onClick={this.onSave}
                        >
                            {messages["uploadFile"]}
                        </Button>
                    )}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    loaded: state.tech.docs.loaded,
    documents: state.tech.docs.documents,
    publicToken: state.auth.publicToken
});

export default connect(mapStateToProps, {
    fetchDocuments,
    saveDocuments
})(Documents);
