import { DOCUMENT } from "@actions/technician/document";

const initialState = {
    loaded: false,
    documents: {},
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case DOCUMENT.FETCH_DOCUMENTS:
            return {
                ...state,
                documents: action.documents,
                loaded: true
            };
        default:
            return state;
    }
}
