import React from "react";
import GoogleMapReact from "google-map-react";
import styles from "./styles";
import Marker from "@components/Marker";

const ServiceLocationCard = ({ lat, lng, country, city, state, street, ext_number }) => {
    const key = process.env.MIX_GOOGLE_MAPS_KEY;
    return (
        <div style={styles.card}>
            <div style={styles.title}>Service location</div>
            <div
                style={{
                    height: "50vh",
                    width: "100%"
                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{ key: key }}
                    defaultCenter={{
                        lat: lat,
                        lng: lng
                    }}
                    defaultZoom={15}
                >
                    <Marker lat={lat} lng={lng} />
                </GoogleMapReact>
            </div>
            <div style={{ marginTop: "10px" }}>
                <div style={styles.data}>Address:</div>
                <div
                    style={styles.data}
                >{`${street} ${ext_number}, ${city} ${state} ${country}`}</div>
            </div>
        </div>
    );
};

export default ServiceLocationCard;
