import { EXPERIENCE } from "@actions/technician/experience";

const initialState = {
    loaded: false,
    list: [],
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case EXPERIENCE.FETCH_EXPERIENCES:
            return {
                ...state,
                list: action.experiences,
                loaded: true
            };
        case EXPERIENCE.ADD_EXPERIENCE:
            return {
                ...state,
                list: [...state.list, action.experience]
            };
        case EXPERIENCE.REMOVE_EXPERIENCE:
            return {
                ...state,
                list: state.list.filter(e => e.id.toString() !== action.id.toString())
            };
        case EXPERIENCE.RESET:
            return {
                ...state,
                list: [],
                loaded: false
            };
        default:
            return state;
    }
}
