import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_CONSTANT_MAP } from "@utils/routes";
import { Modal, Form, Spinner, Button } from "react-bootstrap";

const AddRoot = ({ technician, specialty, onClose }) => {
    const messages = useSelector(state => state.lang.messages);

    const [show, setShow] = useState(true);
    const [root, setRoot] = useState(null);
    const [rootList, setRootList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async function getRoots() {
            const response = await axios.get(
                API_CONSTANT_MAP.skills.get_left_roots(technician, specialty)
            );
            setRootList(response.data);
            setLoaded(true);
            setRoot(response.data[0]?.id || null);
        })();
    }, []);

    return (
        <Modal
            show={show}
            onHide={() => {
                setShow(false);
                onClose();
            }}
            animation={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{messages["knowledge"]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loaded && <Spinner animation="border" variation="light" />}
                {loaded && (
                    <Form>
                        <Form.Group>
                            <Form.Control
                                as="select"
                                custom
                                onChange={e => setRoot(e.target.value)}
                            >
                                {rootList.map(root => {
                                    return (
                                        <option key={root.id} value={root.id}>
                                            {root.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                )}

                {/* TODO: Update message for translations */}
                {loaded && rootList.length === 0 && <p>It seems you already have all the roots</p>}

                <div className="text-right">
                    <Button
                        variant="primary"
                        disabled={!loaded || rootList.length === 0}
                        onClick={event => {
                            event.persist();
                            setShow(false);
                            onClose({ root });
                        }}
                    >
                        {messages["saveChanges"]}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddRoot;
