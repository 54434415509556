import { REQUEST_SERVICE } from "@actions/technician/request_service";
import { merge } from "@utils/calendar";

const initialState = {
    loaded_calendar: false,
    schedule: {},
    services: {
        2: { data: [], loaded: false },
        3: { data: [], loaded: false },
        4: { data: [], loaded: false }
    },
    colors: {},
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case REQUEST_SERVICE.GET_SCHEDULE:
            return {
                ...state,
                schedule: action.schedule,
                loaded_calendar: true,
                colors: action.colors
            };
        case REQUEST_SERVICE.SAVE_SCHEDULE:
            return {
                ...state,
                schedule: action.schedule,
                loaded_calendar: true
            };
        case REQUEST_SERVICE.GET_SERVICES:
            return {
                ...state,
                services: {
                    ...state.services,
                    [action.status]: {
                        data: action.services,
                        loaded: true
                    }
                }
            };
        case REQUEST_SERVICE.GET_SERVICE:
            return {
                ...state,
                services: {
                    ...state.services,
                    [3]: {
                        ...state.services[3],
                        data: [...state.services[3].data, action.service]
                    }
                }
            };
        case REQUEST_SERVICE.RESET:
            return {
                ...initialState
            };
        case REQUEST_SERVICE.ERROR:
            return {
                ...state,
                error: action.error
            };
        case REQUEST_SERVICE.ACCEPT_SERVICE:
            return {
                ...state,
                services: {
                    ...state.services,
                    [2]: {
                        ...state.services[2],
                        data: state.services[2].data.filter(
                            s => s.id.toString() !== action.id.toString()
                        )
                    },
                    [3]: {
                        ...state.services[3],
                        data: [...state.services[3].data, action.service]
                    }
                },
                schedule: merge(state.schedule, action.service.dates),
                colors: { ...state.colors, ...action.color }
            };
        case REQUEST_SERVICE.REJECT_SERVICE:
            return {
                ...state,
                services: {
                    ...state.services,
                    [2]: {
                        ...state.services[2],
                        data: state.services[2].data.filter(
                            s => s.id.toString() !== action.id.toString()
                        )
                    }
                }
            };
        default:
            return state;
    }
}
