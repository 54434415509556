import { SKILL } from "@actions/technician/skill";

const initialState = {
    loaded: false,
    list: [],
    error: null
};

const matches = (e, toMatch) => e.id.toString() === toMatch.toString();

export default function (state = initialState, action) {
    switch (action.type) {
        case SKILL.FETCH_SKILLS:
            return {
                ...state,
                list: action.skills,
                loaded: true
            };

        case SKILL.ADD_SKILL:
            return {
                ...state,
                list: [...state.list, action.skill]
            };

        case SKILL.REMOVE_SKILL:
            return {
                ...state,
                list: state.list.filter(e => !matches(e, action.id))
            };

        case SKILL.ADD_ROOT: {
            const list = [...state.list];
            const index = list.findIndex(e => matches(e, action.skill_id));
            list[index].roots = [...list[index].roots, action.root];

            return {
                ...state,
                list
            };
        }

        case SKILL.REMOVE_ROOT: {
            const list = [...state.list];
            const index = list.findIndex(e => matches(e, action.skill_id));
            list[index].roots = list[index].roots.filter(e => !matches(e, action.root_id));

            return {
                ...state,
                list
            };
        }

        case SKILL.ADD_PROTOCOL: {
            const list = [...state.list];
            const skillIndex = list.findIndex(e => matches(e, action.skill_id));
            const rootIndex = list[skillIndex].roots.findIndex(e => matches(e, action.root_id));
            if (action.mode === "direct") {
                list[skillIndex].roots[rootIndex].protocols.push(action.data);
            } else {
                const labelIndex = list[skillIndex].roots[rootIndex].labels.findIndex(e =>
                    matches(e, action.label_id)
                );
                list[skillIndex].roots[rootIndex].labels[labelIndex].protocols.push(action.data);
            }

            return {
                ...state,
                list
            };
        }

        case SKILL.REMOVE_PROTOCOL: {
            const list = [...state.list];
            const skillIndex = list.findIndex(e => matches(e, action.skill_id));
            const rootIndex = list[skillIndex].roots.findIndex(e => matches(e, action.root_id));
            if (action.mode === "direct") {
                list[skillIndex].roots[rootIndex].protocols = list[skillIndex].roots[
                    rootIndex
                ].protocols.filter(e => !matches(e, action.protocol_id));
            } else {
                const labelIndex = list[skillIndex].roots[rootIndex].labels.findIndex(e =>
                    matches(e, action.label_id)
                );
                list[skillIndex].roots[rootIndex].labels[labelIndex].protocols = list[
                    skillIndex
                ].roots[rootIndex].labels[labelIndex].protocols.filter(
                    e => !matches(e, action.protocol_id)
                );
            }

            return {
                ...state,
                list
            };
        }

        case SKILL.ADD_LABEL: {
            const list = [...state.list];
            const skillIndex = list.findIndex(e => matches(e, action.skill_id));
            const rootIndex = list[skillIndex].roots.findIndex(e => matches(e, action.root_id));
            list[skillIndex].roots[rootIndex].labels.push(action.data);
            return {
                ...state,
                list
            };
        }

        case SKILL.REMOVE_LABEL: {
            const list = [...state.list];
            const skillIndex = list.findIndex(e => matches(e, action.skill_id));
            const rootIndex = list[skillIndex].roots.findIndex(e => matches(e, action.root_id));
            list[skillIndex].roots[rootIndex].labels = list[skillIndex].roots[
                rootIndex
            ].labels.filter(e => !matches(e, action.label_id));

            return {
                ...state,
                list
            };
        }

        case SKILL.UPDATE_DAILY_RATE: {
            const list = [...state.list];
            const idx = list.findIndex(e => matches(e, action.data.id));
            list[idx].daily_rate = action.data.daily_rate;

            return {
                ...state,
                list
            };
        }

        case SKILL.RESET:
            return {
                ...state,
                list: [],
                loaded: false
            };
        default:
            return state;
    }
}
