import React from "react";
import { connect } from "react-redux";
import { Form, Button, Modal, Table, Container, Row, Col, ButtonGroup } from "react-bootstrap";
import { FaMinusCircle, FaEdit } from 'react-icons/fa';
import Moment from "moment";
import { getProtocols, addProtocols, editProtocols, deleteProtocols } from "@actions/Administrator/skills"
import { addPopper } from "@actions/popper";


class SkillsManagement extends React.Component {

    state = {
        addModal: false,
        editModal: false,
        deleteModal: false,
        protocolId: null,
        es_name: null,
        en_name: null,
        is_loading: false
    };

    componentDidMount = async () =>{
        if(this.props.protocols.loaded == false){
            await this.props.getProtocols(this.props.route.match.params.type, this.props.route.match.params.id, 1);
        }
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    openAddModal = () =>{this.setState({addModal: true});}

    openEditModal = (id) =>{this.setState({editModal: true, protocolId: id});}

    openDeleteModal = (id) =>{this.setState({deleteModal: true, protocolId: id});}

    handleAddModal = () =>{this.setState({addModal: false});}

    handleEditModal = () =>{this.setState({editModal: false});}

    handleDeleteModal = () =>{this.setState({deleteModal: false});}

    addRoot = async () =>{
        let data;
        if(this.props.route.match.params.type == 'labels'){
            data = {
                en_name: this.state.en_name,
                es_name: this.state.es_name,
                label_id: this.props.route.match.params.id
            };
        }
        else{
            data = {
                en_name: this.state.en_name,
                es_name: this.state.es_name,
                root_id: this.props.route.match.params.id
            };
        }
        await this.props.addProtocols(data);
        this.setState({ addModal: false });
        this.popper("added");
    }

    editLabel = async () =>{
        const data = {
            en_name: this.state.en_name,
            es_name: this.state.es_name,
        };
        await this.props.editProtocols(this.state.protocolId, data);
        this.setState({ editModal: false });
        this.popper("edited");
    }

    deleteLabel = async () =>{
        await this.props.deleteProtocols(this.state.protocolId);
        this.setState({ deleteModal: false });
        this.popper("deleted");
    }

    changePage = async (symbol) =>{
        if(symbol === "+" && this.props.protocols.meta.current_page < this.props.protocols.meta.last_page){
            await this.props.getProtocols(this.props.route.match.params.type, this.props.route.match.params.id, (this.props.protocols.meta.current_page + 1));
        }
        else if(symbol === "-" && this.props.protocols.meta.current_page > 1){
            await this.props.getLevels(this.props.route.match.params.type, this.props.route.match.params.id, (this.props.protocols.meta.current_page - 1));  
        }
    };

    popper = async (message) =>{
        this.props.addPopper({
          title: "success",
          message: message,
          time: 6000,
          level: "success"
      });
      }
    
    render() {
        const { messages, publicToken } = this.props;
        return (
            <div className="flex-center full-height position-ref">
                <Container>
                    <h1>Protocols</h1>
                    <Table hover variant="dark" className="Custom_Table" responsive>
                    <thead>
                            <tr>
                            <th>{messages['id']}</th>
                            <th>{messages['name']}</th>
                            <th>{messages['parent_name']}</th>
                            <th>{messages['parent_type']}</th>
                            <th>{messages['created_at']}</th>
                            <th>{messages['updated_at']}</th>
                            <th>{messages['options']}</th>
                            </tr>
                        </thead>
                        <tbody>
                        { this.props.protocols.data.map((Data) =>{
                            return(
                                <tr key = { Data.id }>
                                    <td>{ Data.id }</td>
                                    <td>{ Data.name }</td>
                                    <td>{ Data.parent_name }</td>
                                    <td>{ Data.parent_type }</td>
                                    <td>{ Moment(Data.created_at).format('YYYY/MM/DD') }</td>
                                    <td>{ Moment(Data.updated_at).format('YYYY/MM/DD') }</td>
                                    <td><Button 
                                        className="Button_Padding"
                                        variant="outline-light"
                                        onClick={() => this.openDeleteModal(Data.id)}>
                                            <FaMinusCircle/>
                                        </Button> 
                                        <Button
                                            className="Button_Padding"
                                            variant="outline-light"
                                            onClick={() => this.openEditModal(Data.id)}>
                                            <FaEdit/>
                                        </Button>
                                        </td>
                                </tr>
                            )})}
                        </tbody>
                    </Table>
                    <ButtonGroup>
                      <Button variant="outline-light" onClick={() => this.changePage("-")}>{messages['prev']}</Button>
                      <Button variant="outline-light" onClick={() => this.changePage("+")}>{messages['next']}</Button>
                    </ButtonGroup>
                    <Button
                        className="Button_Padding"
                        variant="outline-light"
                        onClick={this.openAddModal}>Add protocols</Button>
                </Container>   

                <Modal show={this.state.addModal} onHide={this.handleAddModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{messages['add']} {messages['protocol']} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <h5 className="dark_title">{messages['name_en']}</h5>
                        <Form.Control type="text" name="en_name" placeholder="name" onChange={this.onChange}/>
                        <h5 className="dark_title">{messages['name_es']}</h5>
                        <Form.Control type="text" name="es_name" placeholder="nombre" onChange={this.onChange}/>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleAddModal}>
                        {messages['close']}
                        </Button>
                        <Button variant="primary" onClick={this.addRoot}>
                        {messages['yes']}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.editModal} onHide={this.handleEditModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{messages['edit']} {messages['protocol']} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <h5 className="dark_title">{messages['name_en']}</h5>
                        <Form.Control type="text" name="en_name" placeholder="name" onChange={this.onChange}/>
                        <h5 className="dark_title">{messages['name_es']}</h5>
                        <Form.Control type="text" name="es_name" placeholder="nombre" onChange={this.onChange}/>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleEditModal}>
                        {messages['close']}
                        </Button>
                        <Button variant="primary" onClick={this.editLabel}>
                        {messages['yes']}
                        </Button>
                    </Modal.Footer>
                </Modal>  

                <Modal show={this.state.deleteModal} onHide={this.handleDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{messages['delete']} {messages['specialty']} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to delete this label?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleDeleteModal}>
                        {messages['close']}
                        </Button>
                        <Button variant="primary" onClick={this.deleteLabel}>
                        {messages['yes']}
                        </Button>
                    </Modal.Footer>
                </Modal>                
            </div>
        );
    }
}

const mapStateToProps = state => ({
    protocols: state.admin.skills.protocols,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    getProtocols,
    addProtocols,
    editProtocols,
    deleteProtocols,
    addPopper
})(SkillsManagement);



                        