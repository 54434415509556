import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Spinner, Button } from "react-bootstrap";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";

const AddLabel = ({ onClose, root }) => {
    const messages = useSelector(state => state.lang.messages);

    const [show, setShow] = useState(true);
    const [label, setLabel] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [labelList, setLabelList] = useState([]);

    useEffect(() => {
        (async function getLabels() {
            const response = await axios.get(API_CONSTANT_MAP.skills.left_labels(root));
            setLabelList(response.data);
            setLoaded(true);
            setLabel(response.data[0]?.id || null);
        })();
    }, []);

    return (
        <Modal
            show={show}
            animation={true}
            onHide={() => {
                setShow(false);
                onClose();
            }}
        >
            <Modal.Header>
                <Modal.Title>{messages["addLabel"]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loaded && <Spinner animation="border" variation="light" />}
                {loaded && (
                    <Form>
                        <Form.Group>
                            <Form.Control
                                as="select"
                                custom
                                onChange={e => setLabel(e.target.name)}
                            >
                                {labelList.map(label => {
                                    return (
                                        <option key={label.id} value={label.id}>
                                            {label.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                )}

                {/* TODO: Update message */}
                {loaded && labelList.length === 0 && (
                    <p>It seems you already have all the labels</p>
                )}

                <div className="text-right">
                    <Button
                        variant="primary"
                        disabled={!loaded || labelList.length === 0}
                        onClick={event => {
                            event.persist();
                            setShow(false);
                            onClose({ label });
                        }}
                    >
                        {messages["saveChanges"]}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddLabel;
