import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ChangePassword from "@components/shared/ChangePassword";
import { AUTH } from "@actions/auth";

const UpdatePasswordWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`;

const UpdatePassword = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onSave = () => {
        dispatch({
            type: AUTH.UPDATE_CHANGE_OF_PASSWORD,
            change_of_password: 0
        });
        history.push("/t");
    };

    return (
        <UpdatePasswordWrapper>
            <div>
                <ChangePassword onSave={onSave} />
            </div>
        </UpdatePasswordWrapper>
    );
};

export default UpdatePassword;
