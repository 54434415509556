import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Spinner, Button, Form, Row, Col, CardColumns, Card } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { API_CONSTANT_MAP } from "@utils/routes";
import Stars from "@components/Stars";
import AddRoot from "./AddRoot";
import AddLabel from "./AddLabel";
import AddProtocol from "./AddProtocol";

const Details = ({
    skill,
    technician,
    onAddedRoot,
    onDeletedRoot,
    onAddedLabel,
    onDeletedLabel,
    onAddedProtocol,
    onDeletedProtocol
}) => {
    const messages = useSelector(state => state.lang.messages);

    // Global State
    const [_root, setRoot] = useState(null);
    const [_label, setLabel] = useState(null);
    const [_protocol, setProtocol] = useState(null);
    const [mode, setMode] = useState(null);

    // State related to Root
    const [isAddingRoot, setIsAddingRoot] = useState(false);
    const [isDeletingRoot, setIsDeletingRoot] = useState(false);
    const [addRootModal, setAddRootModal] = useState(false);

    // State related to Label
    const [isAddingLabel, setIsAddingLabel] = useState(false);
    const [isDeletingLabel, setIsDeletingLabel] = useState(false);
    const [addLabelModal, setAddLabelModal] = useState(false);

    // State related to Protocol
    const [isAddingProtocol, setIsAddingProtocol] = useState(false);
    const [isDeletingProtocol, setIsDeletingProtocol] = useState(false);
    const [addProtocolModal, setAddProtocolModal] = useState(false);

    const handleAddRoot = async (payload = null) => {
        if (payload) {
            setIsAddingRoot(true);
            const data = {
                skill_technician_id: skill.id,
                root_id: payload.root
            };
            const response = await axios.post(API_CONSTANT_MAP.skills.post_roots, data);
            onAddedRoot(data, response.data);
            setIsAddingRoot(false);
        }

        setAddRootModal(false);
    };

    const handleDeleteRoot = async root_id => {
        setIsDeletingRoot(true);
        await axios.delete(API_CONSTANT_MAP.skills.delete_roots(root_id));
        onDeletedRoot({ skill_id: skill.id, root_id });
        setIsDeletingRoot(false);
    };

    const handleAddLabel = async (payload = null) => {
        if (payload) {
            setIsAddingLabel(true);
            const data = { skill_root_id: _root, label_id: payload.label };
            const response = await axios.post(API_CONSTANT_MAP.skills.post_labels, data);
            onAddedLabel({ root_id: _root, skill_id: skill.id }, response.data);
            setIsAddingLabel(false);
        }

        setAddLabelModal(false);
    };

    const handleDeleteLabel = async (root_id, label_id) => {
        setIsDeletingLabel(true);
        await axios.delete(API_CONSTANT_MAP.skills.delete_labels(label_id));
        onDeletedLabel({ skill_id: skill.id, root_id, label_id });
        setIsDeletingLabel(false);
    };

    const handleAddProtocol = async (payload = null) => {
        if (payload) {
            setIsAddingProtocol(true);
            const data = { protocol_id: payload.protocol, rate: payload.rate };
            if (mode === "direct") data.skill_root_id = _root;
            else data.skill_label_id = _label;
            const response = await axios.post(API_CONSTANT_MAP.skills.post_protocols, data);
            onAddedProtocol(
                { skill_id: skill.id, mode, root_id: _root, label_id: _label },
                response.data
            );
            setIsAddingProtocol(false);
        }

        setAddProtocolModal(false);
    };

    const handleDeleteProtocol = async (root_id, label_id, protocol_id, mode) => {
        setIsDeletingProtocol(true);
        await axios.delete(API_CONSTANT_MAP.skills.delete_protocols(protocol_id));
        onDeletedProtocol({ skill_id: skill.id, mode, root_id, label_id, protocol_id });
        setIsDeletingProtocol(false);
    };

    return (
        <Container>
            <h1 className="reset-header text-white pt-4 mb-4">{messages["skillDetails"]}</h1>

            <Form>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className="text-white">{messages["specialty"]}</Form.Label>
                            <Form.Control value={skill.specialty.name} readOnly={true} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className="text-white">{messages["dailyRate"]}</Form.Label>
                            <Form.Control value={skill.daily_rate} readOnly={true} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className="text-white">
                                {messages["yearsOfExperience"]}
                            </Form.Label>
                            <Form.Control value={skill.years} readOnly={true} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <div className="flex flex-row justify-between align-items-center">
                <h1 className="reset-header text-white pt-4 mb-4">{messages["knowledge"]}</h1>
                {isAddingRoot && <Spinner size="sm" animation="border" variant="light" />}
                {!isAddingRoot && (
                    <Button variant="outline-light" size="sm" onClick={() => setAddRootModal(true)}>
                        {messages["addKnowledge"]}
                    </Button>
                )}
            </div>

            <CardColumns>
                {skill.roots.map(root => {
                    return (
                        <Card key={root.id}>
                            <Card.Header className="flex justify-between">
                                <span>{root.root.name}</span>
                                <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => {
                                        setRoot(root.id);
                                        handleDeleteRoot(root.id);
                                    }}
                                >
                                    {isDeletingRoot && _root.toString() === root.id.toString() ? (
                                        <Spinner size="sm" variant="light" animation="border" />
                                    ) : (
                                        <FontAwesomeIcon icon={faTrash} />
                                    )}
                                </Button>
                            </Card.Header>
                            <Card.Body>
                                {/* Brand Mode */}
                                {root.root.mode === "brand" &&
                                    root.labels.map(label => {
                                        return (
                                            <Card key={label.id}>
                                                <Card.Body>
                                                    <Card.Title className="flex justify-between">
                                                        {label.label.name}
                                                        <Button
                                                            size="sm"
                                                            variant="danger"
                                                            onClick={() => {
                                                                setRoot(root.id);
                                                                setLabel(label.id);
                                                                handleDeleteLabel(
                                                                    root.id,
                                                                    label.id
                                                                );
                                                            }}
                                                        >
                                                            {isDeletingLabel &&
                                                            _label.toString() ===
                                                                label.id.toString() ? (
                                                                <Spinner
                                                                    size="sm"
                                                                    variant="light"
                                                                    animation="border"
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            )}
                                                        </Button>
                                                    </Card.Title>

                                                    <ul>
                                                        {label.protocols.map(protocol => {
                                                            return (
                                                                <li
                                                                    key={protocol.id}
                                                                    className="mb-3"
                                                                >
                                                                    <p className="m-0">
                                                                        {protocol.protocol.name}
                                                                        <Button
                                                                            size="sm"
                                                                            variant="danger"
                                                                            className="ml-2"
                                                                            onClick={() => {
                                                                                setProtocol(
                                                                                    protocol.id
                                                                                );
                                                                                handleDeleteProtocol(
                                                                                    root.id,
                                                                                    label.id,
                                                                                    protocol.id,
                                                                                    "brand"
                                                                                );
                                                                            }}
                                                                        >
                                                                            {isDeletingProtocol &&
                                                                            _protocol.toString() ===
                                                                                protocol.id.toString() ? (
                                                                                <Spinner
                                                                                    size="sm"
                                                                                    variant="light"
                                                                                    animation="border"
                                                                                />
                                                                            ) : (
                                                                                <FontAwesomeIcon
                                                                                    icon={faTrash}
                                                                                />
                                                                            )}
                                                                        </Button>
                                                                    </p>
                                                                    <Stars stars={protocol.rate} />
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                    <Button
                                                        size="sm"
                                                        variant="primary"
                                                        onClick={() => {
                                                            setMode("brand");
                                                            setRoot(root.id);
                                                            setLabel(label.id);
                                                            setAddProtocolModal(true);
                                                        }}
                                                    >
                                                        {messages["addProtocol"]}
                                                    </Button>
                                                </Card.Body>
                                            </Card>
                                        );
                                    })}

                                {/* Direct Mode */}
                                {root.root.mode === "direct" && (
                                    <Card>
                                        <Card.Body>
                                            <ul>
                                                {root.protocols.map(protocol => {
                                                    return (
                                                        <li key={protocol.id} className="mb-3">
                                                            <p className="m-0">
                                                                {protocol.protocol.name}
                                                                <Button
                                                                    size="sm"
                                                                    variant="danger"
                                                                    className="ml-2"
                                                                    onClick={() => {
                                                                        setProtocol(protocol.id);
                                                                        handleDeleteProtocol(
                                                                            root.id,
                                                                            null,
                                                                            protocol.id,
                                                                            "direct"
                                                                        );
                                                                    }}
                                                                >
                                                                    {isDeletingProtocol &&
                                                                    _protocol.toString() ===
                                                                        protocol.id.toString() ? (
                                                                        <Spinner
                                                                            size="sm"
                                                                            variant="light"
                                                                            animation="border"
                                                                        />
                                                                    ) : (
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                        />
                                                                    )}
                                                                </Button>
                                                            </p>
                                                            <Stars stars={protocol.rate} />
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                )}

                                <div className="text-right">
                                    {root.root.mode === "direct" ? (
                                        <Button
                                            size="sm"
                                            variant="primary"
                                            onClick={() => {
                                                setMode("direct");
                                                setRoot(root.id);
                                                setAddProtocolModal(true);
                                            }}
                                        >
                                            {isAddingProtocol &&
                                            mode === "direct" &&
                                            _root.toString() === root.id.toString() ? (
                                                <Spinner
                                                    size="sm"
                                                    animation="border"
                                                    variant="light"
                                                />
                                            ) : (
                                                <span>{messages["addProtocol"]}</span>
                                            )}
                                        </Button>
                                    ) : (
                                        <Button
                                            size="sm"
                                            variant="primary"
                                            onClick={() => {
                                                setRoot(root.id);
                                                setAddLabelModal(true);
                                            }}
                                        >
                                            {isAddingLabel &&
                                            _root.toString() === root.id.toString() ? (
                                                <Spinner
                                                    size="sm"
                                                    animation="border"
                                                    variant="light"
                                                />
                                            ) : (
                                                <span>{messages["addLabel"]}</span>
                                            )}
                                        </Button>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    );
                })}
            </CardColumns>

            {addRootModal && (
                <AddRoot
                    technician={technician}
                    specialty={skill.specialty.id}
                    onClose={handleAddRoot}
                />
            )}

            {addLabelModal && <AddLabel root={_root} onClose={handleAddLabel} />}

            {addProtocolModal && (
                <AddProtocol
                    parent={mode === "direct" ? _root : _label}
                    onClose={handleAddProtocol}
                    mode={mode}
                />
            )}
        </Container>
    );
};

export default Details;
