/* Styles for the Service view */
import React from "react";
const title = {
    paddingTop: "20px",
    paddingLeft: "20px",
}

const card = {
    backgroundColor: "slategray",
    padding: "20px",
    borderRadius: "10px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
}

export default { title, card };

