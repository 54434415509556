import React from "react";
import Dropzone from "react-dropzone";
import { Image } from "react-bootstrap";

const imageMaxSize = 10000000;

const Document = ({ messages, titleKey, required, data, onDrop, images }) => {
    return (
        <div className="mb-4">
            <h3 className="m-0">
                {messages[titleKey]} {required && "*"}
            </h3>

            {/* Messages */}
            {!data && (
                <p className="text-white">
                    {messages["status"]}: {messages["file.0"]}
                </p>
            )}
            {data && data.status === 1 && (
                <p className="text-white">
                    {messages["status"]}:{" "}
                    {data.comment.length > 0 ? data.comment : messages["file.1"]}
                </p>
            )}
            {data && data.status === 2 && (
                <p className="text-white">
                    {messages["status"]}: {messages["file.2"]}
                </p>
            )}

            {/* Dropzone Area */}
            { onDrop && ((data && [1].indexOf(data.status) >= 0) || !data) && (
                <Dropzone
                    onDrop={files => onDrop(titleKey, files)}
                    maxSize={imageMaxSize}
                    accept="image/*"
                >

                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <p className="mb-0">{messages["dragAndDrop"]}</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
            )}

            {/* Preview of images for the given document */}
            {images.map(src => {
                return <Image key={src} src={src} className="Passport" />;
            })}
        </div>
    );
};

export default Document;
