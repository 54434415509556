import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Modal, Form, Button, Spinner, Row, Col } from "react-bootstrap";
import DataListInput from "react-datalist-input";
import { API_CONSTANT_MAP } from "@utils/routes";

const AddExperience = ({ technician, onClose }) => {
    const messages = useSelector(state => state.lang.messages);

    const [show, setShow] = useState(true);

    // State related to Equipment
    const [equipmentListLoaded, setEquipmentListLoaded] = useState(false);
    const [equipmentList, setEquipmentList] = useState([]);
    const [equipment, setEquipment] = useState(null);
    const [equipmentStr, setEquipmentStr] = useState("");

    // State related to Brand
    const [brandMap, setBrandMap] = useState({});
    const [brand, setBrand] = useState(null);
    const [brandList, setBrandList] = useState([]);
    const [brandStr, setBrandStr] = useState("");

    // State related to Technology
    const [technologyMap, setTecnologyMap] = useState({});
    const [technology, setTechnology] = useState(null);
    const [technologyList, setTechnologyList] = useState([]);
    const [technologyStr, setTechnologyStr] = useState("");

    // State related to Brand
    const [modelMap, setModelMap] = useState({});
    const [model, setModel] = useState(null);
    const [modelList, setModelList] = useState([]);
    const [modelStr, setModelStr] = useState("");

    useEffect(() => {
        (async function getEquipments() {
            const response = await axios.get(
                API_CONSTANT_MAP.experiences.get_equipments(technician)
            );
            setEquipmentList(response.data);
            setEquipment(response.data[0]?.id);
            setEquipmentListLoaded(true);
            fetchBrandAndTechnologies(response.data[0]?.id);
        })();
    }, []);

    const fetchBrandAndTechnologies = async eid => {
        if (!brandMap[eid]) {
            const response = await axios.get(
                API_CONSTANT_MAP.experiences.get_brand_technologies(eid)
            );
            setBrandMap({ ...brandMap, [eid]: response.data.brands });
            setBrandList(response.data.brands);
            setTecnologyMap({ ...technologyMap, [eid]: response.data.technologies });
            setTechnologyList(response.data.technologies);
            fetchModels(eid, response.data.brands[0]?.id);
        } else {
            const [brand] = brandMap[eid];
            const [technology] = technologyMap[eid];
            setBrand(brand?.id);
            setBrandList(brandMap[eid]);
            setTechnology(technology?.id);
            setTechnologyList(technologyMap[eid]);
            if (brand)
                setModel(modelMap[`${eid}-${brand.id}`] && modelMap[`${eid}-${brand.id}`][0]?.id);
            else setModel(null);
            setModelList(modelMap[`${eid}-${brand?.id}`] || []);
        }
    };

    const onBrandChange = bid => {
        if (!modelMap[`${equipment}-${bid}`]) {
            fetchModels(equipment, bid);
        } else {
            const [model] = modelMap[`${equipment}-${bid}`];
            setModel(model?.id);
            setModelList(modelMap[`${equipment}-${bid}`]);
        }
    };

    const fetchModels = async (eid, bid) => {
        if (eid && bid) {
            const response = await axios.get(API_CONSTANT_MAP.experiences.get_models(eid, bid));
            setModelMap({ ...modelMap, [`${eid}-${bid}`]: response.data });
            setModel(response.data[0]?.id);
            setModelList(response.data);
        } else setModel(null);
    };

    return (
        <Modal
            show={show}
            animation={true}
            onHide={() => {
                setShow(false);
                onClose();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{messages["newExperience"]}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {!equipmentListLoaded && <Spinner animation="border" variant="primary" />}
                {equipmentListLoaded && (
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>{messages["equipment"]}</Form.Label>

                                    <DataListInput
                                        items={equipmentList.map(item => {
                                            return {
                                                label: item.name,
                                                key: item.name,
                                                id: item.id
                                            };
                                        })}
                                        inputClassName="form-control form-control-md"
                                        onSelect={e => {
                                            setEquipment(e.id);
                                            fetchBrandAndTechnologies(e.id);
                                        }}
                                        onInput={e => {
                                            setEquipment(null);
                                            setEquipmentStr(e);
                                            setBrandList([]);
                                            setTechnologyList([]);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>{messages["technology"]}</Form.Label>

                                    <DataListInput
                                        items={technologyList.map(item => {
                                            return {
                                                label: item.name,
                                                key: item.name,
                                                id: item.id
                                            };
                                        })}
                                        inputClassName="form-control form-control-md"
                                        onSelect={e => {
                                            setTechnology(e.id);
                                        }}
                                        onInput={e => {
                                            setTechnology(null);
                                            setTechnologyStr(e);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>{messages["brand"]}</Form.Label>

                                    <DataListInput
                                        items={brandList.map(item => {
                                            return {
                                                label: item.name,
                                                key: item.name,
                                                id: item.id
                                            };
                                        })}
                                        inputClassName="form-control form-control-md"
                                        onSelect={e => {
                                            setBrand(e.id);
                                            onBrandChange(e.id);
                                        }}
                                        onInput={e => {
                                            setBrand(null);
                                            setBrandStr(e);
                                            setModelList([]);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>{messages["model"]}</Form.Label>

                                    <DataListInput
                                        items={modelList.map(item => {
                                            return {
                                                label: item.name,
                                                key: item.name,
                                                id: item.id
                                            };
                                        })}
                                        inputClassName="form-control form-control-md"
                                        onSelect={e => {
                                            setModel(e.id);
                                        }}
                                        onInput={e => {
                                            setModel(null);
                                            setModelStr(e);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Form>
                )}

                <div className="text-right">
                    <Button
                        variant="primary"
                        disabled={!equipmentListLoaded || equipmentListLoaded.length === 0}
                        size="sm"
                        type="submit"
                        onClick={event => {
                            event.persist();
                            setShow(false);
                            onClose({
                                equipment,
                                equipmentStr,
                                technology,
                                technologyStr,
                                brand,
                                brandStr,
                                model,
                                modelStr
                            });
                        }}
                    >
                        {messages["addExperience"]}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddExperience;
