import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import _ from "lodash";

import PublicLayout from "./public";
import PrivateLayout from "./private";

import { Routes as cRoutes, Links as cLinks } from "./routes/clientRoutes";
import { Routes as tRoutes, Links as tLinks } from "./routes/technicianRoutes";
import { Routes as aRoutes, Links as aLinks } from "./routes/administratorRoutes";
import publicRoutes from "./routes/publicRoutes";
import sessionRoutes from "./routes/sessionRoutes";

import LoadingScreen from "../views/public/LoadingScreen";
import NotFound from "./public/NotFound";
import { loadTokens, updateToken, cleanAuth, removeTokens, fetchProfile } from "../actions/auth";
import { loadLanguage, setLanguage } from "../actions/lang";

class Template extends React.Component {
    state = {
        loading: true
    };

    async componentDidMount() {
        await this.props.loadTokens();
        await this.props.loadLanguage();

        if (this.props.token !== null) {
            const { rol, locale } = this.props;
            await this.props.updateToken(rol, this.props.token, this.props.locale);
            if (this.props.error !== null) {
                await this.props.removeTokens();
                await this.props.cleanAuth();
                await this.props.setLanguage(locale);
            } else {
                await this.props.setLanguage(locale, rol);
                await this.props.fetchProfile(rol);
            }
        } else {
            await this.props.removeTokens();
            await this.props.cleanAuth();
        }

        this.setState({ loading: false });
    }

    handleRedirect = () => {
        const { rol, token } = this.props;

        if (!token) {
            return <Redirect to="/login" />;
        }

        if (rol === "client") {
            return <Redirect to="/c" />;
        } else if (rol === "technician") {
            return <Redirect to="/t" />;
        } else {
            return <Redirect to="/a" />;
        }
    };

    mapRoutesWithLayout = (routes, links, valid_rol) => {
        const { token, rol, user, messages } = this.props;
        return _.map(routes, (route, key) => {
            const { component, path } = route;
            return (
                <Route
                    exact
                    path={path}
                    key={key}
                    render={route =>
                        token && rol === valid_rol ? (
                            <PrivateLayout
                                component={component}
                                route={route}
                                user={user}
                                links={links}
                                rol={valid_rol}
                                messages={messages}
                            />
                        ) : (
                            this.handleRedirect()
                        )
                    }
                />
            );
        });
    };

    render() {
        const { token, user, messages } = this.props;
        const { loading } = this.state;
        if (loading) return <LoadingScreen />;
        return (
            <BrowserRouter>
                <Switch>
                    {_.map(publicRoutes, (route, key) => {
                        const { component, path } = route;
                        return (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={route => (
                                    <PublicLayout
                                        component={component}
                                        route={route}
                                        user={user}
                                        messages={messages}
                                    />
                                )}
                            />
                        );
                    })}

                    {_.map(sessionRoutes, (route, key) => {
                        const { component, path } = route;
                        return (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={route =>
                                    token !== null ? (
                                        this.handleRedirect()
                                    ) : (
                                        <PublicLayout
                                            component={component}
                                            route={route}
                                            user={user}
                                            messages={messages}
                                        />
                                    )
                                }
                            />
                        );
                    })}

                    {this.mapRoutesWithLayout(aRoutes, aLinks, "administrator")}
                    {this.mapRoutesWithLayout(tRoutes, tLinks, "technician")}
                    {this.mapRoutesWithLayout(cRoutes, cLinks, "client")}
                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    rol: state.auth.rol,
    error: state.auth.error,
    user: state.auth.user,
    locale: state.lang.locale,
    messages: state.lang.messages
});

export default connect(mapStateToProps, {
    loadTokens,
    updateToken,
    removeTokens,
    cleanAuth,
    fetchProfile,
    loadLanguage,
    setLanguage
})(Template);
