import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Nav, Table, Container } from "react-bootstrap";
import { getServices, setStatus, getTechnicians, cancelService } from "@actions/Client/service";
import Moment from "moment";

class Service extends React.Component {
    state = {
        Status: 1,
        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentDidMount = async () => {
        await this.props.getServices(this.props.Status);
    };

    onMakeRequest = async () => {};

    onSelect = async e => {
        if (e !== this.props.Status) {
            await this.props.setStatus(e);
            await this.props.getServices(this.props.Status);
            this.setState({ Status: parseInt(e) });
        }
    };

    requestService = () => {
        this.props.route.history.push("/c/request_service");
    };

    seeTechnicians = async id => {
        await this.props.getTechnicians(id);
        this.props.route.history.push("/c/technician_list");
    };

    cancelService = async id => {
        await this.props.cancelService(id);
        await this.props.getServices(this.props.Status);
    };

    render() {
        const { messages } = this.props;
        let services = [];
        if (this.props.Service !== null) {
            services = this.props.Service;
        }
        return (
            <div className="min-h-full flex flex-col justify-center align-items-center">
                <Container>
                    <Nav fill variant="tabs" className="Custom_Card">
                        <Nav.Item>
                            <Nav.Link eventKey="0" onSelect={this.onSelect}>
                                {messages["canceled"]}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="1" onSelect={this.onSelect}>
                                {messages["open"]}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2" onSelect={this.onSelect}>
                                {messages["waiting_confirmation"]}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3" onSelect={this.onSelect}>
                                {messages["assigned"]}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="4" onSelect={this.onSelect}>
                                {messages["finished"]}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Table hover variant="dark" className="Custom_Table" responsive>
                        <thead>
                            <tr>
                                <th>{messages["model"]}</th>
                                <th>{messages["brand"]}</th>
                                <th>{messages["machine"]}</th>
                                <th>{messages["service"]}</th>
                                <th>{messages["specialty"]}</th>
                                <th>{messages["equipment_year"]}</th>
                                <th>{messages["created_at"]}</th>
                                <th>{messages["seeDetails"]}</th>
                                {this.state.Status === 1 && <th>{messages["technician_list"]}</th>}
                                {(this.state.Status === 1 || this.state.Status === 2 || this.state.Status === 3) && (
                                    <th>{messages["cancel_service"]}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {services &&
                                services.map((service, key) => {
                                    return (
                                        <tr key={service.id}>
                                            <td>
                                                {typeof service.model === "object"
                                                    ? service.model.name
                                                    : service.model}
                                            </td>
                                            <td>
                                                {typeof service.brand === "object"
                                                    ? service.brand.name
                                                    : service.brand}{" "}
                                            </td>
                                            <td>
                                                {typeof service.machine === "object"
                                                    ? service.machine.name
                                                    : service.machine}{" "}
                                            </td>
                                            <td>
                                                {typeof service.service === "object"
                                                    ? service.service.name
                                                    : service.service}{" "}
                                            </td>
                                            <td>
                                                {typeof service.specialty === "object"
                                                    ? service.specialty.name
                                                    : service.specialty}{" "}
                                            </td>
                                            <td>{service.equipment_year}</td>
                                            <td>
                                                {Moment(service.created_at).format("YYYY/MM/DD")}
                                            </td>
                                            <td>
                                                <Link to={`/c/service/${service.id}`}>
                                                    <Button
                                                        variant="outline-light"
                                                        className="Button_Padding"
                                                    >
                                                        {messages["seeDetails"]}
                                                    </Button>
                                                </Link>
                                            </td>
                                            {this.state.Status === 1 && (
                                                <td>
                                                    <Button
                                                        variant="outline-light"
                                                        className="Button_Padding"
                                                        onClick={() =>
                                                            this.seeTechnicians(service.id)
                                                        }
                                                    >
                                                        {messages["see_technicians"]}
                                                    </Button>
                                                </td>
                                            )}
                                            {(this.state.Status === 1 ||
                                                this.state.Status === 2 || this.state.Status === 3) && (
                                                <td>
                                                    <Button
                                                        variant="outline-light"
                                                        className="Button_Padding"
                                                        onClick={() =>
                                                            this.cancelService(service.id)
                                                        }
                                                    >
                                                        {messages["cancel_service"]}
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </Container>
                <Button
                    variant="outline-light"
                    className="Button_Padding"
                    onClick={this.requestService}
                >
                    {messages["request_service"]}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    Service: state.client.Service.serviceList,
    Status: state.client.Service.status
});

export default connect(mapStateToProps, {
    getServices,
    setStatus,
    getTechnicians,
    cancelService
})(Service);
