import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Form, Button, Modal, Card } from "react-bootstrap";
import {updateUser} from "../../../../actions/auth";
import {create_form_data} from "../../../../utils/routes";
import { API_CONSTANT_MAP } from "@utils/routes";

class InviteTechnician extends React.Component {

    state = {
        email: null,
        error: null,
        show: false,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleClose = () =>{
        this.setState({show: false});
    }
    
    onMakeRequest = async () => {
        var re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        if (re.test(String(this.state.email))){       
            var data = {
                emails: [this.state.email],
                type: 0
            };  
            try {
                const response = await axios.post(API_CONSTANT_MAP.invite, data);
                if(response.status === 200){
                    this.setState({show: true});
                }

            } catch (err) {
                console.log(err.message);
            }
        } 
    };

    signTechnician = () => {
        this.props.route.history.push("/a/invite_technician/general_information");
    }

    render() {
        const { messages, publicToken } = this.props;
        return (
            <div className="flex-center full-height position-ref">
                <div className="relative_style">
                    <Card className="padding">
                        <Card.Body>
                            <Card.Title>Regular technician invitation</Card.Title>
                            <Form>
                                <Form.Control type="email" name="email" onChange={this.onChange} placeholder="Enter email" />
                                <Button variant="primary" onClick={this.onMakeRequest} className="top_padding">{messages['invite']}</Button>
                            </Form>
                        </Card.Body>
                    </Card>

                    <Card className="padding">
                        <Card.Body>
                            <Card.Title>Technician prefill information</Card.Title>
                            <Card.Text>
                            Fill the technician data, skills and experiences
                            </Card.Text>
                            <Button variant="primary" onClick={this.signTechnician} >Prefill-Information</Button>
                        </Card.Body>
                    </Card>
                </div>                   

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Excess</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{messages['email_sent']}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        {messages['close']}
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    securityRequirements: state.auth.user,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    updateUser
})(InviteTechnician);



                        