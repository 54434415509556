import { SKILLS } from "@actions/Administrator/skills";

const initialState = {
    specialty: {
        data: [],
        meta: [],
        loaded: false
    },
    roots: {
        data: [],
        meta: [],
        loaded: false
    },
    labels: {
        data: [],
        meta: [],
        loaded: false,
    },
    protocols: {
        data: [],
        meta: [],
        loaded: false,
    },
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {

            case SKILLS.GET_SPECIALTY:
            {
                return {
                    ...state,
                    specialty:{
                        ...state.specialty,
                        data: action.specialty.data,
                        meta: action.specialty.meta,
                        loaded: true
                    }
                };
            }

            case SKILLS.ADD_SPECIALTY:
                {
                    if(state.specialty.data.length < 5){
                        return{
                            ...state,
                            specialty: {
                                ...state.specialty,
                                data: [...state.specialty.data, action.specialty]
                            }
                        }
                    }
                    else{
                        if(state.specialty.meta.current_page === state.specialty.meta.last_page){
                            return{
                                ...state,
                                specialty: {
                                    ...state.specialty,
                                    meta : {
                                        ...state.specialty.meta,
                                        last_page: state.specialty.meta.last_page + 1
                                    }
                                }
                            }
                        }
                        else{
                            return{
                                ...state
                            }
                        }   
                    }
                }

            case SKILLS.EDIT_SPECIALTY:
                {
                    const updateIndex = state.specialty.data.findIndex(data => data.id == action.id);
                    let tempData = Array.from(state.specialty.data);
                    tempData[updateIndex] = action.specialty;
                    return{
                        ...state,
                        specialty: {
                            ...state.specialty,
                            data: tempData
                        }
                    }
                }

            case SKILLS.DELETE_SPECIALTY:
                {
                    return{
                        ...state,
                        specialty: {
                            ...state.specialty,
                            data: state.specialty.data.filter(data => data.id !== action.id)
                        }
                    }
                }            

            case SKILLS.GET_ROOTS:
                {
                    return {
                        ...state,
                        roots:{
                            ...state.roots,
                            data: action.roots.data,
                            meta: action.roots.meta,
                        }
                    };
                }

            case SKILLS.ADD_ROOTS:
                {
                    if(state.roots.data.length < 5){
                        return{
                            ...state,
                            roots: {
                                ...state.roots,
                                data: [...state.roots.data, action.root]
                            }
                        }
                    }
                    else{
                        if(state.roots.meta.current_page === state.roots.meta.last_page){
                            return{
                                ...state,
                                roots: {
                                    ...state.roots,
                                    meta : {
                                        ...state.roots.meta,
                                        last_page: state.roots.meta.last_page + 1
                                    }
                                }
                            }
                        }
                        else{
                            return{
                                ...state
                            }
                        }   
                    }
                }

            case SKILLS.EDIT_ROOTS:
                {
                    const updateIndex = state.roots.data.findIndex(data => data.id == action.id);
                    let tempData = Array.from(state.roots.data);
                    tempData[updateIndex] = action.root;
                    return{
                        ...state,
                        roots: {
                            ...state.roots,
                            data: tempData
                        }
                    }
                }

            case SKILLS.DELETE_ROOTS:
                {
                    return{
                        ...state,
                        roots: {
                            ...state.roots,
                            data: state.roots.data.filter(data => data.id !== action.id)
                        }
                    }
                }    

            case SKILLS.GET_LABELS:
                {
                    return {
                        ...state,
                        labels:{
                            ...state.labels,
                            data: action.labels.data,
                            meta: action.labels.meta,
                        }
                    };
                }

            case SKILLS.ADD_LABELS:
                {
                    if(state.labels.data.length < 5){
                        return{
                            ...state,
                            labels: {
                                ...state.labels,
                                data: [...state.labels.data, action.label]
                            }
                        }
                    }
                    else{
                        if(state.labels.meta.current_page === state.labels.meta.last_page){
                            return{
                                ...state,
                                labels: {
                                    ...state.labels,
                                    meta : {
                                        ...state.labels.meta,
                                        last_page: state.labels.meta.last_page + 1
                                    }
                                }
                            }
                        }
                        else{
                            return{
                                ...state
                            }
                        }   
                    }
                }
    
            case SKILLS.EDIT_LABELS:
                {
                    const updateIndex = state.labels.data.findIndex(data => data.id == action.id);
                    let tempData = Array.from(state.labels.data);
                    tempData[updateIndex] = action.label;
                    return{
                        ...state,
                        labels: {
                            ...state.labels,
                            data: tempData
                        }
                    }
                }
    
            case SKILLS.DELETE_LABELS:
                {
                    return{
                        ...state,
                        labels: {
                            ...state.labels,
                            data: state.labels.data.filter(data => data.id !== action.id)
                        }
                    }
                } 

            case SKILLS.GET_PROTOCOLS:
                {
                    return {
                        ...state,
                        protocols:{
                            ...state.protocols,
                            data: action.protocols.data,
                            meta: action.protocols.meta,
                        }
                    };
                }

            case SKILLS.ADD_PROTOCOLS:
                {
                    if(state.protocols.data.length < 5){
                        return{
                            ...state,
                            protocols: {
                                ...state.protocols,
                                data: [...state.protocols.data, action.protocol]
                            }
                        }
                    }
                    else{
                        if(state.protocols.meta.current_page === state.protocols.meta.last_page){
                            return{
                                ...state,
                                protocols: {
                                    ...state.protocols,
                                    meta : {
                                        ...state.protocols.meta,
                                        last_page: state.protocols.meta.last_page + 1
                                    }
                                }
                            }
                        }
                        else{
                            return{
                                ...state
                            }
                        }   
                    }
                }
    
            case SKILLS.EDIT_PROTOCOLS:
                {
                    const updateIndex = state.protocols.data.findIndex(data => data.id == action.id);
                    let tempData = Array.from(state.protocols.data);
                    tempData[updateIndex] = action.protocol;
                    return{
                        ...state,
                        protocols: {
                            ...state.protocols,
                            data: tempData
                        }
                    }
                }
    
            case SKILLS.DELETE_PROTOCOLS:
                {
                    return{
                        ...state,
                        protocols: {
                            ...state.protocols,
                            data: state.protocols.data.filter(data => data.id !== action.id)
                        }
                    }
                } 
              
        default:
            return state;
    }
}
