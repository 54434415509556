import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import {updateUser} from "../../../actions/auth";
import {create_form_data} from "../../../utils/routes";

class SecurityRequirements extends React.Component {

    state = {
        securityRequirements: this.props.securityRequirements.epp,
        
        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    
    onMakeRequest = async () => {
        if(this.props.securityRequirements.epp !== this.state.securityRequirements){
            const data = {
                epp: this.state.securityRequirements,
            };
            this.setState({
                is_loading: true,
                error: null
            });
            await this.props.updateUser(create_form_data(data), "client" );
        }
    };

    render() {
        const { messages } = this.props;
        return (
            <div className="flex-center full-height position-ref">
                <Form>
                    <Form.Group>
                        <Form.Label className="white_title">{messages['security_requirements']}</Form.Label>
                        <Form.Control as="textarea" rows="10" cols="100" name="securityRequirements" onChange={ this.onChange } value={this.state.securityRequirements}/>
                        <div className="text-right">
                            <Button
                                variant="outline-light"
                                size="sm"
                                onClick={this.onMakeRequest}
                            >
                                {messages['submit']}
                            </Button>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    securityRequirements: state.auth.user,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    updateUser
})(SecurityRequirements);

                        