import React from "react";
import { connect } from "react-redux";
import { Container, Image, Form, Button, Table, Card, Modal } from "react-bootstrap";
import { getProfile, getUserServices } from "@actions/Administrator/documents";
import { setService, deleteUser } from "@actions/Administrator/management";
import Moment from "moment";

class UserInformation extends React.Component {
    state = {
        review: null,
        error: null,
        deleteModal: false,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleDeleteModal = () => {
        this.setState({ deleteModal: false });
    };

    OpenDeleteModal = () => {
        this.setState({ deleteModal: true });
    };

    componentDidMount = async () => {
        await this.props.getProfile(
            this.props.route.match.params.profile,
            this.props.route.match.params.id
        );
        await this.props.getUserServices(
            this.props.route.match.params.profile,
            this.props.route.match.params.id
        );
    };

    seeDetails = async service => {
        service.client.latitude = service.client.location.latitude;
        service.client.longitude = service.client.location.longitude;
        await this.props.setService(service);
        this.props.route.history.push("/a/service_detail");
    };

    seeDocuments = async () => {
        console.log("hola");
        if (this.props.route.match.params.profile === "client") {
            this.props.route.history.push(
                "/a/client_documents/client/" + this.props.route.match.params.id
            );
        } else {
            this.props.route.history.push(
                "/a/user_documents/" +
                    this.props.route.match.params.profile +
                    "/" +
                    this.props.route.match.params.id
            );
        }
    };

    seeSkills = async () => {
        this.props.route.history.push(
            "/a/invite_technician/skills_information/" + this.props.route.match.params.id
        );
    };

    deleteUser = async () => {
        await this.props.deleteUser(
            this.props.route.match.params.profile,
            this.props.route.match.params.id
        );
        this.setState({ deleteModal: false });
        this.props.route.history.push("/a/" + this.props.route.match.params.profile);
    };

    render() {
        const { messages, publicToken } = this.props;
        console.log(this.props.data);
        return (
            <div className="flex-center full-height position-ref">
                <Container>
                    <Card>
                        <Card.Header as="h4" className="h-black">
                            <div className="flex flex-row justify-between align-items-center">
                                {messages["user_information"]}
                                <Button variant="primary" size="sm" onClick={this.seeDocuments}>
                                    {messages["check_documents"]}
                                </Button>
                                <Button variant="primary" size="sm" onClick={this.OpenDeleteModal}>
                                    {messages["delete_user"]}
                                </Button>
                                {this.props.route.match.params.profile == "technician" ? (
                                    <Button variant="primary" size="sm" onClick={this.seeSkills}>
                                        {messages["see_and_edit_skills"]}
                                    </Button>
                                ) : null}
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {messages["first_name"]}: {this.props.data.firstName}
                            <br></br>
                            {messages["last_name"]}: {this.props.data.lastName}
                            <br></br>
                            {messages["username"]}: {this.props.data.username}
                            <br></br>
                            {messages["created_at"]}{" "}
                            {Moment(this.props.data.created_at).format("YYYY/MM/DD")}
                            <br></br>
                            {messages["email"]}: {this.props.data.email}
                            <br></br>
                            {messages["phone_number"]}: {this.props.data.phoneNumber}
                            <br></br>
                            {messages["document_status"]}: {this.props.data.document_status}
                            <br></br>
                        </Card.Body>
                    </Card>
                    <h1>{messages["service_history"]}</h1>
                    <Table hover variant="dark" className="Custom_Table" responsive>
                        <thead>
                            <tr>
                                <th>{messages["id"]}</th>
                                <th>{messages["created_at"]}</th>
                                <th>{messages["machine"]}</th>
                                <th>{messages["model"]}</th>
                                <th>{messages["service"]}</th>
                                <th>{messages["specialty"]}</th>
                                <th>{messages["options"]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.services.data.map((Data, key) => {
                                return (
                                    <tr key={Data.id}>
                                        <td>{Data.id}</td>
                                        <td>{Moment(Data.created_at).format("YYYY/MM/DD")}</td>
                                        <td>{Data.machine.name}</td>
                                        <td>{Data.model.name}</td>
                                        <td>{Data.service.name}</td>
                                        <td>{Data.specialty.name}</td>

                                        <td key={key}>
                                            <Button
                                                className="Button_Padding"
                                                variant="outline-light"
                                                onClick={() => this.seeDetails(Data)}
                                            >
                                                {messages["service"]}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Container>

                <Modal show={this.state.deleteModal} onHide={this.handleDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete user</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to delete this user?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.deleteUser}>
                            {messages["yes"]}
                        </Button>
                        <Button variant="secondary" onClick={this.handleDeleteModal}>
                            {messages["no"]}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profile: state.admin.documents.profile,
    services: state.admin.documents.services,
    idProfile: state.admin.documents.id_profile,
    data: state.admin.documents.data,
    publicToken: state.auth.publicToken,
    messages: state.lang.messages
});

export default connect(mapStateToProps, {
    getProfile,
    getUserServices,
    setService,
    deleteUser
})(UserInformation);
