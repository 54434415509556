import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Form, Spinner, Row, Col, Button, Modal } from "react-bootstrap";
import { API_CONSTANT_MAP } from "@utils/routes";

const AddSkill = ({ technician, onClose }) => {
    const messages = useSelector(state => state.lang.messages);

    const [show, setShow] = useState(true);

    // Specialty State
    const [specialtyListLoaded, setSpecialtyListLoaded] = useState(false);
    const [specialtyList, setSpecialtyList] = useState([]);
    const [specialty, setSpecialty] = useState(null);

    // Root State
    const [rootListLoaded, setRootListLoaded] = useState(false);
    const [rootList, setRootList] = useState([]);
    const [root, setRoot] = useState(null);

    // Years plus daily rate
    const [years, setYears] = useState(1);
    const [rate, setRate] = useState(1);

    // Fetch for specialties
    useEffect(() => {
        (async function getSpecilaties() {
            const response = await axios.get(
                API_CONSTANT_MAP.skills.get_left_specialties(technician)
            );
            setSpecialtyList(response.data);
            setSpecialty(response.data[0]?.id);
            setSpecialtyListLoaded(true);
        })();
    }, []);

    // Fetch for Roots
    useEffect(() => {
        async function getRoots() {
            const response = await axios.get(
                API_CONSTANT_MAP.skills.get_left_roots(technician, specialty)
            );
            setRootList(response.data);
            setRoot(response.data[0]?.id);
            setRootListLoaded(true);
        }

        if (specialty) {
            getRoots();
        }
    }, [specialty]);

    return (
        <Modal
            show={show}
            animation={true}
            onHide={() => {
                setShow(false);
                onClose();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title> {messages["newSkill"]} </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    {!specialtyListLoaded && <Spinner animation="border" variant="primary" />}
                    {specialtyListLoaded && specialtyList.length > 0 && (
                        <>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>{messages["specialty"]}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="specialty"
                                            onChange={e => setSpecialty(e.target.value)}
                                            custom
                                        >
                                            {specialtyList.map(specialty => {
                                                return (
                                                    <option key={specialty.id} value={specialty.id}>
                                                        {specialty.name}
                                                    </option>
                                                );
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            {messages["knowledge"]}

                                            {!rootListLoaded && (
                                                <Spinner
                                                    animation="border"
                                                    variant="light"
                                                    className="ml-2 mb-1"
                                                    size="sm"
                                                />
                                            )}
                                        </Form.Label>
                                        {rootListLoaded && (
                                            <Form.Control
                                                as="select"
                                                custom
                                                name="root"
                                                onChange={e => setRoot(e.target.value)}
                                            >
                                                {rootList.map(root => {
                                                    return (
                                                        <option key={root.id} value={root.id}>
                                                            {root.name}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>{messages["yearsOfExperience"]}</Form.Label>
                                        <Form.Control
                                            size="md"
                                            placeholder="1, 2, 5..."
                                            name="years"
                                            value={years}
                                            min={1}
                                            onChange={e => setYears(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>{messages["dailyRate"]}</Form.Label>
                                        <Form.Control
                                            size="md"
                                            type="text"
                                            placeholder="20, 50, 100..."
                                            name="rate"
                                            value={rate}
                                            min={1}
                                            onChange={e => setRate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-right">
                                    <Button
                                        disabled={
                                            !specialtyListLoaded ||
                                            specialtyList.length === 0 ||
                                            !rootListLoaded
                                        }
                                        variant="outline-primary"
                                        size="sm"
                                        type="button"
                                        onClick={event => {
                                            event.persist();
                                            setShow(true);
                                            onClose({ specialty, root, years, rate });
                                        }}
                                    >
                                        {messages["addSkill"]}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddSkill;
