export const BASE_API = process.env.MIX_REACT_API;

export const API_CONSTANT_MAP = {
    login: `${BASE_API}/auth/login`,
    register: rol => `${BASE_API}/auth/${rol}/register`,
    update_token: type => `${BASE_API}/auth/${type}/refresh`,
    update_user: type => `${BASE_API}/auth/${type}`,
    logout: type => `${BASE_API}/auth/${type}/logout`,
    profile: type => `${BASE_API}/auth/${type}`,
    forgot: `${BASE_API}/auth/forgot`,
    check_reset: `${BASE_API}/auth/reset/check`,
    reset_password: `${BASE_API}/auth/reset/password`,
    update_lang: `${BASE_API}/auth/lang`,
    skills: {
        get: id => `${BASE_API}/at/technician/${id}/skills`,
        post: id => `${BASE_API}/at/technician/${id}/skills`,
        get_left_specialties: id => `${BASE_API}/at/technician/${id}/skills/left`,
        get_left_roots: (tid, sid) => `${BASE_API}/at/technician/${tid}/skills/${sid}/roots/left`,
        put_rate: sid => `${BASE_API}/at/skills/${sid}/rates`,
        delete: sid => `${BASE_API}/at/skills/${sid}`,
        post_roots: `${BASE_API}/at/skills/roots`,
        delete_roots: rid => `${BASE_API}/at/skills/roots/${rid}`,
        left_labels: rid => `${BASE_API}/at/skills/roots/${rid}/labels/left`,
        post_labels: `${BASE_API}/at/skills/labels`,
        delete_labels: lid => `${BASE_API}/at/skills/labels/${lid}`,
        left_protocols_by_roots: id => `${BASE_API}/at/skills/roots/${id}/protocols/left`,
        left_protocols_by_labels: id => `${BASE_API}/at/skills/labels/${id}/protocols/left`,
        post_protocols: `${BASE_API}/at/skills/protocols`,
        delete_protocols: pid => `${BASE_API}/at/skills/protocols/${pid}`
    },
    experiences: {
        get: tid => `${BASE_API}/at/technician/${tid}/experiences`,
        post: tid => `${BASE_API}/at/technician/${tid}/experiences`,
        delete: id => `${BASE_API}/at/experiences/${id}`,
        get_technologies: id => `${BASE_API}/admin/technology/${id}`,
        get_brands: id => `${BASE_API}/at/experiences/equipments/${id}/brand_technologies`,
        get_equipments: tid => `${BASE_API}/at/technician/${tid}/equipments`,
        get_brand_technologies: eid =>
            `${BASE_API}/at/experiences/equipments/${eid}/brand_technologies`,
        get_models: (eid, bid) =>
            `${BASE_API}/at/experiences/equipments/${eid}/brands/${bid}/models`,
        add_brand: `${BASE_API}/admin/brand`,
        edit_brand: id => `${BASE_API}/admin/brand/${id}`,
        add_tech: `${BASE_API}/admin/technology`,
        edit_tech: id => `${BASE_API}/admin/technology/${id}`,
        add_model: `${BASE_API}/admin/model`,
        edit_model: id => `${BASE_API}/admin/model/${id}`
    },
    documents: {
        base: (type, id) => `${BASE_API}/admin/${type}/${id}/documents`
    },
    request_services: {
        get: (page, status) => `${BASE_API}/admin/request_service?page=${page}&status=${status}`,
        find: id => `${BASE_API}/admin/request_service/${id}`,
        put: id => `${BASE_API}/admin/request_service/${id}`,
        update_status: id => `${BASE_API}/admin/request_service/status/${id}`
    },
    skill: `${BASE_API}/technician/skills`,
    delete_skill: id => `${BASE_API}/technician/skills/${id}`,
    experience: `${BASE_API}/technician/experiences`,
    delete_experience: id => `${BASE_API}/technician/experiences/${id}`,
    left_specialties: `${BASE_API}/technician/skills/left`,
    left_roots: id => `${BASE_API}/technician/skills/${id}/roots/left`,
    post_roots: `${BASE_API}/technician/skills/roots`,
    delete_roots: id => `${BASE_API}/technician/skills/roots/${id}`,
    delete_protocols: id => `${BASE_API}/technician/skills/protocols/${id}`,
    left_labels: id => `${BASE_API}/technician/skills/roots/${id}/labels/left`,
    specialties: `${BASE_API}/technician/specialties`,
    business_rotation: `${BASE_API}/client/business/rotation/s`,
    add_business_rotation: `${BASE_API}/client/business/rotation`,
    delete_business_rotation: id => `${BASE_API}/client/business/rotation/${id}`,
    put_rate: id => `${BASE_API}/technician/rate/${id}`,
    lines: `${BASE_API}/client/business`,
    delLines: `${BASE_API}/client/business/line`,
    schedule: `${BASE_API}/technician/schedule`,
    getServices: serviceStatus => `${BASE_API}/client/request_service?status=${serviceStatus}`,
    change_password: `${BASE_API}/auth/reset`,
    service_options: `${BASE_API}/client/service`,
    machine_options: `${BASE_API}/client/equipment`,
    specialty_options: `${BASE_API}/client/specialty`,
    brand_options: `${BASE_API}/client/brand`,
    get_model: (machine, brand) => `${BASE_API}/client/model/${machine}/${brand}`,
    post_service: `${BASE_API}/client/request_service`,
    services: status => `${BASE_API}/technician/request_service?status=${status}`,
    service: id => `${BASE_API}/technician/request_service/${id}`,
    equipment: `${BASE_API}/technician/equipments?per_page=all`,
    tech_brands: id => `${BASE_API}/technician/equipments/${id}`,
    models: (eid, bid) => `${BASE_API}/technician/equipments/${eid}/brand/${bid}`,
    //models: id => `${BASE_API}/equipment/brand/${id}`,
    technician_list: ServiceId => `${BASE_API}/client/request_service/suitor/${ServiceId}`,
    accept_technician: (service_id, technician_id) =>
        `${BASE_API}/client/request_service/${service_id}/${technician_id}`,
    cancel_service: id => `${BASE_API}/client/request_service/${id}`,
    invitation: (rol, token) => `${BASE_API}/invitation/${rol}/${token}`,
    accept_service: id => `${BASE_API}/technician/request_service/accept/${id}`,
    reject_service: id => `${BASE_API}/technician/request_service/reject/${id}`,
    invite: `${BASE_API}/admin/send_invitations`,
    management: (view, page) => `${BASE_API}/admin/${view}?page=${page}`,
    deleteItem: (category, id) => `${BASE_API}/admin/${category}/${id}`,
    addItem: category => `${BASE_API}/admin/${category}`,
    getAllItems: category => `${BASE_API}/admin/${category}?per_page=all`,
    get_documents: (profile, page, status) =>
        `${BASE_API}/admin/docs/${profile}?status=${status}&page=${page}`,
    depth: (id, level) => `${BASE_API}/technician/skills/${id}/depth/${level}`,
    add_depth: `${BASE_API}/technician/skills/levels`,
    add_in_depth: `${BASE_API}/technician/skills/level`,
    depth_one: id => `${BASE_API}/technician/skills/${id}/left`,
    docs: `${BASE_API}/admin/docs`,
    get_custom: `${BASE_API}/admin/unregistered`,
    get_services: (page, status) =>
        `${BASE_API}/admin/request_service?page=${page}&status=${status}`,
    edit_service_status: serviceId => `${BASE_API}/admin/request_service/${serviceId}`,
    get_profiles: (profile, page) => `${BASE_API}/admin/${profile}?page=${page}`,
    get_languages: page => `${BASE_API}/admin/language?page=${page}`,
    add_language: `${BASE_API}/admin/language`,
    update_delete_language: id => `${BASE_API}/admin/language/${id}`,
    get_user_services: (profile, id) => `${BASE_API}/admin/${profile}/${id}/services`,
    get_profile: (profile, id) => `${BASE_API}/admin/${profile}/${id}/profile`,
    languages: `${BASE_API}/ct/languages`,
    get_coefficients: (specialtyId, page) =>
        `${BASE_API}/admin/technician/coefficients?specialty=${specialtyId}&page=${page}`,
    put_coefficients: specialtyId => `${BASE_API}/admin/technician/coefficients/${specialtyId}`,
    //get_documents: `${BASE_API}/technician/documents`,
    post_documents: `${BASE_API}/technician/documents`,
    user_documents: (profile, id) => `${BASE_API}/admin/${profile}/${id}/documents`,

    /* Admin SKills */

    get_specialty: page => `${BASE_API}/admin/specialty?page=${page}`,
    add_specialty: `${BASE_API}/admin/specialty`,
    get_all_specialties: `${BASE_API}/admin/specialty?per_page=100`,
    edit_specialty: id => `${BASE_API}/admin/specialty/${id}`,
    delete_specialty: id => `${BASE_API}/admin/specialty/${id}`,
    get_roots: (id, page) => `${BASE_API}/admin/specialty/${id}/roots?page=${page}`,
    add_root: `${BASE_API}/admin/roots`,
    edit_root: id => `${BASE_API}/admin/roots/${id}`,
    delete_root: id => `${BASE_API}/admin/roots/${id}`,
    get_labels: (id, page) => `${BASE_API}/admin/roots/${id}/labels?page=${page}`,
    add_label: `${BASE_API}/admin/labels`,
    edit_label: id => `${BASE_API}/admin/labels/${id}`,
    delete_label: id => `${BASE_API}/admin/labels/${id}`,
    get_protocols: (type, id, page) => `${BASE_API}/admin/${type}/${id}/protocols?page=${page}`,
    add_protocol: `${BASE_API}/admin/protocols`,
    edit_protocol: id => `${BASE_API}/admin/protocols/${id}`,
    delete_protocol: id => `${BASE_API}/admin/protocols/${id}`,
    get_technologies: (idEquipment, page) =>
        `${BASE_API}/admin/technology/${idEquipment}?page=${page}`,

    admin_get_services: serviceStatus =>
        `${BASE_API}/admin/request_service?status=${serviceStatus}`,
    admin_technician_list: `${BASE_API}/admin/pg/request_service/suitor/1`,
    admin_request_service: `${BASE_API}/admin/pg/request_service/1`,

    admin_service_options: `${BASE_API}/admin/services?per_page=all`,
    admin_machine_options: `${BASE_API}/admin/equipment?per_page=all`,
    admin_specialty_options: `${BASE_API}/admin/specialty?per_page=all`,
    admin_brand_options: `${BASE_API}/admin/brand?per_page=all`,

    post_admin_service: id => `${BASE_API}/admin/pg/request_service/${id}`,

    admin_type_of_service: page => `${BASE_API}/admin/services?page=${page}`,
    admin_type_of_service_add: `${BASE_API}/admin/services`,
    admin_type_of_service_delete_put: idService => `${BASE_API}/admin/services/${idService}`,

    admin_create_service_simulation: `${BASE_API}/admin/simulation`,
    admin_get_suitors: id => `${BASE_API}/admin/simulation/${id}/suitors`,

    /* Admin Technician creation */
    create_technician: `${BASE_API}/admin/technician`,
    delete_user: (type, id) => `${BASE_API}/admin/${type}/${id}`
};

export const create_form_data = (body, photos) => {
    const data = new FormData();

    Object.keys(body).forEach(key => {
        data.append(key, body[key]);
    });

    return data;
};
