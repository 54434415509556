import React from "react";
import styles from "./styles";
import PlyrComponent from "@components/Plyr";

const ServiceMediaCard = ({ images, videos, publicToken }) => {
    return (
        <div style={styles.card}>
            <div style={styles.title}>Media</div>
            <div style={styles.flexRow}>
                <div>
                    <div style={styles.key}>Images:</div>
                    <div style={styles.data}>
                        {images.map((image, key) => {
                            return (
                                <img
                                    src={`${image.url}/${publicToken}`}
                                    key={key}
                                    style={styles.image}
                                    alt="service"
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <div style={styles.flexRow}>
                <div>
                    <div style={styles.key}>Videos:</div>
                    <div style={styles.flexRow}>
                        {videos.map((video, key) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        padding: "10px"
                                    }}
                                    key={key}
                                >
                                    <PlyrComponent
                                        sources={{
                                            type: "video",
                                            sources: [
                                                {
                                                    src: `${video.url}/${publicToken}`,
                                                    type: video.mime_type,
                                                    size: 720
                                                }
                                            ]
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceMediaCard;
