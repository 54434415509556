import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner, Form, Button } from "react-bootstrap";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";
import Stars from "@components/Stars";

const AddProtocol = ({ onClose, mode, parent }) => {
    const messages = useSelector(state => state.lang.messages);

    const [show, setShow] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [protocolList, setProtocolList] = useState([]);
    const [rate, setRate] = useState(1);
    const [protocol, setProtocol] = useState(null);

    useEffect(() => {
        (async function getProtocols() {
            if (mode === "direct") {
                const response = await axios.get(
                    API_CONSTANT_MAP.skills.left_protocols_by_roots(parent)
                );
                setProtocolList(response.data);
                setProtocol(response.data[0]?.id || null);
            } else {
                const response = await axios.get(
                    API_CONSTANT_MAP.skills.left_protocols_by_labels(parent)
                );
                setProtocolList(response.data);
                setProtocol(response.data[0]?.id || null);
            }
            setLoaded(true);
        })();
    }, []);

    return (
        <Modal
            show={show}
            onHide={() => {
                setShow(false);
                onClose();
            }}
            animation={true}
        >
            <Modal.Header>
                <Modal.Title>{messages["addProtocol"]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loaded && <Spinner animation="border" variation="light" />}
                {loaded && (
                    <Form>
                        <Form.Group>
                            <Form.Control
                                as="select"
                                custom
                                onChange={e => setProtocol(e.target.value)}
                            >
                                {protocolList.map(protocol => {
                                    return (
                                        <option key={protocol.id} value={protocol.id}>
                                            {protocol.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{messages["rateYourAbility"]}</Form.Label>
                            <div>
                                <Stars onClick={setRate} stars={rate} size="2x" />
                            </div>
                        </Form.Group>
                    </Form>
                )}

                {/* TODO: Update message */}
                {loaded && protocolList.length === 0 && (
                    <p>It seems you already have all the protocols</p>
                )}

                <div className="text-right">
                    <Button
                        variant="primary"
                        disabled={!loaded || protocolList.length === 0}
                        onClick={event => {
                            event.persist();
                            setShow(false);
                            onClose({ protocol, rate });
                        }}
                    >
                        {messages["saveChanges"]}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddProtocol;
