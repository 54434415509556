import React from "react";
import { connect } from "react-redux";
import { Button, Container, Table, Nav, Modal, Form, ButtonGroup } from "react-bootstrap";
import Moment from "moment";
import {
    setView,
    deleteItem,
    editItem,
    addItem,
    getBrand,
    getEquipment,
    setType
} from "@actions/Administrator/management";
import { FaMinusCircle, FaEdit } from "react-icons/fa";
import { addPopper } from "@actions/popper";

class Management extends React.Component {
    state = {
        show: false,
        editModal: false,
        eliminateModal: false,
        item: null,
        spanish: null,
        english: null,
        error: null,
        select: null,
        is_loading: false
    };

    componentDidMount = async () => {
        if (this.props.management[this.props.view].loaded == false) {
            await this.props.setView(this.props.view, 1);
            await this.props.getEquipment();
            await this.props.getBrand();
        }
    };

    handleClose = () => {
        this.setState({ show: false });
    };
    handleOpen = () => {
        this.setState({ show: true });
    };
    eliminateModal = Data => {
        this.setState({ eliminateModal: true, item: Data });
    };
    handleEliminateModal = () => {
        this.setState({ eliminateModal: false });
    };
    editModal = Data => {
        this.setState({ editModal: true, item: Data });
    };
    handleEditModal = () => {
        this.setState({ editModal: false });
    };
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onSelect = async e => {
        if (e !== this.props.view) {
            if (this.props.management[e].loaded === false) {
                await this.props.setView(e, 1);
            } else {
                await this.props.setType(e);
            }
        }
    };

    modalForm = () => {
        const { messages } = this.props;
        if (this.props.view === "model") {
            let brand = [];
            if (this.props.brand !== null) {
                brand = this.props.brand.data;
            }
            return (
                <Form>
                    <h5 className="dark_title">{messages["select_brand"]}</h5>
                    <Form.Control as="select" onChange={this.onChange} name="select">
                        <option></option>
                        {brand &&
                            brand.map((item, key) => {
                                return (
                                    <option value={item.id} key={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                    </Form.Control>
                    <h5 className="dark_title">
                        {this.props.view} {messages["name_en"]}
                    </h5>
                    <Form.Control
                        type="text"
                        name="english"
                        placeholder="name"
                        onChange={this.onChange}
                    />
                    <h5 className="dark_title">
                        {this.props.view} {messages["name_es"]}
                    </h5>
                    <Form.Control
                        type="text"
                        name="spanish"
                        placeholder="nombre"
                        onChange={this.onChange}
                    />
                </Form>
            );
        } else {
            return (
                <Form>
                    <h5 className="dark_title">
                        {this.props.view} {messages["name_en"]}
                    </h5>
                    <Form.Control
                        type="text"
                        name="english"
                        placeholder="name"
                        onChange={this.onChange}
                    />
                    <h5 className="dark_title">
                        {this.props.view} {messages["name_es"]}
                    </h5>
                    <Form.Control
                        type="text"
                        name="spanish"
                        placeholder="nombre"
                        onChange={this.onChange}
                    />
                </Form>
            );
        }
    };

    changePage = async symbol => {
        if (
            symbol === "+" &&
            this.props.management[this.props.view].meta.current_page <
                this.props.management[this.props.view].meta.last_page
        ) {
            await this.props.setView(
                this.props.view,
                this.props.management[this.props.view].meta.current_page + 1
            );
        } else if (symbol === "-" && this.props.management[this.props.view].meta.current_page > 1) {
            await this.props.setView(
                this.props.view,
                this.props.management[this.props.view].meta.current_page - 1
            );
        }
    };

    deleteItem = async () => {
        await this.props.deleteItem(this.props.view, this.state.item.id);
        await this.setState({ eliminateModal: false });
        this.popper("deleted");
    };

    editItem = async () => {
        let Data = {
            es: {
                name: this.state.spanish
            },
            en: {
                name: this.state.english
            }
        };
        await this.props.editItem(this.props.view, this.state.item.id, Data);
        await this.setState({ editModal: false });
        this.popper("edited");
    };

    addItem = async () => {
        let Data = {};
        switch (this.props.view) {
            case "equipment":
                Data = {
                    en_name: this.state.english,
                    es_name: this.state.spanish
                };
                await this.props.addItem(this.props.view, Data);

                break;

            case "brand":
                Data = {
                    en_name: this.state.english,
                    es_name: this.state.spanish
                };
                await this.props.addItem(this.props.view, Data);

                break;

            case "technology":
                Data = {
                    en_name: this.state.english,
                    es_name: this.state.spanish,
                    equipment_id: this.state.select
                };
                await this.props.addItem(this.props.view, Data);

                break;

            case "model":
                Data = {
                    en_name: this.state.english,
                    es_name: this.state.spanish,
                    brand: this.state.select
                };
                await this.props.addItem(this.props.view, Data);
                break;
        }
        await this.setState({ show: false });
        this.popper("added");
    };

    seeTechnologies(equipmentId) {
        this.props.route.history.push("/a/equipment_management/" + equipmentId);
    }

    popper = async message => {
        this.props.addPopper({
            title: "success",
            message: message,
            time: 6000,
            level: "success"
        });
    };

    render() {
        const { messages, publicToken } = this.props;
        return (
            <div className="min-h-full flex flex-col justify-center align-items-center">
                <Container>
                    <h2>
                        {messages[this.props.view]} {messages["page"]}:{" "}
                        {this.props.management[this.props.view].meta.current_page}
                    </h2>
                    <Nav fill variant="tabs" className="Custom_Card">
                        <Nav.Item>
                            <Nav.Link eventKey="equipment" onSelect={this.onSelect}>
                                {messages["equipment"]}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="brand" onSelect={this.onSelect}>
                                {messages["brand"]}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="model" onSelect={this.onSelect}>
                                {messages["model"]}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Table hover variant="dark" className="Custom_Table" responsive>
                        <thead>
                            <tr>
                                <th>{messages["id"]}</th>
                                <th>{messages["name"]}</th>
                                <th>{messages["created_at"]}</th>
                                <th>{messages["updated_at"]}</th>
                                <th>{messages["options"]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.management[this.props.view].data.map(Data => {
                                return (
                                    <tr key={Data.id}>
                                        <td>{Data.id}</td>
                                        <td>{Data.name}</td>
                                        <td>{Moment(Data.created_at).format("YYYY/MM/DD")}</td>
                                        <td>{Moment(Data.updated_at).format("YYYY/MM/DD")}</td>
                                        <td>
                                            <Button
                                                className="Button_Padding"
                                                variant="outline-light"
                                                onClick={() => this.eliminateModal(Data)}
                                            >
                                                <FaMinusCircle />
                                            </Button>
                                            <Button
                                                className="Button_Padding"
                                                variant="outline-light"
                                                onClick={() => this.editModal(Data)}
                                            >
                                                <FaEdit />
                                            </Button>
                                            {this.props.view == "equipment" && (
                                                <Button
                                                    className="Button_Padding"
                                                    variant="outline-light"
                                                    onClick={() => this.seeTechnologies(Data.id)}
                                                >
                                                    Check Equipment
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <ButtonGroup>
                        <Button variant="outline-light" onClick={() => this.changePage("-")}>
                            {messages["prev"]}
                        </Button>
                        <Button variant="outline-light" onClick={() => this.changePage("+")}>
                            {messages["next"]}
                        </Button>
                    </ButtonGroup>
                    <Button
                        variant="outline-light"
                        onClick={this.handleOpen}
                        className="Button_Padding"
                    >
                        {messages["add"]} {messages[this.props.view]}
                    </Button>
                </Container>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {messages["add"]} {this.props.view}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.modalForm()}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            {messages["close"]}
                        </Button>
                        <Button variant="primary" onClick={this.addItem}>
                            {messages["add"]}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.eliminateModal} onHide={this.handleEliminateModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {messages["delete"]} {this.props.view}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{messages["delete_this?"]}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleEliminateModal}>
                            {messages["close"]}
                        </Button>
                        <Button variant="primary" onClick={this.deleteItem}>
                            {messages["yes"]}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.editModal} onHide={this.handleEditModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {messages["edit"]} {this.props.view}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{messages["delete_this?"]}</p>
                        <Form>
                            <h5 className="dark_title">
                                {this.props.view} {messages["name_en"]}
                            </h5>
                            <Form.Control
                                type="text"
                                name="english"
                                placeholder="name"
                                onChange={this.onChange}
                            />
                            <h5 className="dark_title">
                                {this.props.view} {messages["name_es"]}
                            </h5>
                            <Form.Control
                                type="text"
                                name="spanish"
                                placeholder="nombre"
                                onChange={this.onChange}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleEditModal}>
                            {messages["close"]}
                        </Button>
                        <Button variant="primary" onClick={this.editItem}>
                            {messages["yes"]}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    management: state.admin.management.management,
    view: state.admin.management.view,
    brand: state.admin.management.brand,
    equipment: state.admin.management.equipment,
    messages: state.lang.messages
});

export default connect(mapStateToProps, {
    setView,
    deleteItem,
    editItem,
    addItem,
    getBrand,
    getEquipment,
    setType,
    addPopper
})(Management);
