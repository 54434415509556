import { useState, useEffect } from "react";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";

export default function useEquipments() {
    const [loading, setLoading] = useState(true);
    const [equipment, setEquipment] = useState([]);
    const [brand, setBrand] = useState([]);
    const [model, setModel] = useState([]);
    const [technologies, setTechnologies] = useState([]);

    useEffect(() => {
        fetchEquipment();
    }, []);

    async function fetchEquipment() {
        try {
            const response = await axios.get(API_CONSTANT_MAP.experiences.get_equipments(1));
            setEquipment(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function fetchBrands(equipmentId) {
        try {
            const response = await axios.get(
                API_CONSTANT_MAP.experiences.get_brand_technologies(equipmentId)
            );
            setBrand(response.data.brands);
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchBrandsEquipment(equipmentId) {
        try {
            const response = await axios.get(API_CONSTANT_MAP.experiences.get_brands(equipmentId));
            setBrand(response.data.brands);
            setTechnologies(response.data.technologies);
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchTechnologies(equipmentId) {
        try {
            const response = await axios.get(
                API_CONSTANT_MAP.experiences.get_technologies(equipmentId)
            );
            setTechnologies(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchModels(equipmentId, brandId) {
        try {
            const response = await axios.get(
                API_CONSTANT_MAP.experiences.get_models(equipmentId, brandId)
            );
            setModel(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function addBrand(data) {
        try {
            const response = await axios.post(API_CONSTANT_MAP.experiences.add_brand, data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function editBrand(data, brandId) {
        try {
            const response = await axios.put(
                API_CONSTANT_MAP.experiences.edit_brand(brandId),
                data
            );
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function deleteBrand(brandId) {
        try {
            const response = await axios.delete(API_CONSTANT_MAP.experiences.edit_brand(brandId));
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function addTech(data) {
        try {
            const response = await axios.post(API_CONSTANT_MAP.experiences.add_tech, data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function editTech(data, techId) {
        try {
            const response = await axios.put(API_CONSTANT_MAP.experiences.edit_tech(techId), data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function deleteTech(techId) {
        try {
            const response = await axios.delete(API_CONSTANT_MAP.experiences.edit_tech(techId));
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function addModel(data) {
        try {
            const response = await axios.post(API_CONSTANT_MAP.experiences.add_model, data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function editModel(data, modelId) {
        try {
            const response = await axios.put(
                API_CONSTANT_MAP.experiences.edit_model(modelId),
                data
            );
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function deleteModel(modelId) {
        try {
            const response = await axios.delete(API_CONSTANT_MAP.experiences.edit_model(modelId));
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return {
        equipment,
        fetchBrands,
        fetchBrandsEquipment,
        brand,
        fetchModels,
        model,
        fetchTechnologies,
        technologies,
        addBrand,
        editBrand,
        deleteBrand,
        addTech,
        editTech,
        deleteTech,
        addModel,
        editModel,
        deleteModel,
        loading
    };
}
