import React from 'react';
import './Marker.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
const Marker = (props) => {
    const { color, name, id } = props;
    return (
      <FaMapMarkerAlt color="#FF003E" size="2rem" className="centerMark"

      />
    );
  };

  export default Marker;