import React from "react";
import ReactDOM from "react-dom";

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require("./bootstrap");
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/Styles/generalStyles.css";
import "./assets/styles/main.css";

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import App from "./app";
ReactDOM.render(<App />, document.getElementById("app"));
