import React from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Table, Container, Nav, Form, Row, Col, Modal } from "react-bootstrap";
import { getLanguages, addLanguage, editLanguage, deleteLanguage } from "@actions/Administrator/language";
import Moment from "moment";
import { addPopper } from "@actions/popper";

class Language extends React.Component {

    state = {
        idLang: null,
        langModal: false,
        editLangModal: false,
        deleteLangModal: false,
        spanish: null,
        english: null,
        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleLangModalOpen = () =>{    this.setState({ langModal: true }); };
    handleLangModalClose = () =>{   this.setState({ langModal: false });    };

    handleEditLangModalOpen = (id) =>{    this.setState({ editLangModal: true, idLang: id }); };
    handleEditLangModalClose = () =>{   this.setState({ editLangModal: false });    };

    handleDeleteLangModalOpen = (id) =>{    this.setState({ deleteLangModal: true, idLang: id }); };
    handleDeleteLangModalClose = () =>{   this.setState({ deleteLangModal: false });    };

    componentDidMount = async () =>{
        if(this.props.languages.loaded === false){
            await this.props.getLanguages(1);
        }
    }

    addLanguage = async () =>{
        let data = {
            es_name: this.state.spanish,
            en_name: this.state.english 
        };
        await this.props.addLanguage(data);
        this.setState({langModal: false});
    };

    editLanguage = async () =>{
        let data = {
            es_name: this.state.spanish,
            en_name: this.state.english 
        };
        await this.props.editLanguage(this.state.idLang, data);
        this.setState({editLangModal: false});
    };

    deleteLanguage = async () =>{
        await this.props.deleteLanguage(this.state.idLang);
        this.setState({deleteLangModal: false});
    };

    changePage = async (symbol) =>{
        if(symbol === "+" && this.props.languages.meta.last_page !== this.props.languages.meta.current_page){
            await this.props.getLanguages((this.props.languages.meta.current_page + 1));
        }
        if(symbol === "-" && this.props.languages.meta.current_page > 1){
            await this.props.getLanguages((this.props.languages.meta.current_page - 1));
        }
    };

    render() {
        const { messages } = this.props;
        return (
            <div className="flex-center full-height position-ref">
                <Container>
                    <Table hover variant="dark" className="Custom_Table" responsive>
                        <thead>
                                <tr>
                                <th>{messages['id']}</th>
                                <th>{messages['name']}</th>
                                <th>{messages['created_at']}</th>
                                <th>{messages['updated_at']}</th>
                                <th>{messages['options']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.props.languages.data.map((language, key) =>{
                                return(
                                    <tr key = {language.id}>
                                        <td>{language.id}</td>
                                        <td>{language.name}</td>
                                        <td>{Moment(language.created_at).format('YYYY/MM/DD')}</td>
                                        <td>{Moment(language.updated_at).format('YYYY/MM/DD')}</td>
                                        <td><Button 
                                            className="Button_Padding"
                                            variant="outline-light"
                                            onClick={() => this.handleEditLangModalOpen(language.id)}>
                                                {messages['edit']}
                                            </Button>
                                            <Button 
                                            className="Button_Padding"
                                            variant="outline-light"
                                            onClick={() => this.handleDeleteLangModalOpen(language.id)}>
                                                {messages['delete']}
                                            </Button>  
                                        </td>
                                    </tr>
                                )})}
                            </tbody>
                        </Table>
                        <ButtonGroup>
                                <Button variant="outline-light" onClick={() => this.changePage("-")}>{messages['prev']}</Button>
                                <Button variant="outline-light" onClick={() => this.changePage("+")}>{messages['next']}</Button>
                        </ButtonGroup>
                        <Button
                        variant="outline-light"
                        onClick={this.handleLangModalOpen}
                        className="Button_Padding"
                        >{messages['add_language']}</Button>  

                    </Container>

                    <Modal show={this.state.langModal} onHide={this.handleLangModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Excess</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Label>{messages['language_name_en']}</Form.Label>
                                <Form.Control type="text" name="english" placeholder="name" onChange={this.onChange}/>
                                <Form.Label>{messages['language_name_es']}</Form.Label>
                                <Form.Control type="text" name="spanish" placeholder="nombre" onChange={this.onChange}/>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.addLanguage}>
                                {messages['add']}
                            </Button>
                            <Button variant="secondary" onClick={this.handleLangModalClose}>
                                {messages['close']}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.editLangModal} onHide={this.handleEditLangModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Excess</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Label>{messages['language_name_en']}</Form.Label>
                                <Form.Control type="text" name="english" placeholder="name" onChange={this.onChange}/>
                                <Form.Label>{messages['language_name_es']}</Form.Label>
                                <Form.Control type="text" name="spanish" placeholder="nombre" onChange={this.onChange}/>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.editLanguage}>
                                {messages['edit']}
                            </Button>
                            <Button variant="secondary" onClick={this.handleEditLangModalClose}>
                                {messages['close']}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.deleteLangModal} onHide={this.handleDeleteLangModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Excess</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {messages['delete_this?']}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.deleteLanguage}>
                                {messages['delete']}
                            </Button>
                            <Button variant="secondary" onClick={this.handleDeleteLangModalClose}>
                                {messages['close']}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profiles: state.admin.profiles.profiles,
    languages: state.admin.languages.languages
});

export default connect(mapStateToProps, {
    getLanguages,
    addLanguage,
    editLanguage, 
    deleteLanguage
})(Language);



                        