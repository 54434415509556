import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const levels = {
    success: "#48BB78",
    warning: "#ED8936",
    error: "#E53E3E"
};

const PopperCardStyled = styled.div`
    padding: 0.5em 1em;
    background: white;
    box-shadow: 0 4px 6px 4px rgba(0, 0, 0, 0.1);
    margin-top: 1em;
    border-radius: 0.25em;
    border-left: 5px solid transparent;

    border-left-color: ${props =>
        props.level === "success"
            ? levels.success
            : props.level === "error"
            ? levels.error
            : levels.warning};

    h1 {
        color: black;
        font-size: 1em;
        margin: 0;
        font-weight: 600;
    }

    p {
        color: black;
        margin: 0.5em 0 0 0;
        font-size: 0.875em;
    }

    div {
        text-align: right;

        button {
            outline: none;
            background: inherit;
            border: none;
            transition: color 200ms ease;

            &:hover {
                color: red;
            }
        }
    }

    transition: all 500ms ease-out;
    animation: fade-in 0.5s ease-in forwards;

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    ${props => props.fadeOut && "animation: fade-out 0.5s ease-in forwards;"}

    @keyframes fade-out {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;

const PopperCard = ({ title, message, level, timeout, onClose }) => {
    const [fadeOut, setFadeOut] = useState(false);
    let closeTimer = null,
        fadeOutTimer = null;

    useEffect(() => {
        fadeOutTimer = setTimeout(() => setFadeOut(true), timeout - 500);
        closeTimer = setTimeout(onClose, timeout);
    }, []);

    return (
        <PopperCardStyled level={level} fadeOut={fadeOut}>
            <div>
                <button
                    onClick={() => {
                        clearTimeout(closeTimer);
                        clearTimeout(fadeOutTimer);
                        setFadeOut(true);
                        setTimeout(onClose, 500);
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <h1>{title}</h1>
            <p>{message}</p>
        </PopperCardStyled>
    );
};

export default PopperCard;
