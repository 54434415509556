import { AUTH } from "@actions/auth";
import { PROFILE_BUSINESS } from "@actions/Client/businessRotation";
import { PROFILE_LINES } from "@actions/Client/lines";

const initialState = {
    token: null,
    publicToken: null,
    rol: null,
    user: null,
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case AUTH.SET_TOKENS:
        case AUTH.LOAD_TOKENS:
        case AUTH.UPDATE_TOKEN:
            return {
                ...state,
                token: action.token,
                publicToken: action.publicToken,
                rol: action.rol
            };
        case AUTH.PROFILE:
            return {
                ...state,
                user: action.user
            };
        case AUTH.REMOVE_TOKENS:
            return {
                ...state,
                token: null,
                publicToken: null,
                rol: null
            };
        case AUTH.CLEAN_AUTH:
            return {
                ...state,
                error: null,
                user: null
            };

        case AUTH.ERROR:
            return {
                ...state,
                error: action.error
            };

        case AUTH.LANGUAGES: {
            let languages = Array.from(state.user.languages);
            languages = languages.filter(e => !action.removed.includes(e.id.toString()));
            return {
                ...state,
                user: {
                    ...state.user,
                    languages: [...languages, ...action.selected]
                }
            };
        }

        case PROFILE_BUSINESS.ADD_BUSINESS_ROTATION: {
            return {
                ...state,
                user: {
                    ...state.user,
                    company: { business_logics: action.businessRotation }
                }
            };
        }

        case PROFILE_BUSINESS.ADD_BUSINESS_ROTATION: {
            return {
                ...state,
                user: { ...state.user, company: { business_logics: action.businessRotation } }
            };
        }

        case PROFILE_BUSINESS.DELETE_BUSINESS_ROTATION: {
            let data = state.user.company.business_logics.filter(e => e.id !== action.id);
            let aux = Object.assign({}, state.user);
            aux.company.business_logics = data;
            return {
                ...state,
                user: aux
            };
        }

        case PROFILE_LINES.ADD_LINES: {
            let aux = Object.assign({}, state.user);
            aux.company.business_logics.forEach(element => {
                if (element.id === action.idBusiness) {
                    element.lines.forEach((line, index) => {
                        if (line.id === action.lines.lines[0].id) {
                            element.lines.splice(index, 1);
                        }
                    });
                    element.lines.push(action.lines.lines[0]);
                }
            });
            return {
                ...state,
                user: aux
            };
        }

        case PROFILE_LINES.DEL_LINES: {
            let aux = Object.assign({}, state.user);
            aux.company.business_logics.forEach(element => {
                if (element.id === action.lines.business_id) {
                    element.lines.forEach((line, index) => {
                        if (line.id === action.lines.id) {
                            element.lines.splice(index, 1);
                        }
                    });
                }
            });

            return {
                ...state,
                user: aux
            };
        }

        case AUTH.UPDATE_CHANGE_OF_PASSWORD:
            return {
                ...state,
                user: {
                    ...state.user,
                    force_change_of_password: action.change_of_password
                }
            };
        default:
            return state;
    }
}
