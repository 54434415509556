import React from "react";
import { Redirect } from "react-router-dom";
import { Form, Button, Col, Row, Container, Spinner } from "react-bootstrap";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";

class TechnicianRegister extends React.Component {
    state = {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
        errors: {},
        validated: false,
        redirect: false,
        is_loading: false
    };

    async componentDidMount() {
        const { route } = this.props;

        const { token } = route.match.params;
        const response = await axios.get(API_CONSTANT_MAP.invitation(0, token));

        const { data } = response;
        if (!data.success) {
            this.setState({
                redirect: true
            });
        } else {
            this.setState({
                validated: true
            });
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onMakeRequest = async () => {
        this.setState({
            is_loading: true,
            errors: {}
        });

        const data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,
            token: this.props.route.match.params.token
        };

        try {
            await axios.post(API_CONSTANT_MAP.register("technician"), data);
            this.setState({
                is_loading: false,
                redirect: true
            });
        } catch (err) {
            this.setState({
                errors: err.response.data,
                is_loading: false
            });
        }
    };

    displayErrors = field => {
        if (field) {
            return (
                <label className="mt-2">
                    {field.map((msg, index) => {
                        return (
                            <span className="text-white" key={index}>
                                {msg}
                                <br />
                            </span>
                        );
                    })}
                </label>
            );
        }

        return null;
    };

    render() {
        const { is_loading, redirect, validated, errors } = this.state;
        const { firstName, lastName, username, email, password } = errors;

        if (redirect) {
            if (validated) {
                return <Redirect to="/login" />;
            } else {
                return <Redirect to="/" />;
            }
        }

        return (
            <>
                <Form>
                    <h1 className="mb-4 text-white text-center">Sign Up</h1>
                    <Container className="form-fixed-width">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Username
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        name="username"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(username)}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        name="email"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(email)}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Name
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        name="firstName"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(firstName)}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Last Name
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        name="lastName"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(lastName)}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Password
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="password"
                                        name="password"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(password)}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Password Confirmation
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="password"
                                        name="password_confirmation"
                                        onChange={this.onChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                {is_loading && (
                                    <Spinner
                                        variant="light"
                                        animation="border"
                                    />
                                )}
                                {!is_loading && (
                                    <Button
                                        variant="outline-light"
                                        size="sm"
                                        type="submit"
                                        onClick={this.onMakeRequest}
                                    >
                                        Sign Up
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </>
        );
    }
}

export default TechnicianRegister;
