import { LANGUAGE } from "@actions/Administrator/language";

const initialState = {
    languages: {
        data: [],
        meta: {},
        loaded: false,
    },
};

export default function(state = initialState, action) {
    switch (action.type) {

        case LANGUAGE.SET_LANGUAGES:
            {
                return{
                    ...state,
                    languages: {
                        ...state.languages,
                        data: action.languages.data,
                        meta: action.languages.meta,
                        loaded: true
                    },
                }
            }
        
        case LANGUAGE.ADD_LANGUAGE:
            {
                if(state.languages.data.length < 5){
                    return{
                        ...state,
                        languages: {
                            ...state.languages,
                            data: [...state.languages.data, action.language]
                        }
                    }
                }
                else{
                    if(state.languages.meta.current_page === state.languages.meta.last_page){
                        return{
                            ...state,
                            languages: {
                                ...state.languages,
                                meta : {
                                    ...state.languages.meta,
                                    last_page: state.languages.meta.last_page + 1
                                }
                            }
                        }
                    }
                    else{
                        return{
                            ...state
                        }
                    }   
                }
            }

        case LANGUAGE.EDIT_LANGUAGE:
            {
                const updateIndex = state.languages.data.findIndex(data => data.id == action.id);
                let tempData = Array.from(state.languages.data);
                tempData[updateIndex] = action.language;
                return{
                    ...state,
                    languages: {
                        ...state.languages,
                        data: tempData
                    }
                }
            }
        
        case LANGUAGE.DELETE_LANGUAGE:
            {
                return{
                    ...state,
                    languages: {
                        ...state.languages,
                        data: state.languages.data.filter(data => data.id !== action.id)
                    }
                }
            }

        default:
            return state;
    }
}
