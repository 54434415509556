const card = {
    backgroundColor: "slategray",
    padding: "20px",
    borderRadius: "10px",
    margin: "20px",
    display: "flex",
    flexDirection: "column"
};

const title = {
    fontSize: "2rem",
    color: "white"
};

const key = {
    fontSize: "1rem",
    color: "white"
};

const data = {
    fontSize: "1.5rem",
    color: "white"
};

const flexRow = {
    display: "flex",
    flexDirection: "row",
    marginTop: "1rem"
};

const image = {
    width: "50%",
    height: "auto",
    padding: "10px"
};

export default { card, title, key, data, flexRow, image };
