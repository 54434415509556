import Client from "@views/private/client/Dashboard";
import FactoryInformation from "@views/private/client/FactoryInformation";
import BusinessRotation from "@views/private/client/BusinessRotation";
import Passport from "@views/private/client/Passport";
import SecurityRequirements from "@views/private/client/SecurityRequirements";
import Factory from "@views/private/client/Factory";
import Service from "@views/private/client/Service";
import Options from "@views/private/client/Options";
import RequestService from "@views/private/client/ServiceForm";
import TechnicianList from "@views/private/client/TechnicianList";
import ServiceDetail from "@views/private/client/ServiceDetails";

export const Routes = {
    Client: {
        component: Client,
        path: "/c"
    },

    FactoryInformation: {
        component: FactoryInformation,
        path: "/c/factory_information"
    },

    BusinessRotation: {
        component: BusinessRotation,
        path: "/c/business_rotation"
    },

    Passport: {
        component: Passport,
        path: "/c/passport"
    },

    SecurityRequirements: {
        component: SecurityRequirements,
        path: "/c/security_requirements"
    },

    Factory: {
        component: Factory,
        path: "/c/factory"
    },

    Service: {
        component: Service,
        path: "/c/service"
    },
    ServiceDetail: {
        component: ServiceDetail,
        path: "/c/service/:id"
    },
    Options: {
        component: Options,
        path: "/c/options"
    },

    ServiceForm: {
        component: RequestService,
        path: "/c/request_service"
    },

    TechnicianList: {
        component: TechnicianList,
        path: "/c/technician_list"
    },
};

export const Links = [
    {
        key: "dashboard",
        to: "/c"
    },
    {
        key: "factory",
        to: "/c/factory"
    },
    {
        key: "factory_information",
        to: "/c/factory_information"
    },
    {
        key: "service",
        to: "/c/service"
    },
    {
        key: "options",
        to: "/c/options"
    }
];
