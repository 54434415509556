import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Form, Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { API_CONSTANT_MAP } from "@utils/routes";

class ResetPassword extends React.Component {
    state = {
        redirect: false,
        validated: false,
        password: "",
        password_confirmation: "",
        token: "",
        is_loading: false,
        errors: {}
    };

    async componentDidMount() {
        const { token } = this.props.route.match.params;
        const response = await axios.post(API_CONSTANT_MAP.check_reset, {
            token
        });

        if (response.data.success) {
            this.setState({ validated: true, token });
        } else {
            this.setState({ validated: false, redirect: true });
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onMakeRequest = async e => {
        e.preventDefault();
        this.setState({ errors: {}, is_loading: true });

        const data = {
            token: this.state.token,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation
        };

        try {
            await axios.post(API_CONSTANT_MAP.reset_password, data);
            this.setState({ redirect: true });
        } catch (err) {
            this.setState({
                errors: err.response.data.errors,
                is_loading: false
            });
        }
    };

    render() {
        const { validated, redirect, errors, is_loading } = this.state;
        const { password } = errors;
        if (redirect) {
            if (validated) {
                return <Redirect to="/login" />;
            } else {
                return <Redirect to="/" />;
            }
        }

        return (
            <Form>
                <h1 className="mb-4 text-white text-center">Reset Password</h1>
                {!validated && (
                    <p className="text-center">
                        <br />
                        <Spinner animation="border" variant="light" />
                    </p>
                )}
                {validated && (
                    <Container className="form-fixed-width">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">Password</Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="password"
                                        name="password"
                                        onChange={this.onChange}
                                    />
                                    {password && (
                                        <label className="mt-2">
                                            {password.map((msg, index) => {
                                                return (
                                                    <span className="text-white" key={index}>
                                                        {msg}
                                                        <br />
                                                    </span>
                                                );
                                            })}
                                        </label>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Password Confirmation
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="password"
                                        name="password_confirmation"
                                        onChange={this.onChange}
                                    />
                                </Form.Group>{" "}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                {is_loading && <Spinner variant="light" animation="border" />}
                                {!is_loading && (
                                    <Button
                                        variant="outline-light"
                                        size="sm"
                                        type="submit"
                                        onClick={this.onMakeRequest}
                                    >
                                        Reset Password
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                )}
            </Form>
        );
    }
}

export default ResetPassword;
