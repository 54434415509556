import React from "react";
import { Table, Button, ButtonGroup } from "react-bootstrap";
import Moment from "moment";
import MetaButton from "@components/shared/buttons/MetaButton";
import styles from "./styles";

const RequestServiceTable = ({
    data = [],
    loading,
    messages,
    seeDetails,
    meta = { current_page: 0, last_page: 0 },
    status,
    changePage,
    changeStatus
}) => {
    return (
        <div className="container">
            <div style={styles.flexRow}>
                <div style={styles.status}>Current status: {status}</div>
                <Button style={styles.button} variant="dark" onClick={() => changeStatus()}>
                    Change status
                </Button>
            </div>
            <Table hover variant="dark" className="Custom_Table m-10 flex-1" responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>{messages["client"]}</th>
                        <th>{messages["service"]}</th>
                        <th>{messages["specialty"]}</th>
                        <th>{messages["created_at"]}</th>
                        <th>{messages["options"]}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(service => {
                        return (
                            <tr key={service.id}>
                                <td>{service.id}</td>
                                <td>{service.client.firstName + " " + service.client.lastName}</td>
                                <td>
                                    {typeof service.service === "object"
                                        ? service.service.name
                                        : service.service}{" "}
                                </td>
                                <td>
                                    {typeof service.specialty === "object"
                                        ? service.specialty.name
                                        : service.specialty}{" "}
                                </td>
                                <td>{Moment(service.created_at).format("YYYY/MM/DD")}</td>
                                <td>
                                    <Button
                                        variant="outline-light"
                                        className="Button_Padding"
                                        onClick={() => seeDetails(service)}
                                    >
                                        {messages["seeDetails"]}
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <MetaButton meta={meta} changePage={page => changePage(page)} />
        </div>
    );
};

export default RequestServiceTable;
