import React from "react";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";

export default function useRequestServices() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("OPEN");

    useEffect(() => {
        fetchRequestServices();
    }, [page, status]);

    const fetchRequestServices = useCallback(async () => {
        try {
            const response = await axios.get(API_CONSTANT_MAP.request_services.get(page, status));
            console.log("hla", response.data);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [page, status]);

    return {
        loading,
        data,
        status,
        setPage,
        setStatus
    };
}
