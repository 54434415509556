import {v4 as uuidv4} from 'uuid';

export const POPPER = {
    ADD_POPPER: "ADD_POPPER",
    REMOVE_POPPER: "REMOVE_POPPER"
};

export const addPopper = (popper) => dispatch => {
    const id = uuidv4().toString();
    dispatch({
        type: POPPER.ADD_POPPER,
        popper: {
            ...popper,
            id
        }
    });
};

export const removePoppper = (id) => dispatch => {
    dispatch({
        type: POPPER.REMOVE_POPPER,
        id
    });
};
