import { MANAGEMENT } from "@actions/Administrator/management";

const initialState = {
    level: 1,
    specialty: 1,
    specialties: {
        data: [],
    },
    management: {
        equipment:  { loaded: false, data:[], meta: [] },
        brand:      { loaded: false, data:[], meta: [] },
        technology: { loaded: false, data:[], meta: [] },
        model:      { loaded: false, data:[], meta: [] }
    },

    view: "equipment",
    brand: null,
    equipment: null,
    levels: {
        data: [],
        meta: {}
    },
    father_levels: null,
    custom: null,
    error: null,
    services: {
        0: { loaded: false, data:[] },
        1: { loaded: false, data:[] },
        2: { loaded: false, data:[] },
        3: { loaded: false, data:[] },
        4: { loaded: false, data:[] }
    },
    service: [],
    servicePage: 1,
    serviceStatus: 1
};

export default function(state = initialState, action) {
    switch (action.type) {

        case MANAGEMENT.SET_VIEW:
            {
                return{
                    ...state,
                    management: {
                        ...state.management,
                        [action.view]: { loaded: true, data: action.data.data, meta: action.data.meta },
                    } ,
                    view: action.view,
                }
            }

        case MANAGEMENT.SET_TYPE:
            {
                return{
                    ...state,
                    view: action.view
                }
            }

        case MANAGEMENT.DELETE_ITEM:
            {
                let aux = Array.from(state.management[state.view].data);
                aux = aux.filter(e => e.id !== action.id);
                return {
                    ...state,
                    management: {
                        ...state.management,
                        [state.view]: {
                            ...state.management[state.view],
                            data: aux
                        }
                    }
                }
            }

        case MANAGEMENT.EDIT_ITEM:
            {
                const updateIndex = state.management[state.view].data.findIndex(data => data.id == action.item.id);
                let tempData = Array.from(state.management[state.view].data);
                tempData[updateIndex] = action.item;
                return{
                    ...state,
                    management: {
                        ...state.management,
                        [state.view]: {
                            ...state.management[state.view],
                            data: tempData
                        }
                    }
                }
            }

        case MANAGEMENT.ADD_ITEM:
            {
                if(state.management[state.view].data.length < 5){
                    return {
                        ...state,
                        management: {
                            ...state.management,
                            [state.view]: {
                                ...state.management[state.view],
                                data: [...state.management[state.view].data, action.item]
                            }
                        }
                    }
                }

                return{
                    ...state,
                    management: {
                        ...state.management,
                        [state.view]: {
                            ...state.management[state.view],
                            meta: {
                                ...state.management[state.view].meta,
                                last_page: state.management[state.view].meta.last_page + 1
                            }
                        }
                    }
                }
            }

        case MANAGEMENT.GET_BRAND:
            {
                return {
                    ...state,
                    brand: action.brand
                }
            }
        
        case MANAGEMENT.GET_EQUIPMENT:
            {
                return {
                    ...state,
                    equipment: action.equipment
                }
            }
        
        case MANAGEMENT.GET_LEVELS:
            {
                return{
                    ...state,
                    levels:{
                        ...state.levels,
                        data: action.levels.data,
                        meta: action.levels.meta
                    },
                    level: action.level,
                    specialty: action.specialty,
                }
            }
        case MANAGEMENT.GET_FATHER_LEVELS:
            {
                return{
                    ...state,
                    father_levels: action.levels
                }
            }

        case MANAGEMENT.GET_SPECIALTY:
            {
                return{
                    ...state,
                    specialties:{
                        ...state.specialties,
                        data: action.specialty.data
                    }
                }
            }

        case MANAGEMENT.EDIT_LEVEL:
            {
                const updateIndex = state.levels.data.findIndex(data => data.id == action.level.id);
                let tempData = Array.from(state.levels.data);
                tempData[updateIndex] = action.level;
                return{
                    ...state,
                    levels: {
                        ...state.levels,
                        data: tempData
                    }
                }
            }
        
        case MANAGEMENT.ADD_LEVEL:
            {
                if(state.levels.data.length < 5){
                    return{
                        ...state,
                        levels: {
                            ...state.levels,
                            data: [...state.levels.data, action.level]
                        }
                    }
                }

                return{
                    ...state,
                    levels: {
                        ...state.levels,
                        meta: {
                            ...state.levels.meta,
                            last_page: state.levels.meta.last_page + 1
                        }
                    }
                }
            }
        
        case MANAGEMENT.DELETE_LEVEL:
            {
                let aux = Array.from(state.levels.data);
                aux = aux.filter(e => e.id !== action.id);
                return {
                    ...state,
                    levels: {
                        ...state.levels,
                        data: aux
                    }
                }

            }

        case MANAGEMENT.GET_CUSTOM:
            {
                return{
                    ...state,
                    custom: action.custom
                }
            }

        case MANAGEMENT.GET_SERVICES:
            {
                return{
                    ...state,
                    services: {
                        ...state.services,
                        [action.status]: { loaded: true, data: action.services.data},
                    } ,
                    servicePage: action.page,
                    serviceStatus: action.status
                }
            }

        case MANAGEMENT.SET_SERVICE:
            {
                return{
                    ...state,
                    service: action.service
                }
            }

        case MANAGEMENT.EDIT_SERVICE_STATUS:
            {
                return{
                    ...state,
                    services: {
                        ...state.services,
                        [state.serviceStatus]: { data: state.services[state.serviceStatus].data.filter(element => element.id !== action.service.id)},
                        [action.service.status]: { data: [...state.services[action.service.status].data, action.service] }
                    },
                }
            
            }

        case MANAGEMENT.SET_STATUS:
            {
                return{
                    ...state,
                    serviceStatus: action.status
                }
            }

        default:
            return state;
    }
}
