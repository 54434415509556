import React from "react";
export function Star() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24">
            <path
                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.62L12 2L9.19 8.62L2 9.24l5.45 4.73L5.82 21L12 17.27z"
                fill="#DBC500"
            ></path>
        </svg>
    );
}
