import { SERVICE } from "@actions/Administrator/serviceSimulator/client/service";

const initialState = {
    service: [],
    models: [],
    technicians: null,
    technician: null,
    idService: null,
    status: 1,
    options: null,
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SERVICE.ADMIN_GET_SERVICES: {
            return {
                ...state,
                service: action.service
            };
        }

        case SERVICE.ADMIN_SET_STATUS: {
            return {
                ...state,
                status: action.status
            };
        }

        case SERVICE.ADMIN_GET_REQUEST_OPTIONS: {
            return {
                ...state,
                options: action.options
            };
        }

        case SERVICE.ADMIN_GET_TECHNICIANS: {
            return {
                ...state,
                technicians: action.technicians,
            };
        }

        case SERVICE.ADMIN_CANCEL_SERVICE: {
            return {
                ...state
            };
        }

        case SERVICE.ADMIN_GET_SPECIFIC_MODEL: {
            return{
                ...state,
                models: action.models
            }
        }

        case SERVICE.ADMIN_SEE_TECHNICIAN_DETAILS: {
            return{
                ...state,
                technician: action.technician
            }
        }

        default:
            return state;
    }
}
