import { BUSINESS_ROTATION } from "@actions/Client/businessRotation";

const initialState = {
    businessRotation: null,
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {

        case BUSINESS_ROTATION.GET_BUSINESS_ROTATION:
            {
                return {
                    ...state,
                    businessRotation: action.businessRotation
                };
            }

        default:
            return state;
    }
}
