import React from "react";
import { connect } from "react-redux";
import { Container, Image, Form, Button } from "react-bootstrap";
import { getProfile } from "@actions/Administrator/documents";
import Moment from "moment";
import { API_CONSTANT_MAP } from "@utils/routes";
import axios from "axios";

class Profile extends React.Component {

    state = {
        review: null,
        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentDidMount = async () =>{
        await this.props.getProfile(this.props.profile, this.props.idProfile);
    }


    
    onMakeRequest = async (symbol) => {
        if(symbol === "+"){
            let Data ={
                id: this.props.idProfile,
                status: 2,
                message: this.state.review,
                role: this.props.profile
            };

            try {
                const response = await axios.put(API_CONSTANT_MAP.docs, Data);
                if(response.status === 200){
                    this.props.route.history.push("/a/documents/client");
                }

            } catch (err) {
                console.log(err.data);
            }
        }
        if(symbol === "-"){
            let Data ={
                id: this.props.idProfile,
                status: 1,
                message: this.state.review,
                role: this.props.profile
            };

            try {
                const response = await axios.put(API_CONSTANT_MAP.docs, Data);
                if(response.status === 200){
                    this.props.route.history.push("/a/documents/client");
                }
            } catch (err) {
                console.log(err.data);
            }
        }
    };

    render() {
        const { messages, publicToken } = this.props;
        let Data = [];
        if(this.props.data !== null){
            Data = this.props.data
        }
        return (
            <div className="flex-center full-height position-ref">
                <Container>
                    <h1> <b>{messages['user_information']}</b></h1>
                    <h3><b>{messages['first_name']}:</b> {Data.firstName}</h3> 
                    <h3><b>{messages['last_name']}:</b> {Data.lastName}</h3>
                    <h3><b>{messages['username']}:</b> {Data.username}</h3> 
                    <h3><b>{messages['created_at']}:</b> { Moment(Data.created_at).format('YYYY/MM/DD') }</h3>
                    <h3><b>{messages['email']}:</b> {Data.email}</h3> 
                    <h3><b>{messages['phone_number']}:</b> {Data.phoneNumber}</h3>
                    <h1>{messages['passport']}</h1>
                    {Data.passport &&
                        <Image src={`${Data.passport.url}/${this.props.publicToken}`} className="Passport_admin" fluid />
                    }
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="white_title">{messages['review']}</Form.Label>
                        <Form.Control as="textarea" rows="2" name="review" onChange={this.onChange}/>
                    </Form.Group>
                    <Button 
                    className="Button_Padding"
                    variant="outline-light"
                    onClick={() => this.onMakeRequest("+")}>
                        {messages['accept']}
                    </Button>
                    <Button 
                    className="Button_Padding"
                    variant="outline-light"
                    onClick={() => this.onMakeRequest("-")}>
                        {messages['reject']}
                    </Button>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profile: state.admin.documents.profile,
    idProfile: state.admin.documents.id_profile,
    data: state.admin.documents.data,
    publicToken: state.auth.publicToken,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    getProfile
})(Profile);



                        