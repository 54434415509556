import axios from "axios";


export const PAYMENTS = {
    GET_TECHNICIAN_COEFFICIENTS: "GET_TECHNICIAN_COEFFICIENTS",
    PUT_TECHNICIAN_COEFFICIENTS: "PUT_TECHNICIAN_COEFFICIENTS",
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: PAYMENTS.ERROR,
    error
});

export const getTechnicianCoefficients = (specialtyId, page) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_coefficients(specialtyId, page));
        dispatch({
            type: PAYMENTS.GET_TECHNICIAN_COEFFICIENTS,
            response: response.data,
            specialtyId: specialtyId,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const putTechnicianCoefficients = (specialtyId, coefficient) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.put_coefficients(specialtyId), coefficient);
        dispatch({
            type: PAYMENTS.PUT_TECHNICIAN_COEFFICIENTS,
            response: response.data,
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};




