import { PAYMENTS } from "@actions/Administrator/payments";

const initialState = {
    technician_coefficients: {
        data: [],
        meta: {},
        loaded: false,
        specialty_id: 1
    },
};

export default function(state = initialState, action) {
    switch (action.type) {

        case PAYMENTS.GET_TECHNICIAN_COEFFICIENTS:
            {
                return{
                    ...state,
                    technician_coefficients: {
                        ...state.technician_coefficients,
                        data: action.response.data,
                        meta: action.response.meta,
                        specialty_id: action.specialtyId,
                        loaded: true
                    },
                }
            }

        case PAYMENTS.PUT_TECHNICIAN_COEFFICIENTS:
            {
                const updateIndex = state.technician_coefficients.data.findIndex(data => data.id == action.response.id);
                let tempData = Array.from(state.technician_coefficients.data);
                tempData[updateIndex] = action.response;
                return{
                    ...state,
                    technician_coefficients: {
                        ...state.technician_coefficients,
                        data: tempData
                    }
                }
            }
        
        default:
            return state;
    }
}
