import React from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Table, Container, Nav, Form, Row, Col } from "react-bootstrap";
import { getProfiles } from "@actions/Administrator/profiles";

class Client extends React.Component {

    state = {
        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentDidMount = async () =>{
        await this.props.getProfiles("client", 1);
    };

    onMakeRequest = async (id) =>{
        this.props.route.history.push("/a/user_information/client/" + id);
    };

    changePage = async (symbol) =>{
        if(symbol === "+" && this.props.profiles.client.meta.current_page < this.props.profiles.client.meta.last_page){
            await this.props.getProfiles("client", (this.props.profiles.client.meta.current_page + 1));
        }
        if(symbol === "-" && this.props.profiles.client.meta.current_page > 1){
            await this.props.getProfiles("client", (this.props.profiles.client.meta.current_page - 1));
        }
    };

    render() {
        const { messages } = this.props;
        return (
            <div className="flex-center full-height position-ref">
                <Container>
                    <Table hover variant="dark" className="Custom_Table" responsive>
                        <thead>
                                <tr>
                                <th>{messages['id']}</th>
                                <th>{messages['first_name']}</th>
                                <th>{messages['last_name']}</th>
                                <th>{messages['email']}</th>
                                <th>{messages['options']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.props.profiles.client.data.map((profile, key) =>{
                                return(
                                    <tr key = {profile.id}>
                                        <td>{profile.id}</td>
                                        <td>{profile.firstName}</td>
                                        <td>{profile.lastName}</td>
                                        <td>{profile.email }</td>
                                        <td><Button 
                                            className="Button_Padding"
                                            variant="outline-light"
                                            onClick={() => this.onMakeRequest(profile.id)}>
                                                {messages['view_profile']}
                                            </Button> 
                                        </td>
                                    </tr>
                                )})}
                            </tbody>
                        </Table>
                        <ButtonGroup>
                                <Button variant="outline-light" onClick={() => this.changePage("-")}>{messages['prev']}</Button>
                                <Button variant="outline-light" onClick={() => this.changePage("+")}>{messages['next']}</Button>
                        </ButtonGroup>

                    </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profiles: state.admin.profiles.profiles
});

export default connect(mapStateToProps, {
    getProfiles
})(Client);



                        