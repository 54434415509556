import React from "react";
import Plyr from "@components/Plyr";
import { useSelector } from "react-redux";

const VideoGallery = ({ resources, title }) => {
    const publicToken = useSelector(state => state.auth.publicToken);

    const s = resources.map(r => {
        const src = `${r.url}/${publicToken}`;

        return {
            src,
            type: r.mime_type,
            size: 720
        };
    });

    const sources = {
        type: "video",
        sources: s
    };

    if (s.length === 0) {
        return <div className="mb-2 pt-4"></div>
    }

    return (
        <>
            <h1 className="pt-4 mb-2">{title}</h1>
            <div className="gallery-grid videos">
                <Plyr sources={sources} controls />
            </div>
        </>
    );
};

export default VideoGallery;
