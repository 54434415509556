import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";
import { setTokens, fetchProfile } from "@actions/auth";
import { setLanguage } from "@actions/lang";
import { API_CONSTANT_MAP } from "@utils/routes";

class Login extends React.Component {
    state = {
        username: "",
        password: "",
        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onMakeRequest = async () => {
        const data = {
            username: this.state.username,
            password: this.state.password,
            lang: this.props.locale
        };

        this.setState({
            is_loading: true,
            error: null
        });

        try {
            const response = await axios.post(API_CONSTANT_MAP.login, data);

            this.setState({
                is_loading: false
            });

            const token = response.data.access_token.toString();
            const publicToken = response.data.public_token.toString();

            await this.props.setLanguage(this.props.locale, response.data.type);
            await this.props.setTokens({ token, publicToken });
            await this.props.fetchProfile(response.data.type);
        } catch (err) {
            this.setState({
                error: "Invalid Credentials",
                is_loading: false
            });
        }
    };

    render() {
        const { username, password, is_loading, error } = this.state;

        return (
            <Form>
                <h1 className="text-center text-white mb-4">Sign In</h1>
                <Form.Group>
                    <Form.Control
                        size="md"
                        type="text"
                        placeholder="Username"
                        name="username"
                        onChange={this.onChange}
                        value={username}
                    />
                    <Form.Control
                        size="md"
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={this.onChange}
                        className="mt-4 mb-2"
                        value={password}
                    />
                    <div className="text-center">
                        {error && <p className="text-white">{error}</p>}
                        {!error && <br />}

                        {is_loading && <Spinner animation="border" variant="light" />}
                        {!is_loading && (
                            <Button variant="outline-light" size="sm" onClick={this.onMakeRequest}>
                                Sign In
                            </Button>
                        )}
                        <p className="pt-4">
                            <Link to="/forgot" className="text-white">
                                Forgot Password?
                            </Link>
                        </p>
                    </div>
                </Form.Group>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    locale: state.lang.locale
});

export default connect(mapStateToProps, {
    setTokens,
    fetchProfile,
    setLanguage
})(Login);
