import React from "react";
import { connect } from "react-redux";
import { Container, Spinner, Button, Modal, Form } from "react-bootstrap";
import { fetchDocuments, saveDocuments } from "@actions/technician";
import { getUserDocuments, reviewDocument } from "@actions/Administrator/documents";
import File from "@components/admin/documents/file";

const files = [
    { key: "passport", required: true },
    { key: "passport_with_selfie", required: true },
    { key: "proof_of_address", required: true },
    { key: "medical_expense_insurance", required: true },
    { key: "life_insurance", required: true },
    { key: "diploma", required: true },
    { key: "certifications", required: false },
    { key: "licenses", required: false },
    { key: "vaccines", required: false },
    { key: "visas", required: false }
];

class Documents extends React.Component {
    state = {
        files: {},
        documentModal: false,
        key: null,
        comment: null,
        status: 1
    };

    async componentDidMount() {
        await this.props.getUserDocuments(
            this.props.route.match.params.profile,
            this.props.route.match.params.id
        );
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleDocumentModal = () => {
        this.setState({ documentModal: false });
    };

    OpenDocumentModal = key => {
        this.setState({ key, documentModal: true });
    };

    retrieveDocuments = key => {
        const files = [
            ...(this.state.files[key] || []),
            ...(this.props.userDocuments.documents[key]?.files || [])
        ];
        return (
            <File
                files={files}
                documents={this.props.userDocuments.documents}
                keyInfo={key}
                publicToken={this.props.publicToken}
            />
        );
    };

    retrieveData = key => {
        if (this.props.userDocuments.documents[key]) {
            return {
                type: this.props.userDocuments.documents[key].type,
                status: this.props.userDocuments.documents[key].status,
                comment: this.props.userDocuments.documents[key].comment
            };
        }
        return null;
    };

    reviewFile = async () => {
        const data = {
            [this.state.key]: {
                comment: this.state.comment,
                status: this.state.status
            }
        };
        this.handleDocumentModal();
        await this.props.reviewDocument(
            this.props.route.match.params.profile,
            this.props.route.match.params.id,
            data
        );
        this.setState({ documentModal: false, status: 1 });
    };

    render() {
        const { messages, loaded, documents } = this.props;
        const { is_saving } = this.state;
        return (
            <Container>
                <h1 className="reset-header text-white pt-4 mb-2">{messages["documents"]}</h1>
                <p className="text-white mb-4">
                    In order to apply for a service, you first need to upload the different
                    documents, some of them are required(*) and some others optional.
                </p>
                {!this.props.userDocuments.loaded && <Spinner animation="border" variant="light" />}
                {this.props.userDocuments.loaded &&
                    files.map(file => {
                        return (
                            <div
                                key={file.key}
                                style={{
                                    backgroundColor: "#212121",
                                    margin: 5,
                                    padding: 10,
                                    borderRadius: 5,
                                    marginTop: 10
                                }}
                            >
                                <h2 style={{ color: "#F0F0F0" }}>
                                    {messages[file.key]} {file.required && "*"} {file.status}
                                </h2>
                                {this.retrieveDocuments(file.key)}
                                <Button
                                    style={{ marginTop: 5 }}
                                    size="sm"
                                    variant="outline-light"
                                    onClick={() => this.OpenDocumentModal(file.key)}
                                >
                                    {messages["review"]}
                                </Button>
                            </div>
                        );
                    })}

                <Modal show={this.state.documentModal} onHide={this.handleDocumentModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change {messages[this.state.key]} status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                name="comment"
                                onChange={this.onChange}
                            />
                            <Form.Label>Status</Form.Label>
                            <Form.Control as="select" name="status" onChange={this.onChange}>
                                <option value="ON_REVIEW"> On review </option>
                                <option value="VALIDATED"> Validated </option>
                                <option value="EXPIRED"> Expired </option>
                            </Form.Control>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.reviewFile}>
                            Ok
                        </Button>
                        <Button variant="secondary" onClick={this.handleDocumentModal}>
                            {messages["close"]}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    publicToken: state.auth.publicToken,
    userDocuments: state.admin.documents.user_documents
});

export default connect(mapStateToProps, {
    fetchDocuments,
    saveDocuments,
    getUserDocuments,
    reviewDocument
})(Documents);
