import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Button, Row, Col, Container, Image, Jumbotron } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Service = ({
    location,
    setLocation,
    prodLines,
    setProdLines,
    typeProduct,
    setTypeProduct,
    specialties,
    specialty,
    setSpecialty,
    equipment,
    setEquipment,
    equipments,
    brand,
    setBrand,
    brands,
    model,
    setModel,
    models,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    sendRequest
}) => {
    const messages = useSelector(state => state.lang.messages);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const files = acceptedFiles.map(file => (
        <li key={file.path} style={{ color: "white" }}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <Form style={{ padding: 40 }}>
            <h1>General Information</h1>
            <Row>
                <Col>
                    <Form.Label className="white_title">Location</Form.Label>
                    <Form.Control
                        as="select"
                        name="specialty"
                        custom
                        onChange={e => setLocation(e.target.value)}
                    >
                        <option></option>
                        <option value="19.432241,-99.177254">CDMX</option>
                        <option value="20.659698,-103.349609">Guadalajara</option>
                        <option value="19.033333,-98.183334">Puebla</option>
                    </Form.Control>
                </Col>
                <Col>
                    <Form.Label className="white_title">Number of production lines</Form.Label>
                    <Form.Control
                        type="number"
                        name="description"
                        min="1"
                        value={prodLines}
                        onChange={e => setProdLines(e.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label className="white_title">Main product</Form.Label>
                    <Form.Control as="select" name="specialty" custom>
                        <option></option>
                    </Form.Control>
                </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
                <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
            </Row>
            <div style={{ marginTop: 20 }}>
                <h2>Files</h2>
                <ul>{files}</ul>
            </div>
            <div style={{ marginTop: 20 }}>
                <h1>Technical Information</h1>
            </div>
            <Row>
                <Col>
                    <Form.Label className="white_title">Type of technician</Form.Label>
                    <Form.Control
                        as="select"
                        name="specialty"
                        value={specialty}
                        onChange={e => setSpecialty(e.target.value)}
                        custom
                    >
                        <option></option>
                        {specialties.map(sp => (
                            <option key={sp.id} value={sp.id}>
                                {sp.name}
                            </option>
                        ))}
                    </Form.Control>
                </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
                <Col>
                    <Form.Label className="white_title">Type of equipment</Form.Label>
                    <Form.Control
                        as="select"
                        name="specialty"
                        value={equipment}
                        onChange={e => setEquipment(e.target.value)}
                        custom
                    >
                        <option></option>
                        {equipments.map(eq => (
                            <option key={eq.id} value={eq.id}>
                                {eq.name}
                            </option>
                        ))}
                    </Form.Control>
                </Col>
                <Col>
                    <Form.Label className="white_title">Trademark</Form.Label>
                    <Form.Control
                        as="select"
                        name="specialty"
                        value={brand}
                        onChange={e => setBrand(e.target.value)}
                        custom
                    >
                        <option></option>
                        {brands.map(br => (
                            <option key={br.id} value={br.id}>
                                {br.name}
                            </option>
                        ))}
                    </Form.Control>
                </Col>
                <Col>
                    <Form.Label className="white_title">Model of equipment</Form.Label>
                    <Form.Control
                        as="select"
                        name="specialty"
                        value={model}
                        onChange={e => setModel(e.target.value)}
                        custom
                    >
                        <option></option>
                        {models.map(md => (
                            <option key={md.id} value={md.id}>
                                {md.name}
                            </option>
                        ))}
                    </Form.Control>
                </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
                <Col>
                    <Form.Label className="white_title">Service description</Form.Label>
                    <Form.Control as="textarea" row={3} />
                </Col>
            </Row>
            <div style={{ marginTop: 20 }}>
                <h1>Request new service</h1>
            </div>
            <Row>
                <Col>
                    <Form.Label className="white_title">Hourly rate $</Form.Label>
                    <Form.Control type="number" min="1" />
                </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
                <Col>
                    <Form.Label className="white_title">Start date</Form.Label>
                    <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                </Col>
                <Col>
                    <Form.Label className="white_title">End date</Form.Label>
                    <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
                </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "center", marginTop: 60 }}>
                <Button variant="light" onClick={() => sendRequest()}>
                    Request new service
                </Button>
            </div>
        </Form>
    );
};

export default Service;
