import { combineReducers } from "redux";
import businessRotationReducer from "./businessRotation";
import linesReducer from "./lines";
import serviceReducer from "./service";

export default combineReducers({
    BR: businessRotationReducer,
    Lines: linesReducer,
    Service: serviceReducer
});
