import React from "react";
import { connect } from "react-redux";
import { Card, CardDeck } from "react-bootstrap";
import { Link } from 'react-router-dom';
class Factory extends React.Component {


    render() {
      const { messages } = this.props;

        return (
            <div className="flex-center full-height position-ref">

                            <CardDeck>
                              <Card>
                                <Card.Body>
                                  <Card.Title> {messages['submit_passport']}</Card.Title>
                                  <Card.Text> {messages['passport_message']}</Card.Text>
                                </Card.Body>
                                <Card.Footer><Link to="/c/passport">{messages['go']}</Link></Card.Footer>
                              </Card>

                              <Card>
                                <Card.Body>
                                  <Card.Title>{messages['security_requirements']}</Card.Title>
                                  <Card.Text> {messages['factory_safety_requirements']}</Card.Text>
                                </Card.Body>
                                <Card.Footer><Link to="/c/security_requirements">{messages['go']}</Link></Card.Footer>
                              </Card>

                              <Card>
                                <Card.Body>
                                  <Card.Title>{messages['business_rotation']}</Card.Title>
                                  <Card.Text> {messages['information_about_your_factory']}</Card.Text>
                                </Card.Body>
                                <Card.Footer><Link to="/c/business_rotation">{messages['go']}</Link></Card.Footer>
                              </Card>
                            </CardDeck>
                        </div>
        );
    }
}

const mapStateToProps = state => ({
  messages: state.lang.messages,
});

export default connect(mapStateToProps, {

})(Factory);
