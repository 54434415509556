import axios from "axios";


export const MANAGEMENT = {
    SET_VIEW: "SET_VIEW",
    DELETE_ITEM: "DELETE_ITEM",
    EDIT_ITEM: "EDIT_ITEM",
    ADD_ITEM: "ADD_ITEM",
    GET_BRAND: "GET_BRAND",
    GET_EQUIPMENT: "GET_EQUIPMENT",
    GET_SPECIALTY: "GET_SPECIALTY",
    GET_FATHER_LEVELS: "GET_FATHER_LEVELS",
    GET_SERVICES: "GET_SERVICES",
    GET_CUSTOM: "GET_CUSTOM",
    SET_SERVICE: "SET_SERVICE",
    EDIT_SERVICE_STATUS: "EDIT_SERVICE_STATUS",
    SET_STATUS: "SET_STATUS",
    SET_TYPE: "SET_TYPE",
    ERROR: "ERROR",
    DELETE_USER_ADMIN: "DELETE_USER_ADMIN"
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: MANAGEMENT.ERROR,
    error
});

export const setView = (view, page) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.management(view, page));
        dispatch({
            type: MANAGEMENT.SET_VIEW,
            data: response.data,
            view: view,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getTechnologies = (equipmentId, page) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_technologies(equipmentId, page));
        dispatch({
            type: MANAGEMENT.SET_VIEW,
            data: response.data,
            view: 'technology',
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const setType = (view) => async dispatch =>{
    dispatch({
        type: MANAGEMENT.SET_TYPE,
        view: view
    });

};

export const deleteItem = (view, id) => async dispatch => {
    try {
        const response = await axios.delete(API_CONSTANT_MAP.deleteItem(view, id));
        dispatch({
            type: MANAGEMENT.DELETE_ITEM,
            data: response.data,
            view: view,
            id: id
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};


export const editItem = (view, id, Data) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.deleteItem(view, id), Data);
        dispatch({
            type: MANAGEMENT.EDIT_ITEM,
            item: response.data,
            view: view,
            id: id
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};


export const addItem = (view, Data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.addItem(view), Data);
        dispatch({
            type: MANAGEMENT.ADD_ITEM,
            item: response.data
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};


export const getEquipment = () => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.getAllItems("equipment"));
        dispatch({
            type: MANAGEMENT.GET_EQUIPMENT,
            equipment: response.data
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};

export const getBrand = () => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.getAllItems("brand"));
        dispatch({
            type: MANAGEMENT.GET_BRAND,
            brand: response.data
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};


export const getSpecialty = () => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.getAllItems("specialty"));
        dispatch({
            type: MANAGEMENT.GET_SPECIALTY,
            specialty: response.data
        });

    } catch (err) {
        console.log(err);
        dispatch(error(err.message));
    }
};

export const getCustom = () => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_custom);
        dispatch({
            type: MANAGEMENT.GET_CUSTOM,
            custom: response.data,
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getServices = (page, status) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_services(page, status));
        dispatch({
            type: MANAGEMENT.GET_SERVICES,
            services: response.data,
            page: page,
            status: status
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const setService = (service) => async dispatch => {
    try {
        dispatch({
            type: MANAGEMENT.SET_SERVICE,
            service: service
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const editServiceStatus = (serviceId, data) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.edit_service_status(serviceId), data);
        dispatch({
            type: MANAGEMENT.EDIT_SERVICE_STATUS,
            service: response.data
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const setStatus = (status) => async dispatch => {
    try {
        dispatch({
            type: MANAGEMENT.SET_STATUS,
            status: status
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const deleteUser = (type, id) => async dispatch => {
    try {
        await axios.delete(API_CONSTANT_MAP.delete_user(type, id));
        dispatch({
            type: MANAGEMENT.DELETE_USER_ADMIN,
            id: id
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};











