import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Spinner, Card, CardColumns } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AddExperience from "./AddExperience";
import { API_CONSTANT_MAP } from "@utils/routes";

const Experiences = ({
    loading,
    experiences,
    technician,
    onAddedExperience,
    onDeletedExperience
}) => {
    const messages = useSelector(state => state.lang.messages);

    const [isAddingExperience, setIsAddingExperience] = useState(false);
    const [addExperienceModal, setAddExperienceModal] = useState(false);
    const [isDeletingExperience, setIsDeletingExperience] = useState(false);
    const [experience, setExperience] = useState(null);

    const handleAddExperience = async (payload = null) => {
        if (payload) {
            setIsAddingExperience(true);
            const data = {};

            if (payload.equipment) data.equipment_id = payload.equipment;
            else data.equipment_name = payload.equipmentStr;

            if (payload.technology) data.technology_id = payload.technology;
            else data.technology_name = payload.technologyStr;

            if (payload.brand) data.brand_id = payload.brand;
            else data.brand_name = payload.brandStr;

            if (payload.model) data.model_id = payload.model;
            else data.model_name = payload.modelStr;

            const response = await axios.post(API_CONSTANT_MAP.experiences.post(technician), data);
            onAddedExperience(response.data);
            setIsAddingExperience(false);
        }

        setAddExperienceModal(false);
    };

    const handleDeleteExperience = async id => {
        setIsDeletingExperience(true);
        await axios.delete(API_CONSTANT_MAP.experiences.delete(id));
        onDeletedExperience(id);
        setIsDeletingExperience(false);
    };

    return (
        <>
            <div className="flex flex-row justify-between align-items-center">
                <h1 className="reset-header text-white pt-4 mb-4">{messages["experience"]}</h1>

                {isAddingExperience ? (
                    <Spinner variant="light" animation="border" />
                ) : (
                    <Button
                        variant="outline-light"
                        size="sm"
                        onClick={() => setAddExperienceModal(true)}
                    >
                        {messages["addNewExperience"]}
                    </Button>
                )}
            </div>

            {!loading && <Spinner animation="border" variant="light" />}
            <CardColumns>
                {loading &&
                    experiences.map(e => {
                        const { equipment, brand, technology, model } = e;
                        return (
                            <Card key={e.id} border="light">
                                <Card.Body>
                                    <Card.Title className="flex justify-between">
                                        <span>{equipment.name}</span>

                                        <Button
                                            size="sm"
                                            variant="danger"
                                            disabled={isDeletingExperience}
                                            onClick={() => {
                                                handleDeleteExperience(e.id);
                                                setExperience(e.id);
                                            }}
                                        >
                                            {isDeletingExperience &&
                                            experience.toString() === e.id.toString() ? (
                                                <Spinner
                                                    size="sm"
                                                    variant="light"
                                                    animation="border"
                                                />
                                            ) : (
                                                <FontAwesomeIcon icon={faTrash} />
                                            )}
                                        </Button>
                                    </Card.Title>
                                    <Card.Text className="mb-0">
                                        <span className="mb-0">
                                            {messages["brand"]}: {brand.name}
                                        </span>
                                    </Card.Text>
                                    <Card.Text className="mb-0">
                                        <span className="mb-0">
                                            {messages["technology"]}: {technology.name}
                                        </span>
                                    </Card.Text>
                                    <Card.Text className="mb-0">
                                        <span className="mb-0">
                                            {messages["model"]}: {model.name}
                                        </span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        );
                    })}
            </CardColumns>

            {addExperienceModal && (
                <AddExperience technician={technician} onClose={handleAddExperience} />
            )}
        </>
    );
};

export default Experiences;
