import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import Dropzone from 'react-dropzone'
import {updateUser} from "../../../actions/auth";
import {create_form_data} from "../../../utils/routes";
import Passport from "@components/shared/Passport";
import { addPopper } from "@actions/popper";

const imageMaxSize = 10000000;

class PassportClient extends React.Component {
    state = {
        file: null,
        senderFile: null,
        error: null,
        is_loading: false
    };
    

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onMakeRequest = async () => {
        let formData = new FormData();
        formData.append('passport', this.state.senderFile);
        await this.props.updateUser(formData, "client" );
    };

    onDrop = (files, rejectedFiles) =>{
        this.setState({senderFile: files});
        const myFileReader = new FileReader()
        myFileReader.addEventListener("load", ()=>{
            this.setState({file: myFileReader.result});
        }, false)
        myFileReader.readAsDataURL(files[0]);
    };


    render() {
        const { messages } = this.props;

        return (
            <Container>
            <Row>
                <Col>
                    <Passport />
                </Col>
            </Row>
        </Container>
            
        );
    }
}

const mapStateToProps = state => ({
    parsedToken: state.auth.parsedToken,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    updateUser
})(PassportClient);

                        