import { LANG } from "@actions/lang";

/**
 * Function to set the proper translations for the web base on the lang
 * and the user role if provided.
 *
 * @param {'en'|'es'} lang Abbreviation Language
 * @param {string|null} type Rol Type from the user
 */
const setLanguage = (lang, type = null) => {
    let messages = Object.assign({}, require(`../i18n/${lang}.json`));

    if (type) {
        messages = Object.assign(
            messages,
            require(`../i18n/${type}/${lang}.json`)
        );
    }

    return messages;
};

const defaultLanguage = "en";

const initialState = {
    locale: defaultLanguage,
    messages: setLanguage(defaultLanguage),
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LANG.UPDATE_LANGUAGE:
        case LANG.LOAD_LANGUAGE:
            return {
                ...state,
                locale: action.language,
                messages: setLanguage(action.language, action.rol || null)
            };
        case LANG.ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
