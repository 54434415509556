const flexRow = {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    paddingBottom: "10px"
};

const status = {
    color: "white",
    fontSize: "20px"
};

const button = {
    marginLeft: "5px"
};

export default { flexRow, status, button };
