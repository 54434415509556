import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Table from "./Table";

const CalendarStyled = styled.main`
    font-size: 8px;
    display: flex;
    flex-direction: row;

    .wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        background: white;
        padding: 1em;
        border-radius: 0.25em;
    }

    @media (max-width: 1280px) {
        flex-direction: column;
    }
`;

export const FY_FORMAT = "YYYY[-]MM[-]DD";
export const MY_FORMAT = "MMMM, YYYY";

class Calendar extends React.Component {
    static propTypes = {
        locale: PropTypes.string,
        dates: PropTypes.object.isRequired,
        showSidebar: PropTypes.bool,
        service: PropTypes.object,
        onDayClick: PropTypes.func,
        onDayPreviewClick: PropTypes.func,
        onCloseSidebar: PropTypes.func,
        year: PropTypes.string,
        month: PropTypes.string
    };

    state = {
        month: moment().month(),
        year: moment().year(),
        selected: null
    };

    onPrevMonth = () => {
        this.onChangeMonth(false);
    };

    onNextMonth = () => {
        this.onChangeMonth(true);
    };

    onChangeMonth = forward => {
        const { month, year } = this.state;
        const initial = moment()
            .month(month)
            .year(year);

        const updated = forward ? initial.add(1, "month") : initial.subtract(1, "month");

        this.setState({
            month: updated.month(),
            year: updated.year()
        });
    };

    render() {
        const {
            showSidebar = false,
            service,
            locale = "en",
            onDayClick,
            onCloseSidebar,
            onDayPreviewClick,
            dates,
        } = this.props;
        const month = this.props.month || this.state.month;
        const year = this.props.year || this.state.year;
        const title = moment()
            .year(year)
            .month(month)
            .format(MY_FORMAT);

        return (
            <CalendarStyled>
                <div className="wrapper">
                    <Header
                        onPrevMonth={this.onPrevMonth}
                        onNextMonth={this.onNextMonth}
                        title={title}
                    />
                    <Table
                        month={month}
                        year={year}
                        onDayClick={onDayClick || (() => {})}
                        onDayPreviewClick={onDayPreviewClick || (() => {})}
                        locale={locale}
                        dates={dates}
                    />
                </div>
                {showSidebar && <Sidebar onClose={onCloseSidebar} service={service} />}
            </CalendarStyled>
        );
    }
}

export default Calendar;
