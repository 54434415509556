import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";

export const DOCUMENT = {
    FETCH_DOCUMENTS: "FETCH_DOCUMENTS",
    ERROR: "ERROR_DOCUMENTS"
};

const error = error => ({
    type: DOCUMENT.ERROR,
    error
});

export const fetchDocuments = () => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_documents);

        const documents = {};
        response.data.map(doc => {
            documents[doc.type] = {
                ...doc
            };
        });

        dispatch({
            type: DOCUMENT.FETCH_DOCUMENTS,
            documents
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const saveDocuments = (data, documents) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.post_documents, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        response.data.map(doc => {
            if (documents[doc.type]) {
                documents[doc.type] = {
                    ...documents[doc.type],
                    ...doc,
                    files: [...documents[doc.type].files, ...doc.files]
                };
            } else {
                documents[doc.type] = doc;
            }
        });

        dispatch({
            type: DOCUMENT.FETCH_DOCUMENTS,
            documents: { ...documents }
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};
