import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { updateUser } from "../../../actions/auth";
import { create_form_data } from "../../../utils/routes";
import ChangeLanguage from "@components/shared/ChangeLanguage";
import ChangePassword from "@components/shared/ChangePassword";
import LikeLanguages from "@components/shared/LikeLanguages";

class Options extends React.Component {
    state = {
        securityRequirements: this.props.securityRequirements.epp,

        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onMakeRequest = async () => {
        if (this.props.securityRequirements.epp !== this.state.securityRequirements) {
            const data = {
                epp: this.state.securityRequirements
            };
            this.setState({
                is_loading: true,
                error: null
            });
            await this.props.updateUser(create_form_data(data), "client");
        }
    };

    render() {
        const { messages } = this.props;
        return (
            <div className="flex-center full-height position-ref">
                <Form>
                    <Row>
                        <Col>
                            <ChangePassword />
                        </Col>
                        <Col>
                            <ChangeLanguage />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <LikeLanguages />
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    securityRequirements: state.auth.user,
    messages: state.lang.messages
});

export default connect(mapStateToProps, {
    updateUser
})(Options);
