import axios from "axios";


export const SERVICES = {
    ADMIN_GET_TYPE_SERVICE: "ADMIN_GET_TYPE_SERVICE",
    ADMIN_ADD_TYPE_SERVICE: "ADMIN_ADD_TYPE_SERVICE",
    ADMIN_EDIT_TYPE_SERVICE: "ADMIN_EDIT_TYPE_SERVICE",
    ADMIN_DELETE_TYPE_SERVICE: "ADMIN_DELETE_TYPE_SERVICE",

};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: SERVICES.ERROR,
    error
});

export const getTypeOfServices = (page) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.admin_type_of_service(page));
        dispatch({
            type: SERVICES.ADMIN_GET_TYPE_SERVICE,
            services: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const addTypeOfServices = (data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.admin_type_of_service_add, data);
        dispatch({
            type: SERVICES.ADMIN_ADD_TYPE_SERVICE,
            services: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const editTypeOfServices = (data, id) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.admin_type_of_service_delete_put(id), data);
        dispatch({
            type: SERVICES.ADMIN_EDIT_TYPE_SERVICE,
            services: response.data,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const deleteTypeOfServices = (id) => async dispatch => {
    try {
        await axios.delete(API_CONSTANT_MAP.admin_type_of_service_delete_put(id));

        dispatch({
            type: SERVICES.ADMIN_DELETE_TYPE_SERVICE,
            id: id,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};





