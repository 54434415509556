import { useState, useEffect } from "react";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";
import { useSelector } from "react-redux";

export default function useReviewDocuments(type, id) {
    const publicToken = useSelector(state => state.auth.publicToken);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchDocument();
    }, []);

    const [documents, setDocuments] = useState([]);

    async function fetchDocument() {
        try {
            const response = await axios.get(API_CONSTANT_MAP.documents.base(type, id));
            setDocuments(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function reviewDocument(documentType, comment, status) {
        try {
            const data = {
                [documentType]: {
                    comment,
                    status
                }
            };
            const response = await axios.put(API_CONSTANT_MAP.documents.base(type, id), data);
            setDocuments(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return {
        documents,
        reviewDocument,
        publicToken
    };
}
