import { DOCUMENTS } from "@actions/Administrator/documents";

const initialState = {
    documents: {
        client: { data: [], meta: {}, loaded: false},
        technician: { data: [], meta: {}, loaded: false},
    },
    page: 1,
    profile: "client",
    id_profile: null,
    status: 1,
    data: [],
    services: {
        data: [],
        meta: {}
    },
    user_documents: {
        loaded: false,
        documents: [],
    },
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {

        case DOCUMENTS.GET_DOCUMENTS:
            {
                return {
                    ...state,
                    documents: {
                        ...state.documents,
                        [action.profile]: {
                            data: action.documents.data,
                            meta: action.documents.meta,
                            loaded: true
                        }
                    },
                    profile: action.profile,
                    status: action.status
                };
            }

        case DOCUMENTS.GET_USER_DOCUMENTS:
            {
                return {
                    ...state,
                    user_documents: {
                        ...state.user_documents,
                        loaded: true,
                        documents: action.documents,
                    }
                };
            }     
    
        case DOCUMENTS.EDIT_DOCUMENT_STATUS:
            {
                return{
                    ...state,
                    user_documents: {
                        ...state.user_documents,
                        documents: {
                            ...state.user_documents.documents,
                            [action.document[0].type]: action.document[0]
                        }
                    }
                }
            } 

        case DOCUMENTS.SET_ID_PROFILE:
            {
                return{
                    ...state,
                    id_profile: action.idProfile
                }
            }

        case DOCUMENTS.GET_PROFILE:
            {
                return{
                    ...state,
                    data: action.data
                }
            }

            case DOCUMENTS.GET_USER_SERVICES:
                {
                    return{
                        ...state,
                        services: {
                            ...state.services,
                            data: action.services.data,
                            meta: action.services.meta
                        }
                    }
                }
                
        default:
            return state;
    }
}
