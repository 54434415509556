import { PROFILES } from "@actions/Administrator/profiles";

const initialState = {
    profiles: {
        client: { loaded: false, data:[], meta: {} },
        technician: { loaded: false, data:[], meta: [] }
    },
};

export default function(state = initialState, action) {
    switch (action.type) {

        case PROFILES.GET_PROFILES:
            {
                return {
                    ...state,
                    profiles: {
                        ...state.profiles,
                        [action.profile]: { loaded: true, data: action.profiles.data, meta: action.profiles.meta }
                    }
                    
                };
            }

        

        default:
            return state;
    }
}
