import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import {
    previousMonthOffset,
    currentMonth,
    nextMonthOffset,
    isMarked,
    retrieveColor
} from "./operations";
import Day from "./Day";

const TableStyled = styled.table`
    width: 100%;
    margin-top: 0;
    margin-top: 1em;
    border-collapse: collapse;
    border: 1px solid #dee2e6;
    transition: all 500ms ease-in;

    td,
    th {
        border: 1px solid #dee2e6;
        width: calc(100% / 7);
    }

    thead th {
        color: black;
        font-size: 1.5em;
        text-align: center;
        font-weight: 600;
        padding: 0.5rem 0;
    }
`;

const Table = ({ onDayClick, onDayPreviewClick, dates, year, month, locale }) => {
    const colors = useSelector(state => state.tech.req_ser.colors);
    const days = moment.weekdaysShort();
    let calendar = [...previousMonthOffset(year, month), ...currentMonth(year, month)];
    calendar = [...calendar, ...nextMonthOffset(year, month, calendar)];

    return (
        <TableStyled>
            <thead>
                <tr>
                    {days.map((day, index) => (
                        <th key={index}>{day}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {Array(7)
                    .fill(null)
                    .map((_, i) => {
                        const start = i * 7;
                        const end = (i + 1) * 7;
                        return (
                            <tr key={i}>
                                {calendar.slice(start, end).map(date => {
                                    const marked = isMarked(dates, date);
                                    let color = null;
                                    if (marked) {
                                        color = retrieveColor(colors, dates, date);
                                    }

                                    return (
                                        <Day
                                            offset={date.offset}
                                            marked={marked}
                                            color={color}
                                            key={date.dateString}
                                            date={date}
                                            onDayClick={marked ? onDayPreviewClick : onDayClick}
                                        />
                                    );
                                })}
                            </tr>
                        );
                    })}
            </tbody>
        </TableStyled>
    );
};

export default Table;
