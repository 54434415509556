import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./store";
import Template from "./template";
import PopperWrapper from "./components/shared/PopperWrapper";

export default class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Template />
                <PopperWrapper />
            </Provider>
        );
    }
}
