import React from "react";
import { connect } from "react-redux";
import { Form, Button, Col, Row, Container, Spinner } from "react-bootstrap";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";
import { createTechnician } from "@actions/Administrator/invitationManagement"
import { addPopper } from "@actions/popper";
import { getTechnicianCoefficients } from "@actions/Administrator/payments";


class GeneralInformation extends React.Component {
    state = {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        telephone: "",
        errors: {},
        validated: false,
        redirect: false,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onMakeRequest = async () => {
        this.setState({
            is_loading: true,
            errors: {}
        });

        const data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            username: this.state.username,
            email: this.state.email,
            phoneNumber: this.state.telephone

        };

        await this.props.createTechnician(data);

        this.setState({
            is_loading: false,
        });

        this.props.addPopper({
            title: "success",
            message: "addedTechnician",
            time: 6000,
            level: "success"
        });

        this.props.route.history.push("/a/invite_technician/skills_information/" + this.props.technician.id);
    };

    displayErrors = field => {
        if (field) {
            return (
                <label className="mt-2">
                    {field.map((msg, index) => {
                        return (
                            <span className="text-white" key={index}>
                                {msg}
                                <br />
                            </span>
                        );
                    })}
                </label>
            );
        }

        return null;
    };

    render() {
        const { is_loading, errors } = this.state;
        const { firstName, lastName, username, email, telephone } = errors;


        return (
            <div className="flex-center full-height position-ref">
                <Form>
                    <h1 className="mb-4 text-white text-center">Sign Up</h1>
                    <Container className="form-fixed-width">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Username
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        name="username"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(username)}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        name="email"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(email)}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Name
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        name="firstName"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(firstName)}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Last Name
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        name="lastName"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(lastName)}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        Telephone
                                    </Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        name="telephone"
                                        onChange={this.onChange}
                                    />
                                    {this.displayErrors(telephone)}
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                        <Col className="text-right">
                                {is_loading && (
                                    <Spinner
                                        variant="light"
                                        animation="border"
                                    />
                                )}
                                {!is_loading && (
                                    <Button
                                        variant="outline-light"
                                        size="sm"
                                        type="submit"
                                        onClick={this.onMakeRequest}
                                    >
                                        Sign Up
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    messages: state.lang.messages,
    technician: state.admin.invitation.technician,
});

export default connect(mapStateToProps, {
    createTechnician,
    addPopper
})(GeneralInformation);
