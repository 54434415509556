import Administrator from "@views/private/administrator/Dashboard";
import InviteClient from "@views/private/administrator/InviteClient";
import InviteTechnician from "@views/private/administrator/Technician_invitation/InviteTechnician";
import ExperienceManagement from "@views/private/administrator/Experience_management/Management";
import ReviewDocuments from "@views/private/administrator/ReviewDocuments";
import Options from "@views/private/administrator/Options";
import SkillsManagement from "@views/private/administrator/skills/SkillsManagement";
import Profile from "@views/private/administrator/Profile";
import Recomendations from "@views/private/administrator/UserRecomendations";
import Services from "@views/private/administrator/Services";
import Service from "@views/private/administrator/Service";
import Client from "@views/private/administrator/Client";
import Technician from "@views/private/administrator/Technician";
import Language from "@views/private/administrator/Language";
import UserInformation from "@views/private/administrator/UserInformation";
import PaymentAdministration from "@views/private/administrator/PaymentAdministration";
import UserDocuments from "@views/private/administrator/UserDocuments";
import RootManagement from "@views/private/administrator/skills/RootsManagement";
import ProtocolsManagement from "@views/private/administrator/skills/ProtocolsManagement";
import LabelsManagement from "@views/private/administrator/skills/LabelsManagement";
import GeneralInformation from "@views/private/administrator/Technician_invitation/GeneralInformation";
import SkillsInformation from "@views/private/administrator/Technician_invitation/SkillsInformation";
import SkillDetail from "@views/private/administrator/Technician_invitation/SkillDetail";
import Technology from "@views/private/administrator/Experience_management/Technologies";
import EquipmentManagement from "@views/private/administrator/Experience_management/Equipment";
import ClientSimulator from "@views/private/administrator/Service_Simulator/client/service";
import ClientSimulatorForm from "@views/private/administrator/Service_Simulator/client/serviceFormNew";
import TypeOfServices from "@views/private/administrator/TypeOfServices";
import TechnicianList from "@views/private/administrator/Service_Simulator/client/technicianList";
import TechnicianProfile from "@views/private/administrator/Service_Simulator/client/technicianProfile";
import ClientDocuments from "@views/private/administrator/ClientDocuments";

export const Routes = {
    Administrator: {
        component: Administrator,
        path: "/a"
    },
    InviteClient: {
        component: InviteClient,
        path: "/a/invite_client"
    },
    InviteTechnician: {
        component: InviteTechnician,
        path: "/a/invite_technician"
    },
    ExperienceManagement: {
        component: ExperienceManagement,
        path: "/a/equipment/:handle"
    },
    SkillsManagement: {
        component: SkillsManagement,
        path: "/a/skills_management"
    },
    ReviewDocuments: {
        component: ReviewDocuments,
        path: "/a/documents/client"
    },
    Options: {
        component: Options,
        path: "/a/options"
    },
    Profile: {
        component: Profile,
        path: "/a/profile"
    },
    Recomendations: {
        component: Recomendations,
        path: "/a/recomendations"
    },
    Services: {
        component: Services,
        path: "/a/services"
    },
    ServiceDetail: {
        component: Service,
        path: "/a/service_detail/:id"
    },
    Client: {
        component: Client,
        path: "/a/client"
    },
    Technician: {
        component: Technician,
        path: "/a/technician"
    },
    Language: {
        component: Language,
        path: "/a/language"
    },
    UserInformation: {
        component: UserInformation,
        path: "/a/user_information/:profile/:id"
    },
    PaymentAdministration: {
        component: PaymentAdministration,
        path: "/a/payment_administration"
    },
    UserDocuments: {
        component: UserDocuments,
        path: "/a/user_documents/:profile/:id"
    },
    CientDocuments: {
        component: ClientDocuments,
        path: "/a/client_documents/client/:id"
    },
    RootManagement: {
        component: RootManagement,
        path: "/a/roots/:id"
    },
    ProtocolsManagement: {
        component: ProtocolsManagement,
        path: "/a/protocols/:type/:id"
    },
    LabelsManagement: {
        component: LabelsManagement,
        path: "/a/labels/:id"
    },
    GeneralInformation: {
        component: GeneralInformation,
        path: "/a/invite_technician/general_information"
    },
    SkillsInformation: {
        component: SkillsInformation,
        path: "/a/invite_technician/skills_information/:id"
    },
    SkillDetail: {
        component: SkillDetail,
        path: "/a/invite_technician/skill_detail/:tech_id/:id"
    },
    Technology: {
        component: Technology,
        path: "/a/technology/:id"
    },
    EquipmentManagement: {
        component: EquipmentManagement,
        path: "/a/equipment_management/:id"
    },
    ClientSimulator: {
        component: ClientSimulator,
        path: "/a/client_simulator/main"
    },
    ClientSimulatorForm: {
        component: ClientSimulatorForm,
        path: "/a/client_simulator/form"
    },
    TypeOfServices: {
        component: TypeOfServices,
        path: "/a/type_of_services"
    },
    TechnicianList: {
        component: TechnicianList,
        path: "/a/client_simulator/technician_list"
    },
    TechnicianProfile: {
        component: TechnicianProfile,
        path: "/a/client_simulator/technician_details"
    }
};

export const Links = [
    {
        key: "dashboard",
        to: "/a"
    },
    {
        key: "payment_administration",
        to: "/a/payment_administration"
    },
    {
        key: "invite_technician",
        to: "/a/invite_technician"
    },
    {
        key: "invite_client",
        to: "/a/invite_client"
    },
    {
        key: "experience_management",
        to: "/a/equipment/1"
    },
    {
        key: "skills_management",
        to: "/a/skills_management"
    },
    {
        key: "check_documents",
        to: "/a/documents/client"
    },
    {
        key: "user_recomendations",
        to: "/a/recomendations"
    },
    {
        key: "services",
        to: "/a/services"
    },
    {
        key: "client",
        to: "/a/client"
    },
    {
        key: "technician",
        to: "/a/technician"
    },
    {
        key: "client_simulator",
        to: "/a/client_simulator/form"
    },
    {
        key: "type_of_service",
        to: "/a/type_of_services"
    },
    {
        key: "language",
        to: "/a/language"
    },
    {
        key: "options",
        to: "/a/options"
    }
];
