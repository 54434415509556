import React, { useState } from "react";
import useReviewDocuments from "@hooks/useReviewDocuments";
import File from "@components/admin/documents/file";
import { Container, Image, Form, Button, Table, Card, Modal } from "react-bootstrap";

export default function ClientDocuments(props) {
    const { documents, reviewDocument, publicToken } = useReviewDocuments(
        "client",
        props.route.match.params.id
    );
    const { messages } = props;
    const [documentModal, setDocumentModal] = useState(false);
    const [comment, setComment] = useState("");
    const [status, setStatus] = useState("");
    const [documentType, setDocumentType] = useState("");
    function retrieveDocuments(key) {
        const files = [...(documents[key]?.files || [])];
        return <File files={files} documents={documents} keyInfo={key} publicToken={publicToken} />;
    }

    async function review() {
        await reviewDocument(documentType, comment, status);
        setDocumentModal(false);
    }
    return (
        <Container>
            <h1 className="reset-header text-white pt-4 mb-2">{messages["documents"]}</h1>
            <p className="text-white mb-4">
                In order to apply for a service, you first need to upload the different documents,
                some of them are required(*) and some others optional.
            </p>
            {Object.keys(documents).map(file => {
                return (
                    <div
                        key={documents[file].type}
                        style={{
                            backgroundColor: "#212121",
                            margin: 5,
                            padding: 10,
                            borderRadius: 5,
                            marginTop: 10
                        }}
                    >
                        <h2 style={{ color: "#F0F0F0" }}>
                            {messages[documents[file].type]} {documents[file].required && "*"}{" "}
                            {documents[file].status}
                        </h2>
                        {retrieveDocuments(documents[file].type)}
                        <Button
                            style={{ marginTop: 5 }}
                            size="sm"
                            variant="outline-light"
                            onClick={() => {
                                setDocumentModal(true);
                                setDocumentType(documents[file].type);
                            }}
                        >
                            {messages["review"]}
                        </Button>
                    </div>
                );
            })}

            <Modal show={documentModal} onHide={() => setDocumentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Change {messages[documentType]} status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="3"
                            name="comment"
                            onChange={e => setComment(e.target.value)}
                        />
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            name="status"
                            onChange={e => setStatus(e.target.value)}
                        >
                            <option value="ON_REVIEW"> On review </option>
                            <option value="VALIDATED"> Validated </option>
                            <option value="EXPIRED"> Expired </option>
                        </Form.Control>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => review()}>
                        Ok
                    </Button>
                    <Button variant="secondary" onClick={() => setDocumentModal(false)}>
                        {messages["close"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}
