import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Col, Row, Container, Card, Spinner } from "react-bootstrap";
import { fetchServices } from "@actions/technician";

class RequestServices extends React.Component {
    state = {
        status: 2,
        options: [
            {
                key: "assigned",
                value: 2
            },
            {
                key: "accepted",
                value: 3
            },
            {
                key: "completed",
                value: 4
            }
        ],
        is_loading: false
    };

    static getDerivedStateFromProps(props, state) {
        const url = new URLSearchParams(props.route.location.search);
        const status = url.get("status") || 2;

        return {
            status
        };
    }

    async componentDidMount() {
        this.setState({ is_loading: true });
        const { status } = this.state;
        await this.props.fetchServices(status, this.props.services[status].loaded);
        this.setState({ is_loading: false });
    }

    onChange = async e => {
        this.setState({ is_loading: true });
        const { value } = e.target;
        this.props.route.history.push(`/t?status=${value}`);

        await this.props.fetchServices(value, this.props.services[value].loaded);
        this.setState({ is_loading: false });
    };

    render() {
        const { messages, services } = this.props;
        const { status, options, is_loading } = this.state;
        const s = services[status].data || [];

        return (
            <>
                <h1 className="reset-header text-white pt-4 mb-4">{messages["req_ser"]}</h1>
                <Form.Row>
                    <Form.Group as={Col} md="4" className="pl-0 pr-3">
                        <Form.Control
                            as="select"
                            value={status}
                            custom
                            name="status"
                            onChange={this.onChange}
                        >
                            {options.map(o => {
                                return (
                                    <option key={o.value} value={o.value}>
                                        {messages[`req_ser.${o.key}`]}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                {is_loading && <Spinner animation="border" variant="light" />}
                <Container>
                    <Row>
                        {!is_loading &&
                            s.map(req => {
                                return (
                                    <Col className="pl-0 mt-3" lg={4} sm={6} xs={12} key={req.id}>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>
                                                    #{req.id}
                                                </Card.Title>
                                                <Card.Text className="mb-0">
                                                    {typeof req.service === "object"
                                                        ? req.service.name
                                                        : req.service}
                                                </Card.Text>
                                                <Card.Text className="mb-0">
                                                    <span className="mb-0">
                                                        {messages["specialty"]}:{" "}
                                                        {req.specialty.name}
                                                    </span>
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Body className="text-right pt-2">
                                                <Link to={`/t/service/${status}/${req.id}`}>
                                                    {messages["seeDetails"]}
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
                <br/>
            </>
        );
    }
}

const mapStateToProps = state => ({
    services: state.tech.req_ser.services,
    messages: state.lang.messages
});

export default connect(mapStateToProps, {
    fetchServices
})(RequestServices);
