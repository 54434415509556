import React, { useState } from "react";
import useRequestService from "@hooks/useRequestService";
import ServiceClientCard from "@components/admin/card/ServiceClientCard";
import ServiceDetailCard from "@components/admin/card/ServiceDetailCard";
import ServiceMediaCard from "@components/admin/card/ServiceMediaCard";
import ServiceTechnicianCard from "@components/admin/card/ServiceTechnicianCard";
import ServiceUpdateStatusModal from "@components/admin/modals/ServiceUpdateStatusModal";
import ServiceLocationCard from "@components/admin/card/ServiceLocationCard";
import { Spinner } from "react-bootstrap";
import styles from "./styles";

const ServiceDetail = props => {
    const { messages, route } = props;
    const { data, loading, updateStatus, publicToken } = useRequestService(route.match.params.id);
    const [show, setShow] = useState(false);

    console.log("data", data);

    return (
        <div>
            <h1 style={styles.title}>Service detail</h1>
            {loading === true ? (
                <Spinner animation="border" variant="light" />
            ) : (
                <>
                    <ServiceDetailCard
                        brand={data.brand.name}
                        dates={data.dates}
                        description={data.description}
                        hourlyRate={data.wanted_hourly_rate}
                        machine={data.machine.name}
                        model={data.model.name}
                        service={data.service.name}
                        specialty={data.specialty.name}
                        status={data.status.description}
                        year={data.equipment_year}
                        onChangeStatus={() => setShow(true)}
                    />
                    <ServiceLocationCard
                        lat={data.client.address.latitude}
                        lng={data.client.address.longitude}
                        city={data.client.address.city}
                        country={data.client.address.country}
                        state={data.client.address.state}
                        street={data.client.address.street}
                        ext_number={data.client.address.ext_number}
                    />
                    <ServiceClientCard
                        firstName={data.client.firstName}
                        lastName={data.client.lastName}
                        email={data.client.email}
                        loading={loading}
                    />
                    <ServiceTechnicianCard data={data} loading={loading} />
                    <ServiceMediaCard
                        images={data.images}
                        videos={data.videos}
                        publicToken={publicToken}
                    />
                    <ServiceUpdateStatusModal
                        onHide={() => setShow(false)}
                        show={show}
                        messages={messages}
                        initialStatus={data.status.value}
                        updateStatus={status => updateStatus(status)}
                    />
                </>
            )}
        </div>
    );
};

export default ServiceDetail;
