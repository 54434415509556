import React from "react";
import { connect } from "react-redux";
import { Form, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";
import { addPopper } from "@actions/popper";

class ChangePassword extends React.Component {
    state = {
        current: "",
        password: "",
        password_confirmation: "",
        is_saving: false,
        errors: {}
    };

    onSave = async () => {
        this.setState({ is_saving: true, errors: {} });
        const { current, password, password_confirmation } = this.state;
        try {
            const data = {
                current_password: current,
                password,
                password_confirmation
            };

            await axios.put(API_CONSTANT_MAP.change_password, data);
            this.setState({
                current: "",
                password: "",
                password_confirmation: ""
            });
            this.props.addPopper({
                title: "success",
                message: "updatedPassword",
                time: 6000,
                level: "success"
            });
            if (this.props.onSave) {
                this.props.onSave();
            }
        } catch (err) {
            this.setState({ errors: err.response.data });
        } finally {
            this.setState({ is_saving: false });
        }
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        const { messages } = this.props;
        const { current, password, password_confirmation, is_saving, errors } = this.state;
        const { current_password } = errors;
        const password_errors = errors.password;
        return (
            <>
                <h1 className="mt-4 mb-2">{messages["updatePassword"]}</h1>
                <Form>
                    <Form.Group controlId="current">
                        <Form.Label className="text-white">
                            {messages["currentPassword"]}
                        </Form.Label>
                        <Form.Control
                            size="md"
                            type="password"
                            name="current"
                            onChange={this.onChange}
                            value={current}
                            required
                        />
                        {current_password && (
                            <label className="mt-2">
                                {current_password.map((msg, index) => {
                                    return (
                                        <span className="text-white" key={index}>
                                            {msg}
                                            <br />
                                        </span>
                                    );
                                })}
                            </label>
                        )}
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label className="text-white">{messages["password"]}</Form.Label>
                        <Form.Control
                            size="md"
                            type="password"
                            name="password"
                            onChange={this.onChange}
                            value={password}
                            required
                            isValid={false}
                        />
                        {password_errors && (
                            <label className="mt-2">
                                {password_errors.map((msg, index) => {
                                    return (
                                        <span className="text-white" key={index}>
                                            {msg}
                                            <br />
                                        </span>
                                    );
                                })}
                            </label>
                        )}
                    </Form.Group>
                    <Form.Group controlId="password_confirmation">
                        <Form.Label className="text-white">
                            {messages["passwordConfirmation"]}
                        </Form.Label>
                        <Form.Control
                            size="md"
                            type="password"
                            name="password_confirmation"
                            onChange={this.onChange}
                            value={password_confirmation}
                            required
                            isValid={false}
                        />
                    </Form.Group>
                </Form>
                <div className="text-right">
                    {is_saving && <Spinner variant="light" animation="border" size="sm" />}
                    {!is_saving && (
                        <Button size="sm" variant="outline-light" onClick={this.onSave}>
                            {messages["saveChanges"]}
                        </Button>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    messages: state.lang.messages
});

export default connect(mapStateToProps, {
    addPopper
})(ChangePassword);
