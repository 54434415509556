import { combineReducers } from "redux";
import skillReducer from "./skill";
import requestServiceReducer from "./request_service";
import experienceReducer from "./experience";
import documentReducer from "./document";

export default combineReducers({
    skill: skillReducer,
    req_ser: requestServiceReducer,
    exp: experienceReducer,
    docs: documentReducer
});
