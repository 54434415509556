import ClientRegister from "@views/session/ClientRegister";
import TechnicianRegister from "@views/session/TechnicianRegister";
import Login from "@views/session/Login";
import ForgotPassword from "@views/session/ForgotPassword";
import ResetPassword from "@views/session/ResetPassword";

export default {
    TechnicianRegister: {
        component: TechnicianRegister,
        path: "/register/t/:token"
    },
    ClientRegister: {
        component: ClientRegister,
        path: "/register/c/:token"
    },
    Login: {
        component: Login,
        path: "/login"
    },
    ForgotPassword: {
        component: ForgotPassword,
        path: "/forgot"
    },
    ResetPassword: {
        component: ResetPassword,
        path: "/reset/password/:token"
    }
};
