import React from "react";
import { Link } from "react-router-dom";

export default class PublicLayout extends React.Component {
    render() {
        const Component = this.props.component;
        const { route, user } = this.props;
        return (
            <div className="min-h-full flex-col">
                <nav className="flex justify-end">
                    {user ? (
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "white",
                                fontVariant: "small-caps",
                                padding: "0.5rem 1rem"
                            }}
                            to="/c"
                        >
                            Profile
                        </Link>
                    ) : (
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "white",
                                fontVariant: "small-caps",
                                padding: "0.5rem 1rem"
                            }}
                            to="/login"
                        >
                            Login
                        </Link>
                    )}
                </nav>
                <div
                    className="flex flex-1 justify-center align-items-center"
                    style={{
                        height: "calc(100vh - 40px)"
                    }}
                >
                    <Component route={route} user={user} />
                </div>
            </div>
        );
    }
}
