import axios from "axios";


export const PROFILES = {
    GET_PROFILES: "GET_PROFILES",
    ERROR: "ERROR"
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: PROFILES.ERROR,
    error
});

export const getProfiles = (profile, page, document_status = undefined) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_profiles(profile, page), {
            params: {
                document_status
            }
        });
        dispatch({
            type: PROFILES.GET_PROFILES,
            profiles: response.data,
            profile: profile,
            page: page
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};







