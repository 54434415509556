import Dashboard from "@views/private/technician/Dashboard";
import Skills from "@views/private/technician/Skills";
import SkillDetail from "@views/private/technician/skills/SkillDetail";
import AddExperience from "@views/private/technician/experiences/AddExperience";
import ServiceDetail from "@views/private/technician/services/ServiceDetail";
import Map from "@views/private/technician/Map";
import Schedule from "@views/private/technician/Schedule";
import Settings from "@views/private/technician/Settings";
import Documents from "@views/private/technician/Documents";
import UpdatePassword from "@views/private/technician/UpdatePassword";

export const Routes = {
    Dashboard: {
        component: Dashboard,
        path: "/t"
    },
    ServiceDetail: {
        component: ServiceDetail,
        path: "/t/service/:status/:id"
    },
    Skills: {
        component: Skills,
        path: "/t/skill"
    },
    AddExperience: {
        component: AddExperience,
        path: "/t/experience/add"
    },
    SkillDetail: {
        component: SkillDetail,
        path: "/t/skill/:id"
    },
    Map: {
        component: Map,
        path: "/t/map"
    },
    Schedule: {
        component: Schedule,
        path: "/t/calendar"
    },
    Settings: {
        component: Settings,
        path: "/t/settings"
    },
    Documents: {
        component: Documents,
        path: "/t/docs"
    },
    UpdatePassword: {
        component: UpdatePassword,
        path: "/t/update_password"
    }
};

export const Links = [
    {
        key: "dashboard",
        to: "/t"
    },
    {
        key: "skills",
        to: "/t/skill"
    },
    {
        key: "map",
        to: "/t/map"
    },
    {
        key: "calendar",
        to: "/t/calendar"
    },
    {
        key: "options",
        to: "/t/settings"
    },
    {
        key: "documents",
        to: "/t/docs"
    }
];
