import { POPPER } from "@actions/popper";

const initialState = {
    poppers: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case POPPER.ADD_POPPER:
            return {
                ...state,
                poppers: [action.popper, ...state.poppers]
            };
        case POPPER.REMOVE_POPPER:
            return {
                ...state,
                poppers: state.poppers.filter(p => p.id.toString() !== action.id.toString())
            };
        default:
            return state;
    }
}
