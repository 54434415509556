import React from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Table, Container, Nav, Form, Row, Col } from "react-bootstrap";
import {updateUser} from "../../../actions/auth";
import { getDocuments, setIdProfile } from "@actions/Administrator/documents";
import { addPopper } from "@actions/popper";

class ReviewDocuments extends React.Component {

    state = {
        typeOfStatus:[
            "Empty",
            "On review",
            "Passport accepted"
        ],
        status: 0,
        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentDidMount = async () =>{
        console.log(this.props.profile);
        console.log(1);
        console.log(this.state.status);
        await this.props.getDocuments(this.props.profile, 1,  this.state.status);
        console.log(this.props.documents[this.props.profile].data);
    }

    onMakeRequest = async (Id) => {
        await this.props.setIdProfile(Id);
        this.props.route.history.push("/a/profile");
    };

    onSelect = async (e) =>{
        if(e !== this.props.profile){
            await this.props.getDocuments(e, 1, this.props.status);
        }
    }
    changePage = async (symbol) =>{
        if(symbol === "-" && this.props.documents[this.props.profile].meta.current_page > 1){
            await this.props.getDocuments(this.props.profile, (this.props.documents[this.props.profile].meta.current_page - 1), this.props.status);
        }
        if(symbol === "+" && this.props.documents[this.props.profile].meta.current_page < this.props.documents[this.props.profile].meta.last_page){
            await this.props.getDocuments(this.props.profile, (this.props.documents[this.props.profile].meta.current_page + 1), this.props.status); 
        }
        this.popper("edited");
    }
    changeStatus = async () =>{
        if(this.props.status !== this.state.status){
            console.log(this.props.profile);
            console.log(1);
            console.log(this.state.status);
            await this.props.getDocuments(this.props.profile, 1, this.state.status);
        }
    }

    popper = async (message) =>{
        this.props.addPopper({
          title: "success",
          message: message,
          time: 6000,
          level: "success"
      });
    }

    render() {
        const { messages } = this.props;
        let page = 1;
        if(this.props.documents.meta !== undefined){
            page = this.props.documents.meta.current_page;
        }
        console.log(this.props.documents[this.props.profile].data);
        return (
            <div className="flex-center full-height position-ref">
                <Container>
                    <h2>{messages['profile']}: {this.props.profile} {messages['page']}: {page} {messages['status']}: { this.state.typeOfStatus[this.props.status]}</h2>
                    <Nav fill variant="tabs" className="Custom_Card">
                      <Nav.Item>
                        <Nav.Link eventKey="client" onSelect={this.onSelect}>{messages['client']}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="technician" onSelect={this.onSelect}>{messages['technician']}</Nav.Link>
                      </Nav.Item>
                      </Nav> 
                    <Table hover variant="dark" className="Custom_Table" responsive>
                        <thead>
                                <tr>
                                <th>{messages['id']}</th>
                                <th>{messages['first_name']}</th>
                                <th>{messages['last_name']}</th>
                                <th>{messages['status']}</th>
                                <th>{messages['options']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.documents[this.props.profile].data.map((Data, key) =>{
                                return(
                                    <tr key={Data.id}>
                                        <td>{Data.id}</td>
                                        <td>{Data.firstName}</td>
                                        <td>{Data.lastName}</td>
                                        <td>{Data.passport.messages }</td>
                                        <td><Button 
                                            className="Button_Padding"
                                            variant="outline-light"
                                            onClick={() => this.onMakeRequest(Data.id)}>
                                                {messages['check_passport']}
                                            </Button> </td>
                                    </tr>
                                )})}
                            </tbody>
                        </Table>
                        <ButtonGroup>
                                <Button variant="outline-light" onClick={() => this.changePage("-")}>{messages['prev']}</Button>
                                <Button variant="outline-light" onClick={() => this.changePage("+")}>{messages['next']}</Button>
                        </ButtonGroup>
                        <Row>
                            <Col>
                                <Form.Control as="select" name="status" onChange={this.onChange}>
                                <option value="0">{messages['empty']} </option>
                                <option value="1">{messages['on_review']}</option>
                                <option value="2">{messages['passport_accepted']}</option>
                                </Form.Control>
                            </Col>
                            <Col>
                                <Button variant="outline-light" onClick={this.changeStatus}>{messages['change_status']}</Button>
                            </Col>
                        </Row>

                    </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profile: state.admin.documents.profile,
    documents: state.admin.documents.documents,
    status: state.admin.documents.status,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    getDocuments,
    setIdProfile,
    addPopper
})(ReviewDocuments);



                        