import { LINES } from "@actions/Client/lines";

const initialState = {
    lines: null,
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {

        case LINES.GET_LINES:
            {
                return {
                    ...state,
                    lines: action.lines
                };
            }

        default:
            return state;
    }
}
