import axios from "axios";

export const BUSINESS_ROTATION = {
    GET_BUSINESS_ROTATION: "GET_BUSINESS_ROTATION",
    ERROR: "ERROR"
};
export const PROFILE_BUSINESS ={
    ADD_BUSINESS_ROTATION: "ADD_BUSINESS_ROTATION",
    DELETE_BUSINESS_ROTATION: "DELETE_BUSINESS_ROTATION",
    ERROR: "ERROR"
};
import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: BUSINESS_ROTATION.ERROR,
    error
});

export const getBusinessRotation = () => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.business_rotation);
        dispatch({
            type: BUSINESS_ROTATION.GET_BUSINESS_ROTATION,
            businessRotation: response.data
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};


export const addBusinessRotation = (data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.add_business_rotation, data);
        dispatch({
            type: PROFILE_BUSINESS.ADD_BUSINESS_ROTATION,
            businessRotation: response.data
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};


export const deleteBusinessRotation = (id) => async dispatch => {
    try {
        const response = await axios.delete(API_CONSTANT_MAP.delete_business_rotation(id));
        dispatch({
            type: PROFILE_BUSINESS.DELETE_BUSINESS_ROTATION,
            id: id
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};
