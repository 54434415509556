export const LANG = {
    LOAD_LANGUAGE: "LOAD_LANGUAGE",
    UPDATE_LANGUAGE: "UPDATE_LANGUAGE",
    ERROR: "ERROR"
};

export const loadLanguage = () => async dispatch => {
    const language = localStorage.getItem("lang");
    dispatch({
        type: LANG.LOAD_LANGUAGE,
        language: language || "en"
    });
};

export const setLanguage = (language, type = null) => async dispatch => {
    localStorage.setItem("lang", language);
    dispatch({
        type: LANG.UPDATE_LANGUAGE,
        language: language,
        rol: type
    });
};
