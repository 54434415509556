import React from "react";
import styled from "styled-components";

const DayStyled = styled.td`
    height: 16em;
    position: relative;
    transition: all 200ms ease-in;
    background: ${props => (props.isOffset ? "#f8f8f8" : "white")};

    &:hover {
        cursor: pointer;
        box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.25);
        transform: scale(1.01);
        z-index: 99;
    }

    div {
        height: 100%;
        padding: 4.5em 0.75em 0;
        position: relative;
        border-bottom: 2px solid transparent;
        border-color: ${props => props.isMarked === true && props.color};
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        font-size: 1.5em;
        font-weight: 600;
        width: 2em;
        height: 2em;
        border-radius: 50%;

        background: ${props =>
            props.isMarked === null
                ? "inherit"
                : props.isMarked === false
                ? "#3d6dcc"
                : props.color};
        color: ${props => (props.isMarked === null ? "black" : "white")};
    }

    @media (max-width: 1620px) {
        height: 13em;
    }

    @media (max-width: 960px) {
        height: 11em;
    }

    @media (max-width: 600px) {
        height: 9.5em;
    }

    @media (max-width: 448px) {
        height: 7.5em;
        padding: 0;

        span {
            top: 50%;
            left: 50%;
            right: auto;
            transform: translate(-50%, -50%);
        }
    }

    @media (max-width: 360px) {
        height: 6em;
    }
`;

const Day = ({ date, onDayClick, offset, marked, color }) => {
    return (
        <DayStyled
            isMarked={marked}
            color={color}
            isOffset={offset}
            onClick={() => onDayClick(date)}
        >
            <div>
                <span>{date.day}</span>
            </div>
        </DayStyled>
    );
};

export default Day;
