import jwt_decode from "jwt-decode";
import axios from "axios";
import moment from "moment";

export const AUTH = {
    SET_TOKENS: "SET_TOKENS",
    LOAD_TOKENS: "LOAD_TOKENS",
    UPDATE_TOKEN: "UPDATE_TOKEN",
    UPDATE_LANG: "UPDATE_LANG",
    REMOVE_TOKENS: "REMOVE_TOKENS",
    CLEAN_AUTH: "CLEAN_AUTH",
    PROFILE: "PROFILE",
    ERROR: "ERROR",
    LANGUAGES: "LANGUAGES",
    UPDATE_CHANGE_OF_PASSWORD: "UPDATE_CHANGE_OF_PASSWORD"
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: AUTH.ERROR,
    error
});

export const setTokens = ({ token, publicToken }) => async dispatch => {
    localStorage.setItem("token", token);
    localStorage.setItem("publicToken", publicToken);
    const { type } = jwt_decode(token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    dispatch({
        type: AUTH.SET_TOKENS,
        token,
        publicToken,
        rol: type
    });
};

export const loadTokens = () => async dispatch => {
    const token = localStorage.getItem("token");
    const publicToken = localStorage.getItem("publicToken");
    let type = null;
    if (token) type = jwt_decode(token).type;
    dispatch({
        type: AUTH.LOAD_TOKENS,
        token,
        publicToken,
        rol: type
    });
};

export const updateToken = (rol, local_token, lang) => async dispatch => {
    const parsedToken = jwt_decode(local_token);

    const current = moment();
    const date = moment.unix(parsedToken.exp);

    if (!date.isAfter(current)) {
        dispatch(error("Token expired"));
    }

    if (!date.utcOffset(-2).isBefore(current)) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${local_token}`;
        return;
    }

    try {
        const response = await axios.post(
            API_CONSTANT_MAP.update_lang,
            { lang },
            {
                headers: {
                    Authorization: `Bearer ${local_token}`
                }
            }
        );
        const token = response.data.access_token.toString();
        const publicToken = response.data.public_token.toString();

        localStorage.setItem("token", token);
        localStorage.setItem("publicToken", publicToken);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        dispatch({
            type: AUTH.UPDATE_TOKEN,
            token,
            publicToken,
            rol
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const updateUser = (data, type) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.update_user(type), data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        console.log(response.data);
        if (response.status === 200) {
            dispatch({
                type: AUTH.PROFILE,
                user: response.data
            });
        } else {
            dispatch(error("Something went wrong"));
        }
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const removeTokens = () => async dispatch => {
    try {
        localStorage.removeItem("token");
        localStorage.removeItem("publicToken");
        axios.defaults.headers.common["Authorization"] = null;
        dispatch({
            type: AUTH.REMOVE_TOKENS
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const cleanAuth = () => dispatch => {
    dispatch({
        type: AUTH.CLEAN_AUTH
    });
};

export const fetchProfile = rol => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.profile(rol));

        dispatch({
            type: AUTH.PROFILE,
            user: response.data
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const closeSession = rol => async dispatch => {
    try {
        await axios.get(API_CONSTANT_MAP.logout(rol));
        localStorage.removeItem("token");
        localStorage.removeItem("publicToken");
        axios.defaults.headers.common["Authorization"] = null;
    } catch (err) {
    } finally {
        dispatch({ type: AUTH.REMOVE_TOKENS });
        dispatch({ type: AUTH.CLEAN_AUTH });
    }
};

export const languages = (selected, removed) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.languages, {
            selected,
            removed
        });
        dispatch({ type: AUTH.LANGUAGES, selected: response.data, removed });
    } catch (err) {
        dispatch(error(err.message));
    }
};
