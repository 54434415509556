import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import Calendar, { FY_FORMAT } from "@components/shared/calendar";
import { fetchSchedule, saveSchedule, fetchService } from "@actions/technician";
import { addPopper } from "@actions/popper";
import { merge, isPresent } from "@utils/calendar";

const ScheduleStyled = styled.div`
    padding: 1.5rem;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
`;

class Schedule extends React.Component {
    state = {
        calendar: {},
        now: moment(),
        maxDate: moment().add(6, "months"),
        is_saving: false,
        service: null
    };

    async componentDidMount() {
        await this.props.fetchSchedule(this.props.loaded);
    }

    onDayClick = date => {
        const future = moment(date.dateString, FY_FORMAT);

        if (future.isAfter(this.state.now) && future.isBefore(this.state.maxDate)) {
            const { calendar } = this.state;
            const { year, month, day } = date;
            const { schedule } = this.props;

            if (!calendar[year]) {
                calendar[year] = {};
            }

            if (!calendar[year][month]) {
                calendar[year][month] = {
                    week_type: 1
                };
            }

            // The point of this is to have to have in the local state just the actual changes from
            // the original schedule so the request to the server is smaller and not send the full
            // calendar.

            // If date doesn't exist in local state, we need to check in redux state to see
            // if already exists
            if (!calendar[year][month][day]) {
                // If the date already exists in the redux state, we need to check its status
                // to set the proper status to the local state.
                if (isPresent(schedule, { year, month, day })) {
                    const date = schedule[year][month][day];
                    if (date["s"] === 1) {
                        calendar[year][month][day] = {
                            s: 0
                        };
                    } else if (date["s"] === 0) {
                        calendar[year][month][day] = {
                            s: 1
                        };
                    }
                    // Otherwise we just create the day in the local state
                } else {
                    calendar[year][month][day] = {
                        s: 1
                    };
                }
            } else {
                if (calendar[year][month][day]["s"] === 0) {
                    calendar[year][month][day]["s"] = 1;
                } else if (calendar[year][month][day]["s"] === 1) {
                    calendar[year][month][day]["s"] = 0;
                }
            }

            this.setState({ calendar });
        }
    };

    onDayPreviewClick = async date => {
        const { day, month, year } = date;
        const { sid } = this.props.schedule[year][month][day];
        let service = this.props.services[3].data.find(s => s.id.toString() === sid.toString());
        if (service === undefined) {
            await this.props.fetchService(sid);
            service = this.props.services[3].data.find(s => s.id.toString() === sid.toString());
        }
        this.setState({ service });
    };

    onCloseSidebar = () => {
        this.setState({ service: null });
    };

    onSave = async () => {
        const { calendar } = this.state;

        if (Object.keys(calendar).length === 0) return;

        this.setState({ is_saving: true });
        await this.props.saveSchedule(this.props.schedule, this.state.calendar);
        this.props.addPopper({
            title: "success",
            message: "updatedCalendar",
            time: 6000,
            level: "success"
        });
        this.setState({ calendar: {}, is_saving: false });
    };

    render() {
        const { locale, messages, schedule } = this.props;
        const { calendar, is_saving, service } = this.state;

        return (
            <ScheduleStyled>
                <div className="mt-4 pb-2">
                    <Calendar
                        locale={locale}
                        dates={merge(schedule, calendar)}
                        showSidebar={true}
                        onCloseSidebar={this.onCloseSidebar}
                        service={service}
                        onDayClick={this.onDayClick}
                        onDayPreviewClick={this.onDayPreviewClick}
                    />
                </div>
                <div className="text-right">
                    {is_saving && <Spinner variant="light" animation="border" size="sm" />}
                    {!is_saving && (
                        <Button size="sm" variant="outline-light" onClick={this.onSave}>
                            {messages["saveChanges"]}
                        </Button>
                    )}
                </div>
            </ScheduleStyled>
        );
    }
}

const mapStateToProps = state => ({
    locale: state.lang.locale,
    schedule: state.tech.req_ser.schedule,
    loaded: state.tech.req_ser.loaded_calendar,
    services: state.tech.req_ser.services
});

export default connect(mapStateToProps, {
    fetchSchedule,
    saveSchedule,
    fetchService,
    addPopper
})(Schedule);
