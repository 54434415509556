import { useState, useEffect } from "react";
import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";

export default function useService() {
    const [specialties, setSpecialties] = useState([]);

    async function createService(data) {
        try {
            const response = await axios.post(
                API_CONSTANT_MAP.admin_create_service_simulation,
                data
            );
            return response.data.id;
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function getEng(id) {
        const response = await axios.get(API_CONSTANT_MAP.admin_get_suitors(id));
        return response.data.data;
    }

    async function fetchSpecialties() {
        const response = await axios.get(API_CONSTANT_MAP.get_all_specialties);
        setSpecialties(response.data.data);
    }

    return {
        createService,
        getEng,
        specialties,
        fetchSpecialties
    };
}
