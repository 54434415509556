import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Form, Button, Accordion, Card, Modal, Row, Col, Container } from "react-bootstrap";
import { API_CONSTANT_MAP } from "@utils/routes";

class TechnicianList extends React.Component {

    state = {
        Status: 1,
        error: null,
        is_loading: false
    };


    
    onMakeRequest = async (idTechnician) => {
        try {
            const response = await axios.get(API_CONSTANT_MAP.accept_technician(this.props.IdService, idTechnician));
            if(response.status === 200){
                this.props.route.history.push("/c/service/1");
            }
            else{
                alert("there was a problem");
            }

        } catch (err) {
            console.log(err.message);
        }
    };


    render() {
        const { messages } = this.props;
        let technicians = [];
        if(this.props.Technicians !== null){
          technicians = this.props.Technicians;
        }
        return (
            <div className="min-h-full flex flex-col justify-center align-items-center">
            
            <Accordion defaultActiveKey="0">
                            {technicians.length === 0 &&
                            <h1> {messages['no_technicians']}</h1>
                            }
                            {technicians && technicians.map((item, key)  =>{
                                return(
                                    <Card className="Custom_Card">
                                        <Accordion.Toggle as={Card.Header} eventKey={key}>
                                            <h3>{messages['distance']}: {item.distance.toFixed(2)} {messages['meters']}  {messages['daily_rate']}: {item.daily_rate} {messages['dollars']}</h3>
                                            <Button
                                                variant="outline-light"
                                                className="Button_Padding"
                                                onClick={() => this.onMakeRequest(item.id)}
                                            >
                                                {messages['choose_technician']}
                                            </Button>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={key}>
                                        <Card.Body>
                                            <h3>{messages['skills']}:</h3>
                                            {item.skills && item.skills.map((skill, skillKey) =>{
                                                return(
                                                <div>
                                                    <h4>{messages['specialty']}: {skill.specialty.name}</h4>
                                                    {skill.depth_levels && skill.depth_levels.map((depth_level, depthKey) =>{
                                                            return(                                                                                                       
                                                            <Row>
                                                                <Col><h5>{messages['level']} 1</h5>
                                                                    {depth_level.in_depth_level_1.name && <div>{messages['name']}: {depth_level.in_depth_level_1.name} {messages['rating']}: {depth_level.in_depth_level_1.rating}  </div>}
                                                                </Col>
                                                                <Col><h5>{messages['level']} 2</h5>
                                                                    {(depth_level.in_depth_level_2 !== null && depth_level.in_depth_level_2.name) && <div>{messages['name']}: {depth_level.in_depth_level_2.name} {messages['rating']}: {depth_level.in_depth_level_2.rating}  </div>}
                                                                </Col>
                                                                <Col><h5>{messages['level']} 3</h5>
                                                                    {(depth_level.in_depth_level_3 !== null && depth_level.in_depth_level_3.name) && <div>{messages['name']}: {depth_level.in_depth_level_3.name} {messages['rating']}: {depth_level.in_depth_level_3.rating}  </div>}
                                                                </Col>
                                                                <Col><h5>{messages['level']} 4</h5>
                                                                    {(depth_level.in_depth_level_4 !== null && depth_level.in_depth_level_4.name) && <div>{messages['name']}: {depth_level.in_depth_level_4.name} {messages['rating']}: {depth_level.in_depth_level_4.rating}  </div>}
                                                                </Col>
                                                            </Row>
                                                            )})}
                                                </div>
                                                )})}
                                            <h3>{messages['experience']}: </h3>
                                            {item.experience && item.experience.map((experience, expKey) =>{
                                                return(
                                                <Row>
                                                    <Col><h5>{messages['equipment']}</h5>
                                                        {experience.equipment && <div>{messages['name']}: {experience.equipment.name}  </div>}
                                                    </Col>
                                                    <Col><h5>{messages['technology']}</h5>
                                                        {experience.technology && <div>{messages['name']}: {experience.technology.name}  </div>}
                                                    </Col>
                                                    <Col><h5>{messages['brand']}</h5>
                                                        {experience.brand && <div>{messages['name']}: {experience.brand.name}  </div>}
                                                    </Col>
                                                    <Col><h5>{messages['model']}</h5>
                                                        {experience.model && <div>{messages['name']}: {experience.model.name}  </div>}
                                                    </Col>
                                                </Row>
                                                )})}
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>     
                                );
                            })}
                        </Accordion>
                  
  
            </div>
        );
    }
}

const mapStateToProps = state => ({
    Technicians: state.client.Service.technicians,
    IdService: state.client.Service.idService,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {

})(TechnicianList);



