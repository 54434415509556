import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    fetchSkills,
    fetchExperiences,
    addSkill,
    updateRate,
    deleteSkill,
    addExperience,
    removeExperience
} from "@actions/Administrator/technicianInvitation";
import Experiences from "@components/shared/experiences";
import Specialties from "@components/shared/skills";

class Skills extends React.Component {
    async componentDidMount() {
        const { skills_loading, exp_loading } = this.props;
        if (!skills_loading) await this.props.fetchSkills(this.props.route.match.params.id);
        if (!exp_loading) await this.props.fetchExperiences(this.props.route.match.params.id);
    }

    handleAddSkill = (_, data) => this.props.addSkill(data);
    handleUpdateRate = (_, data) => this.props.updateRate(data);
    handleDeleteSkill = id => this.props.deleteSkill(id);

    handleAddExperience = data => this.props.addExperience(data);
    handleDeleteExperience = id => this.props.removeExperience(id);

    render() {
        const { messages, skills, skills_loading, exp_loading, exp } = this.props;

        return (
            <Container>
                <Specialties
                    skills={skills}
                    loading={skills_loading}
                    technician={this.props.route.match.params.id}
                    onAddedSkill={this.handleAddSkill}
                    onUpdatedRate={this.handleUpdateRate}
                    onDeletedSkill={this.handleDeleteSkill}
                    onDetails={skill => {
                        return <Link to={`/a/invite_technician/skill_detail/${this.props.route.match.params.id}/${skill.id}`}>{messages["seeDetails"]}</Link>;
                    }} 
                />

                <Experiences
                    loading={exp_loading}
                    experiences={exp}
                    technician={this.props.route.match.params.id}
                    onAddedExperience={this.handleAddExperience}
                    onDeletedExperience={this.handleDeleteExperience}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    skills: state.admin.technician_skill.list,
    skills_loading: state.admin.technician_skill.loaded,
    exp: state.admin.technician_experience.list,
    exp_loading: state.admin.technician_experience.loaded,
});

export default connect(mapStateToProps, {
    fetchSkills,
    fetchExperiences,
    addSkill,
    updateRate,
    deleteSkill,
    addExperience,
    removeExperience
})(Skills);
