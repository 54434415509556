import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";

export const EXPERIENCE = {
    FETCH_EXPERIENCES: "FETCH_EXPERIENCES",
    ADD_EXPERIENCE: "ADD_EXPERIENCE",
    REMOVE_EXPERIENCE: "REMOVE_EXPERIENCE",
    RESET: "RESET_EXPERIENCES",
    ERROR: "ERROR"
};

const error = error => ({
    type: EXPERIENCE.ERROR,
    error
});

export const fetchExperiences = technician => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.experiences.get(technician));

        dispatch({
            type: EXPERIENCE.FETCH_EXPERIENCES,
            experiences: response.data
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const addExperience = experience => dispatch =>
    dispatch({
        type: EXPERIENCE.ADD_EXPERIENCE,
        experience
    });

export const removeExperience = id => dispatch =>
    dispatch({
        type: EXPERIENCE.REMOVE_EXPERIENCE,
        id
    });

export const resetExperiences = () => dispatch => {
    dispatch({ type: EXPERIENCE.RESET });
};
