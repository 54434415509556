import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion, Card, Button } from "react-bootstrap";
import useService from "@hooks/useService";
import { useLocation } from "react-router";
import { Star } from "@components/icons/star";

const TechnicianList = props => {
    const messages = useSelector(state => state.lang.messages);

    const { getEng } = useService();

    const technicians = [{ daily_rate: 100, distance: 100.3, skills: [] }];

    const [engineers, setEngineers] = useState([]);

    const search = useLocation().search;

    useEffect(() => {
        if (props.route.history.location) {
            const serviceId = new URLSearchParams(search).get("serviceId");
            async function fetchEngineers() {
                const Engineers = await getEng(serviceId);
                setEngineers(Engineers);
            }

            fetchEngineers();
        }
    }, []);

    return (
        <div className="min-h-full flex flex-col justify-center align-items-center">
            <Accordion style={{ width: "100%", paddingLeft: 40, paddingRight: 40 }}>
                {engineers.map(engineer => {
                    console.log(engineer);
                    return (
                        <Card key={engineer.id}>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey={engineer.id + "t"}
                                >
                                    {`${engineer.technician.name ? engineer.technician.name : ""} ${
                                        engineer.technician.last_name
                                    } - ${engineer.technician.email}`}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={engineer.id + "t"}>
                                <Card.Body>
                                    <div style={{ fontSize: 20, marginTop: 20 }}>
                                        Informacion de usuario
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ flex: 1 }}>
                                            Nombre: {engineer.technician.name}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            Apellido: {engineer.technician.last_name}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            Email: {engineer.technician.email}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            Telefono: {engineer.technician.phone_number}
                                        </div>
                                    </div>
                                    <div style={{ fontSize: 20, marginTop: 20 }}>Daily rate</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ flex: 1 }}>
                                            Daily rate: {engineer.daily_rate.daily_rate}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            Años de experiencia en skill elegida:{" "}
                                            {engineer.daily_rate.years}
                                        </div>
                                    </div>
                                    <Accordion style={{ marginTop: 20 }}>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey={engineer.id + "e"}
                                                >
                                                    Experiencia
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={engineer.id + "e"}>
                                                <Card.Body>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            flex: "0 0 33.333333%"
                                                        }}
                                                    >
                                                        {engineer.experience.map(exp => (
                                                            <Card
                                                                style={{
                                                                    flex: "0 0 33.333333%",
                                                                    padding: 20
                                                                }}
                                                            >
                                                                <div style={{ fontSize: 18 }}>
                                                                    {exp.equipment.name}
                                                                </div>
                                                                <div>Marca: {exp.brand.name}</div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        marginTop: 20
                                                                    }}
                                                                >
                                                                    <div style={{ flex: 1 }}>
                                                                        Tecnologia:
                                                                    </div>
                                                                    <div style={{ flex: 1 }}>
                                                                        Modelo:
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "row"
                                                                    }}
                                                                >
                                                                    <div style={{ flex: 1 }}>
                                                                        {exp.technology.name}
                                                                    </div>
                                                                    <div style={{ flex: 1 }}>
                                                                        {exp.model.name}
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        ))}
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <Accordion style={{ marginTop: 20 }}>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey={engineer.id + "s"}
                                                >
                                                    Skills
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={engineer.id + "s"}>
                                                <Card.Body>
                                                    {engineer.skills.map(skill => (
                                                        <Accordion>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle
                                                                        as={Button}
                                                                        variant="link"
                                                                        eventKey={skill.id + "sk"}
                                                                    >
                                                                        {skill.specialty.name}
                                                                    </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse
                                                                    eventKey={skill.id + "sk"}
                                                                >
                                                                    <Card.Body
                                                                        style={{
                                                                            display: "flex",
                                                                            flexWrap: "wrap",
                                                                            flex: "0 0 33.333333%"
                                                                        }}
                                                                    >
                                                                        {skill.roots.map(root => (
                                                                            <Card
                                                                                style={{
                                                                                    flex:
                                                                                        "0 0 33.333333%",
                                                                                    padding: 20
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    {root.root.name}
                                                                                </div>
                                                                                {root.labels.map(
                                                                                    label => (
                                                                                        <div>
                                                                                            {
                                                                                                label
                                                                                                    .label
                                                                                                    .name
                                                                                            }
                                                                                            {label.protocols.map(
                                                                                                protocol => (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            display:
                                                                                                                "flex",
                                                                                                            flexDirection:
                                                                                                                "row"
                                                                                                        }}
                                                                                                    >
                                                                                                        <div>
                                                                                                            {protocol
                                                                                                                .protocol
                                                                                                                .name +
                                                                                                                ": "}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {[
                                                                                                                ...Array(
                                                                                                                    protocol.rate
                                                                                                                )
                                                                                                            ].map(
                                                                                                                (
                                                                                                                    item,
                                                                                                                    i
                                                                                                                ) => (
                                                                                                                    <Star
                                                                                                                        key={
                                                                                                                            protocol.name +
                                                                                                                            i
                                                                                                                        }
                                                                                                                    />
                                                                                                                )
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </Card>
                                                                        ))}
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </Accordion>
                                                    ))}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    );
                })}
            </Accordion>
        </div>
    );
};

export default TechnicianList;
