import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container, Spinner, Form, Row, Col, Button } from "react-bootstrap";
import Calendar from "@components/shared/calendar";
import MediaGallery from "@components/MediaGallery";
import VideoGallery from "@components/VideoGallery";
import GoogleMapReact from "google-map-react";
import Marker from "@components/Marker";
import MapControl from "@components/MapControl";

class ServiceDetails extends React.Component {
    state = {
        service: null,
        redirect: false,
        loaded: false
    };

    static getDerivedStateFromProps(props, state) {
        const { route, services } = props;
        const { id } = route.match.params;
        const service = services.find(service => service.id.toString() === id);

        if (!service) {
            return {
                redirect: true
            };
        }

        return {
            service,
            loaded: true
        };
    }

    render() {
        const { service, redirect, loaded } = this.state;
        const { messages, locale, user } = this.props;
        const key = process.env.MIX_GOOGLE_MAPS_KEY;
        const v = process.env.MIX_GOOGLE_MAPS_VER;

        if (redirect) {
            return <Redirect to="/c/service" />;
        }


        let year = null;
        let month = null;

        if (loaded) {
            [year] = Object.keys(service.dates);
            [month] = Object.keys(service.dates[year]) + 1;
        }

        return (
            <Container>
                <h1 className="reset-header text-white pt-4 mb-4">{messages["requestDetails"]}</h1>
                {!loaded && <Spinner animation="border" variant="light" />}
                {loaded && (
                    <Form>
                        <Row>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["service"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.service === "object"
                                                ? service.service.name
                                                : service.service
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["equipment"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.machine === "object"
                                                ? service.machine.name
                                                : service.machine
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["brand"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.brand === "object"
                                                ? service.brand.name
                                                : service.brand
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["model"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.model === "object"
                                                ? service.model.name
                                                : service.model
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["specialty"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.specialty === "object"
                                                ? service.specialty.name
                                                : service.specialty
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} x={12}>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["equipmentYear"]}
                                    </Form.Label>
                                    <Form.Control value={service.equipment_year} readOnly={true} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="text-white">
                                        {messages["requestDescription"]}
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        value={service.description}
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                )}
                {loaded && (
                    <>
                        <MediaGallery resources={service.images} title={messages["images"]} />
                        <VideoGallery resources={service.videos} title={messages["videos"]} />
                        <h2 className="pt-2 mb-2">{messages["dates"]}</h2>
                        <Calendar
                            locale={locale}
                            dates={service.dates || {}}
                            showSidebar={false}
                            year={year}
                            month={month}
                        />
                        <h2 className="pt-4 mb-2">{messages["map"]}</h2>
                        <div className="map-h">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key, v }}
                                center={{
                                    lat: user.latitude,
                                    lng: user.longitude
                                }}
                                zoom={15}
                                draggable={false}
                                onGoogleApiLoaded={({ map, maps }) => {
                                    this.map = map;
                                    this.maps = maps;
                                    this.setState({
                                        mapControlShouldRender: true
                                    });
                                }}
                                yesIWantToUseGoogleMapApiInternals
                            >
                                <Marker lat={user.latitude} lng={user.longitude} />
                                <MapControl
                                    map={this.map || null}
                                    controlPosition={
                                        this.maps ? this.maps.ControlPosition.TOP_CENTER : null
                                    }
                                />
                            </GoogleMapReact>
                        </div>
                        <br />
                    </>
                )}
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    services: state.client.Service.service,
    locale: state.lang.locale,
    user: state.auth.user
});

export default connect(mapStateToProps, {})(ServiceDetails);
