import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Nav, Table, Container } from "react-bootstrap";
import { getServices, setStatus, getTechnicians, cancelService, getTechnicianList } from "@actions/Administrator/serviceSimulator/client/service";
import ServiceShared from "@components/shared/services/client/service";
import Moment from "moment";

class Service extends React.Component {
    state = {
        Status: 1,
        error: null,
        is_loading: false
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentDidMount = async () => {
        await this.props.getServices(this.props.Status);
    };

    onMakeRequest = async () => {};

    onSelect = async e => {
        if (e !== this.props.Status) {
            await this.props.setStatus(e);
            await this.props.getServices(this.props.Status);
            this.setState({ Status: parseInt(e) });
        }
    };

    requestService = () => {
        this.props.route.history.push("/a/client_simulator/form");
    };

    seeTechnicians = async id => {
        await this.props.getTechnicianList(id);
        //this.props.route.history.push("/a/client_simulator/technician_list");
    };

    cancelService = async id => {
        await this.props.cancelService(id);
        await this.props.getServices(this.props.Status);
    };

    render() {
        const { messages } = this.props;
        return (
            <div className="min-h-full flex flex-col justify-center align-items-center">
              <ServiceShared
                    services = {this.props.Service.data}
                    status = {String(this.props.Status)}
                    seeTechnicians = {this.seeTechnicians}
                    cancelService = {this.cancelService}
                    onSelect = {this.onSelect}
                    requestService = {this.requestService}
                    messages = {messages}
              />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    Service: state.admin.client_service_simulator.service,
    Status: state.admin.client_service_simulator.status,
});

export default connect(mapStateToProps, {
    getServices,
    setStatus,
    getTechnicians,
    cancelService,
    getTechnicianList
})(Service);
