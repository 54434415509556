import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Form, Button, Accordion, Card, Modal, Row, Col, Container, CardColumns } from "react-bootstrap";
import { API_CONSTANT_MAP } from "@utils/routes";
import Stars from "@components/Stars";

class TechnicianProfile extends React.Component {

    state = {
        Status: 1,
        error: null,
        is_loading: false
    };

    render() {
        const { messages } = this.props;
        let technician;
        let jsonString = localStorage.getItem('technicianData');
        if(jsonString !== null){
            technician = JSON.parse(jsonString);
        }
        return (
            <div className="margin_card">
                <h1>Information About technician</h1>
                <Card>
                    <Card.Body>
                        <Card.Title>ID: {technician.id}</Card.Title>
                        <Card.Title>Daily rate: {technician.daily_rate} dollars</Card.Title>
                        <Card.Title>Distance: {technician.distance.toFixed(2)} meters</Card.Title>
                    </Card.Body>
                </Card>
                <h1>Skills</h1>
                {
                    technician.skills.map(skill => {
                        return (
                            <Card key={skill.id} border="light" className="Card_padding">
                                <Card.Body>
                                    <Card.Title>{skill.specialty.name}</Card.Title>
                                    <Card.Text className="flex flex-row justify-between mb-0">
                                        <span className="mb-0">
                                            {messages["dailyRate"]}: {skill.daily_rate}
                                        </span>
                                    </Card.Text>
                                    <Card.Text>
                                        {messages["yearsOfExperience"]}: {skill.years}
                                    </Card.Text>
                                        <h4 className="black-letters"> Roots: </h4>

                                    <CardColumns>
                                        {skill.roots.map(root => {
                                            return (
                                                <Card key={root.id} className="Custom_Gray_Card" >
                                                    <Card.Header className="flex justify-between">
                                                        <span>{root.root.name}</span>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        {/* Brand Mode */}
                                                        {root.root.mode === "brand" &&
                                                            root.labels.map(label => {
                                                                return (
                                                                    <Card key={label.id} className="Custom_Grayer_Card" >
                                                                        <Card.Body>
                                                                            <Card.Title className="flex justify-between">
                                                                                {label.label.name}
                                                                            </Card.Title>

                                                                            <ul>
                                                                                {label.protocols.map(protocol => {
                                                                                    return (
                                                                                        <li
                                                                                            key={protocol.id}
                                                                                            className="mb-3"
                                                                                        >
                                                                                            <p className="m-0">
                                                                                                {protocol.protocol.name}
                                                                                            </p>
                                                                                            <Stars stars={protocol.rate} />
                                                                                        </li>
                                                                                    );
                                                                                })}
                                                                            </ul>
                                                                        </Card.Body>
                                                                    </Card>
                                                                );
                                                            })}

                                                        {/* Direct Mode */}
                                                        {root.root.mode === "direct" && (
                                                            <Card className="Custom_Grayer_Card" >
                                                                <Card.Body>
                                                                    <ul>
                                                                        {root.protocols.map(protocol => {
                                                                            return (
                                                                                <li key={protocol.id} className="mb-3">
                                                                                    <p className="m-0">
                                                                                        {protocol.protocol.name}
                                                                                    </p>
                                                                                    <Stars stars={protocol.rate} />
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </Card.Body>
                                                            </Card>
                                                        )}

                                                        <div className="text-right">
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            );
                                        })}
                                    </CardColumns>

                                </Card.Body>
                            </Card>
                        );
                    })}
            
            <h1>Experience</h1>

            <CardColumns>
                {
                    technician.experience.map(e => {
                        const { equipment, brand, technology, model } = e;
                        return (
                            <Card key={e.id} border="light">
                                <Card.Body>
                                    <Card.Title className="flex justify-between">
                                        <span>{equipment.name}</span>
                                    </Card.Title>
                                    <Card.Text className="mb-0">
                                        <span className="mb-0">
                                            {messages["brand"]}: {brand.name}
                                        </span>
                                    </Card.Text>
                                    <Card.Text className="mb-0">
                                        <span className="mb-0">
                                            {messages["technology"]}: {technology.name}
                                        </span>
                                    </Card.Text>
                                    <Card.Text className="mb-0">
                                        <span className="mb-0">
                                            {messages["model"]}: {model.name}
                                        </span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        );
                    })}
            </CardColumns>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {

})(TechnicianProfile);



