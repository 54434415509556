import React from "react";
import { useSelector } from "react-redux";

const MediaGallery = ({ resources, title, type = 1 }) => {
    const publicToken = useSelector(state => state.auth.publicToken);

    return (
        <>
            <h1 className="pt-4 mb-2">{title}</h1>
            <div className={`gallery-grid ${type === 2 && "videos"}`}>
                {resources.map(r => {
                    return <img key={r.id} src={`${r.url}/${publicToken}`} />;
                })}
            </div>
        </>
    );
};

export default MediaGallery;
