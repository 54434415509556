import { SERVICE } from "@actions/Client/service";

const initialState = {
    serviceList: [],
    technicians: null,
    idService: null,
    status: 1,
    options: null,
    error: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SERVICE.TECHNICIAN_GET_SERVICES: {
            return {
                ...state,
                serviceList: action.service
            };
        }

        case SERVICE.SET_STATUS: {
            return {
                ...state,
                status: action.status
            };
        }

        case SERVICE.GET_REQUEST_OPTIONS: {
            return {
                ...state,
                options: action.options
            };
        }

        case SERVICE.GET_TECHNICIANS: {
            return {
                ...state,
                technicians: action.technicians,
                idService: action.idService
            };
        }

        case SERVICE.CANCEL_SERVICE: {
            return {
                ...state
            };
        }

        default:
            return state;
    }
}
