import axios from "axios";


export const DOCUMENTS = {
    GET_DOCUMENTS: "GET_DOCUMENTS",
    GET_USER_DOCUMENTS: "GET_USER_DOCUMENTS",
    EDIT_DOCUMENT_STATUS: "EDIT_DOCUMENT_STATUS",
    SET_ID_PROFILE: "SET_ID_PROFILE",
    GET_PROFILE: "GET_PROFILE",
    GET_USER_SERVICES: "GET_USER_SERVICES"
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: DOCUMENTS.ERROR,
    error
});

export const getDocuments = (Profile, Page, Status) => async dispatch => {
    try {
        console.log(Profile, Page, Status);
        const response = await axios.get(API_CONSTANT_MAP.get_documents("technician", Page, Status));
        dispatch({
            type: DOCUMENTS.GET_DOCUMENTS,
            documents: response.data,
            profile: Profile,
            status: Status
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getUserDocuments = (Profile, id) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.user_documents(Profile, id));
        dispatch({
            type: DOCUMENTS.GET_USER_DOCUMENTS,
            documents: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const setIdProfile = (idProfile) => async dispatch => {
    try {
        dispatch({
            type: DOCUMENTS.SET_ID_PROFILE,
            idProfile: idProfile,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getProfile = (Profile, id) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_profile(Profile, id));
        dispatch({
            type: DOCUMENTS.GET_PROFILE,
            data: response.data,
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};


export const getUserServices = (Profile, id) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.get_user_services(Profile, id));
        dispatch({
            type: DOCUMENTS.GET_USER_SERVICES,
            services: response.data,
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const reviewDocument = (Profile, id, data) => async dispatch => {
    try {
        const response = await axios.put(API_CONSTANT_MAP.user_documents(Profile, id), data);
        dispatch({
            type: DOCUMENTS.GET_USER_DOCUMENTS,
            documents: response.data,
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};



