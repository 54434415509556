import React from "react";
import { Container } from "react-bootstrap";
import RequestServices from "./services/RequestServices";

class Dashboard extends React.Component {
    render() {
        const { route } = this.props;
        return (
            <Container>
                <RequestServices route={route} />
            </Container>
        );
    }
}

export default Dashboard;
