import axios from "axios";


export const INVITATION = {
    CREATE_TECHNICIAN: "CREATE_TECHNICIAN",
    ERROR: "ERROR"
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: INVITATION.ERROR,
    error
});

export const createTechnician = (data) => async dispatch => {
    try {
        const response = await axios.post(API_CONSTANT_MAP.create_technician, data);
        console.log(response.data);
        dispatch({
            type: INVITATION.CREATE_TECHNICIAN,
            technician: response.data,
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};







