import React from "react";
import styles from "./styles";
import { Button, Modal, DropdownButton, Dropdown } from "react-bootstrap";

const ServiceDetailCard = ({
    dates,
    description,
    service,
    specialty,
    status,
    hourlyRate,
    machine,
    model,
    brand,
    year,
    onChangeStatus
}) => {
    let startDate = `2025`;
    let endDate = `2025`;

    if (dates) {
        let monthArrayStart = dates[Object.keys(dates)[0]];
        let monthStart = Object.keys(dates[Object.keys(dates)[0]])[0];
        let dayStart = Object.keys(monthArrayStart[Object.keys(monthArrayStart)[0]])[0];

        let monthArrayEnd = dates[Object.keys(dates)[Object.keys(dates).length - 1]];
        let monthEnd = Object.keys(dates[Object.keys(dates)[Object.keys(dates).length - 1]])[
            Object.keys(dates).length - 1
        ];
        let dayArrayEnd =
            monthArrayEnd[Object.keys(monthArrayEnd)[Object.keys(monthArrayEnd).length - 1]];
        let dayEnd = Object.keys(dayArrayEnd)[Object.keys(dayArrayEnd).length - 1];

        startDate = `${Object.keys(dates)[0]}/${monthStart}/${dayStart}`;
        endDate = `${Object.keys(dates)[Object.keys(dates).length - 1]}/${monthEnd}/${dayEnd}`;
    }

    return (
        <div style={styles.card}>
            <div style={styles.flexBetween}>
                <div style={styles.title}>Service Information</div>
                <DropdownButton title="" variant="light">
                    <Dropdown.Item onClick={() => onChangeStatus()}>
                        Change service status
                    </Dropdown.Item>
                </DropdownButton>
            </div>
            <div style={styles.flexRow}>
                <div>
                    <div style={styles.key}>Description:</div>
                    <div style={styles.data}>{description}</div>
                </div>
            </div>
            <div style={styles.flexRow}>
                <div style={styles.flexColumn}>
                    <div style={styles.key}>Service:</div>
                    <div style={styles.data}>{service}</div>
                </div>
                <div>
                    <div style={styles.key}>Specialty:</div>
                    <div style={styles.data}>{specialty}</div>
                </div>
            </div>
            <div style={styles.flexRow}>
                <div style={styles.flexColumn}>
                    <div style={styles.key}>Status:</div>
                    <div style={styles.data}>{status}</div>
                </div>
                <div>
                    <div style={styles.key}>Wanted hourly rate:</div>
                    <div style={styles.data}>{`$${hourlyRate} dollars`}</div>
                </div>
            </div>
            <div style={styles.flexRow}>
                <div style={styles.flexColumn}>
                    <div style={styles.key}>Start date:</div>
                    <div style={styles.data}>{startDate}</div>
                </div>
                <div>
                    <div style={styles.key}>End date:</div>
                    <div style={styles.data}>{endDate}</div>
                </div>
            </div>
            <div style={styles.subtitle}>Machine Information</div>
            <div style={styles.flexRow}>
                <div style={styles.flexColumn}>
                    <div style={styles.key}>Machine:</div>
                    <div style={styles.data}>{machine}</div>
                </div>
                <div>
                    <div style={styles.key}>Model:</div>
                    <div style={styles.data}>{model}</div>
                </div>
            </div>
            <div style={styles.flexRow}>
                <div style={styles.flexColumn}>
                    <div style={styles.key}>Brand:</div>
                    <div style={styles.data}>{brand}</div>
                </div>
                <div>
                    <div style={styles.key}>Year:</div>
                    <div style={styles.data}>{year}</div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetailCard;
