import React from "react";
import { connect } from "react-redux";
import { Form, Spinner, Button } from "react-bootstrap";
import { API_CONSTANT_MAP } from "@utils/routes";
import { languages } from "@actions/auth";
import axios from "axios";

class LikeLanguages extends React.Component {
    state = {
        languages: [],
        is_loading: true,
        is_saving: false,
        selected: [],
        removed: []
    };

    async componentDidMount() {
        try {
            const response = await axios.get(API_CONSTANT_MAP.languages);
            this.setState({ languages: response.data, is_loading: false });
        } catch (err) {
            console.log(err);
        }
    }

    onChange = e => {
        const { initial } = this.props;
        let { removed, selected } = this.state;

        if (initial.some(s => s.id.toString() === e.target.value.toString())) {
            if (removed.includes(e.target.value.toString()))
                removed = removed.filter(s => s.toString() !== e.target.value.toString());
            else removed = [...removed, e.target.value];
        } else {
            if (selected.includes(e.target.value.toString()))
                selected = selected.filter(s => s.toString() !== e.target.value.toString());
            else selected = [...selected, e.target.value];
        }

        this.setState({ selected, removed });
    };

    onSave = async () => {
        this.setState({ is_saving: true });
        await this.props.languages(this.state.selected, this.state.removed);
        this.setState({ selected: [], removed: [], is_saving: false });
    };

    render() {
        const { messages, initial } = this.props;
        const { languages, is_loading, is_saving, selected, removed } = this.state;

        return (
            <>
                <h1 className="mt-4 mb-2">{messages["preferredLanguages"]}</h1>
                <Form>
                    {is_loading && <Spinner variant="light" animation="border" />}
                    {!is_loading &&
                        languages.map(lang => {
                            return (
                                <Form.Check
                                    key={lang.id}
                                    type="checkbox"
                                    label={lang.name}
                                    value={lang.id}
                                    className="text-white"
                                    checked={
                                        (initial.some(
                                            e => e.id.toString() === lang.id.toString()
                                        ) &&
                                            !removed.includes(lang.id.toString())) ||
                                        selected.includes(lang.id.toString())
                                    }
                                    onChange={this.onChange}
                                />
                            );
                        })}
                    <div className="text-right">
                        {is_saving && <Spinner variant="light" animation="border" size="sm" />}
                        {!is_saving && (
                            <Button
                                disabled={!(selected.length > 0 || removed.length > 0)}
                                size="sm"
                                variant="outline-light"
                                onClick={this.onSave}
                            >
                                {messages["saveChanges"]}
                            </Button>
                        )}
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    messages: state.lang.messages,
    initial: state.auth.user.languages
});

export default connect(mapStateToProps, { languages })(LikeLanguages);
