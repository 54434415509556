import React from "react";
import styled from "styled-components";
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form, Row, Col, Button} from 'react-bootstrap';

const SidebarStyled = styled.div`
    width: ${props => (props.isOpen ? "40em" : "0em")};
    overflow: hidden;
    transition: all 500ms ease-in;

    .wrapper {
        background: white;
        margin-left: 1em;

        h2 {
            color: black;
            margin-bottom: 0;
        }
    }

    .buttons {
        margin-top: 2em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    @media (max-width: 1280px) {
        width: 100%;

        .wrapper {
            margin-left: 0;
            margin-top: 1em;
        }
    }
`;

const Sidebar = ({ service, onClose }) => {
    const messages = useSelector(state => state.lang.messages);

    return (
        <SidebarStyled isOpen={service}>
            <main className="wrapper">
                <h2 className="text-center">{messages['serviceOverview']}</h2>
                
                {service && (
                    <Form className="mt-4">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label as="h5" className="text-black">
                                        {messages["service"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.service === "object"
                                                ? service.service.name
                                                : service.service
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label as="h5" className="text-black mt-2">
                                        {messages["specialty"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.specialty === "object"
                                                ? service.specialty.name
                                                : service.specialty
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label as="h5" className="text-black mt-2">
                                        {messages["equipment"]}
                                    </Form.Label>
                                    <Form.Control
                                        value={
                                            typeof service.machine === "object"
                                                ? service.machine.name
                                                : service.machine
                                        }
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="buttons">
                            <Button size="sm" variant="secondary" onClick={onClose}>
                                {messages['close']}
                            </Button>
                            <Link to={`/t/service/3/${service.id}`}>
                                <Button size="sm" variant="primary">
                                    {messages['seeDetails']}
                                </Button>
                            </Link>
                        </div>
                    </Form>
                )}
            </main>
        </SidebarStyled>
    );
};

export default Sidebar;
