import React from "react";
import styles from "./styles";

const ServiceTechnicianCard = props => {
    const { data } = props;

    return (
        <div style={styles.card}>
            <div style={styles.title}>Technician information</div>
            <div>
                <div style={styles.key}>Name:</div>
                <div style={styles.data}>
                    {data?.technician !== undefined
                        ? `${data?.technician?.firstName} ${data?.technician?.lastName}`
                        : "Technician not assigned"}
                </div>
            </div>
        </div>
    );
};

export default ServiceTechnicianCard;
