import React from "react";
import { connect } from "react-redux";
import { Button, Container, Table, Nav, Modal, Form, ButtonGroup } from "react-bootstrap";
import Moment from "moment";
import { deleteItem, editItem, addItem, getTechnologies } from "@actions/Administrator/management";
import { FaMinusCircle, FaEdit } from 'react-icons/fa';
import { addPopper } from "@actions/popper";


class Technologies extends React.Component {

    state = {
        show: false,
        editModal: false,
        eliminateModal: false,
        item: null,
        spanish: null,
        english: null,
        error: null,
        select: null,
        is_loading: false
    };

    componentDidMount = async () =>{
        await this.props.getTechnologies(this.props.route.match.params.id, 1);
    };

    handleClose = () =>{this.setState({show: false});};

    handleOpen = () =>{this.setState({show: true});};

    eliminateModal = (Data) =>{this.setState({eliminateModal: true, item: Data});};

    handleEliminateModal = () =>{this.setState({eliminateModal: false});};

    editModal = (Data) =>{this.setState({editModal: true, item: Data});};

    handleEditModal = () =>{this.setState({editModal: false});};

    onChange = e => {
      this.setState({
          [e.target.name]: e.target.value
      });
    };

    changePage = async  (symbol) => {
      if(symbol === "+" && this.props.management.technology.meta.current_page < this.props.management.technology.meta.last_page){
        await this.props.getTechnologies(this.props.route.match.params.id, (this.props.management.technology.meta.current_page + 1));
      }
      else if(symbol === "-" && this.props.management.technology.meta.current_page > 1){
        await this.props.getTechnologies(this.props.route.match.params.id, (this.props.management.technology.meta.current_page - 1));
      }
    };

    deleteItem = async () =>{
      await this.props.deleteItem("technology", this.state.item.id);
      await this.setState({eliminateModal: false});
      this.popper("deleted");
    }

    editItem = async () =>{
      let Data ={
        es:{
          name: this.state.spanish
        },
        en:{
          name: this.state.english
        }
      };
      await this.props.editItem("technology", this.state.item.id, Data);
      await this.setState({editModal: false});
      this.popper("edited");
    }

    addItem = async () =>{
    let  Data = {
        en_name:this.state.english,
        es_name:this.state.spanish,
        equipment_id: this.props.route.match.params.id
      };
      await this.props.addItem("technology", Data);
      await this.setState({show: false});
      this.popper("added");
    }

    popper = async (message) =>{
      this.props.addPopper({
        title: "success",
        message: message,
        time: 6000,
        level: "success"
    });
    }

    render() {
        const { messages, publicToken } = this.props;
        return (
            <div className="min-h-full flex flex-col justify-center align-items-center">
            <Container> 

                    <Table hover variant="dark" className="Custom_Table" responsive>
                      <thead>
                      <tr>
                        <th>{messages['id']}</th>
                        <th>{messages['name']}</th>
                        <th>{messages['created_at']}</th>
                        <th>{messages['updated_at']}</th>
                        <th>{messages['options']}</th>
                      </tr>
                      </thead>
                      <tbody>
                        {this.props.management.technology.data.map((Data, key) =>{
                          return(
                            <tr key= {Data.id}>
                              <td>{ Data.id }</td>
                              <td>{ Data.name }</td>
                              <td>{ Moment(Data.created_at).format('YYYY/MM/DD') }</td>
                              <td>{ Moment(Data.updated_at).format('YYYY/MM/DD') }</td>
                              <td><Button 
                                    className="Button_Padding"
                                    variant="outline-light"
                                    onClick={() => this.eliminateModal(Data)}>
                                      <FaMinusCircle/>
                                    </Button> 
                                    <Button
                                      className="Button_Padding"
                                      variant="outline-light"
                                      onClick={() => this.editModal(Data)}>
                                        <FaEdit/>
                                    </Button>
                              </td>
                            </tr>
                          )})}
                      </tbody>
                    </Table>
                    <ButtonGroup>
                      <Button variant="outline-light" onClick={() => this.changePage("-")}>{messages['prev']}</Button>
                      <Button variant="outline-light" onClick={() => this.changePage("+")}>{messages['next']}</Button>
                    </ButtonGroup>
                    <Button
                        variant="outline-light"
                        onClick={this.handleOpen}
                        className="Button_Padding"
                          >{messages['add']} {messages['technology']}</Button>
            </Container>

            <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                          <Modal.Title>{messages['add']} {messages['technology']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <h5 className="dark_title">{messages['select_equipment']}</h5>
                            <h5 className="dark_title">{messages['technology']} {messages['name_en']}</h5>
                            <Form.Control type="text" name="english" placeholder="name" onChange={this.onChange}/>
                            <h5 className="dark_title">{messages['technology']} {messages['name_es']}</h5>
                            <Form.Control type="text" name="spanish" placeholder="nombre" onChange={this.onChange}/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                    {messages['close']}
                    </Button>
                    <Button variant="primary" onClick={this.addItem}>
                    {messages['add']}
                    </Button>
                    </Modal.Footer>
            </Modal>
            <Modal show={this.state.eliminateModal} onHide={this.handleEliminateModal}>
                    <Modal.Header closeButton>
                          <Modal.Title>{messages['delete']} {messages['technology']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>{messages['delete_this?']}</p>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleEliminateModal}>
                      {messages['close']}
                    </Button>
                    <Button variant="primary" onClick={this.deleteItem}>
                      {messages['yes']}
                    </Button>
                    </Modal.Footer>
            </Modal>
            <Modal show={this.state.editModal} onHide={this.handleEditModal}>
                    <Modal.Header closeButton>
                          <Modal.Title>{messages['edit']} {messages['technology']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>{messages['delete_this?']}</p>
                      <Form>
                        <h5 className="dark_title">{messages['technology']} {messages['name_en']}</h5>
                        <Form.Control type="text" name="english" placeholder="name" onChange={this.onChange}/>
                        <h5 className="dark_title">{messages['technology']} {messages['name_es']}</h5>
                        <Form.Control type="text" name="spanish" placeholder="nombre" onChange={this.onChange}/>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleEditModal}>
                      {messages['close']}
                    </Button>
                    <Button variant="primary" onClick={this.editItem}>
                    {messages['yes']}
                    </Button>
                    </Modal.Footer>
            </Modal>

          </div>
        );
    }
}

const mapStateToProps = state => ({
    management: state.admin.management.management,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    deleteItem,
    editItem, 
    addItem,
    getTechnologies,
    addPopper
})(Technologies);
 