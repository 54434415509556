import React, { useState } from "react";
import useRequestServices from "@hooks/useRequestServices";
import RequestServiceTable from "@components/admin/tables/RequestServiceTable/index";
import { Spinner } from "react-bootstrap";
import ServiceChangeStatusTableModal from "@components/admin/modals/ServiceChangeStatusTableModal";
import { Button } from "react-bootstrap";

const Services = props => {
    const { messages, route } = props;
    const { loading, data, setPage, status, setStatus } = useRequestServices();
    const [show, setShow] = useState(false);

    const seeDetails = async service => {
        route.history.push(`/a/service_detail/${service.id}`);
    };

    return (
        <div className="min-h-full flex flex-col justify-center align-items-center">
            {loading === true ? (
                <Spinner animation="border" variant="light" />
            ) : (
                <>
                    <RequestServiceTable
                        data={data.data}
                        loading={loading}
                        messages={messages}
                        seeDetails={service => seeDetails(service)}
                        meta={data.meta}
                        status={status}
                        changeStatus={() => setShow(true)}
                        changePage={page => setPage(page)}
                    />
                    <ServiceChangeStatusTableModal
                        initialStatus={status}
                        show={show}
                        messages={messages}
                        onHide={() => setShow(false)}
                        updateStatus={serviceStatus => setStatus(serviceStatus)}
                    />
                </>
            )}
        </div>
    );
};

export default Services;
