import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Form, Button, Row, Col, Container, Image, Jumbotron } from "react-bootstrap";
import {getRequestOptions} from "../../../actions/Client/service";
import { API_CONSTANT_MAP } from "@utils/routes";
import Calendar, { FY_FORMAT } from "@components/shared/calendar";
import moment from "moment";
import Dropzone from 'react-dropzone';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css,
import DataListInput from 'react-datalist-input';
const imageMaxSize = 10000000000;
class ServiceForm extends React.Component {

    state = {
        service: null,
        machine: null,
        machineString:null,
        brand: null,
        brandString:null,
        model: null,
        year: null,
        specialty: null,
        description: null,
        modelOptions: [],
        calendar: {},
        now: moment(),
        maxDate: moment().add(6, "months"),
        Images: [],
        Videos: [],
        error: null,
        is_loading: false
    };
    onInputService = (e) =>{this.setState({service: e});}
    onInputMachine = (e) =>{this.setState({machineString: e});}
    onInputBrand = (e) =>{this.setState({brandString: e});}
    onInputModel = (e) =>{this.setState({model: e});}
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    onChangeDataList = e => {
        this.setState({
            [e.name]: e.key
        });

    };

    onChangeForModel = async (e) =>{
      let modelOptions;
      await this.setState({
            [e.type]: e.id,
            [e.type + "String"]: e.key
        });

        if(this.state.machine !== null && this.state.brand){
          try {
            let model = await axios.get(API_CONSTANT_MAP.get_model(this.state.machine, this.state.brand));
            modelOptions = model.data.map((item, key) => {
              return {
                  label: item.name,
                  key: item.name,
                  name: "model"
              }
            });
            this.setState({modelOptions: modelOptions});

          } catch (error) {
            console.log(error.message);
          }
        }
    };

    componentDidMount = async () => {await this.props.getRequestOptions();};
    
    onSelect = async  (e) => {
      if(e !== this.props.Status){
        await this.props.setStatus(e);
        await this.props.getServices(this.props.Status);
      }
    };

    onDayClick = date => {
      const is_future = moment(date.dateString, FY_FORMAT);
      if (
          is_future.isAfter(this.state.now) &&
          is_future.isBefore(this.state.maxDate)
      ) {
          const { calendar } = this.state;
          const { year, month, day } = date;
          if (!calendar[year]) {
              calendar[year] = {};
          }

          if (!calendar[year][month]) {
              calendar[year][month] = {
                  week_type: 1
              };
          }

          if (!calendar[year][month][day]) {
              calendar[year][month][day] = {
                  s: 1
              };
          } else {
              if (calendar[year][month][day]["s"] === 0) {
                  calendar[year][month][day]["s"] = 1;
              } else if (calendar[year][month][day]["s"] === 1) {
                  calendar[year][month][day]["s"] = 0;
              }
          }
          this.setState({ calendar });
      }
  };
 
  onDropImage = (files, rejectedFiles) =>{
    files.forEach((element, index) => {
      let myFileReader = new FileReader();
      myFileReader.addEventListener("load", ()=>{
        let Images = this.state.Images;
        let ImageArray = [myFileReader.result, "Image" + index, element];
        Images.push(ImageArray);
        this.setState({Images: Images});
    }, false)
    myFileReader.readAsDataURL(element);
    });};

  onDropVideo = (files, rejectedFiles) =>{
    files.forEach((element, index) => {
      let myFileReader = new FileReader();
      myFileReader.addEventListener("load", ()=>{
        let Videos = this.state.Videos;
        let VideoArray = [myFileReader.result, "Video" + index, element];
        Videos.push(VideoArray);
        this.setState({Videos: Videos});
    }, false)
    myFileReader.readAsDataURL(element);
  });};

  handleImages = (e) =>{
    let Image = this.state.Images;
    Image.forEach((element, index) => {
      if(element[1] === e.target.name ){
        Image.splice(index, 1);
      }
    });
    Image.forEach((element, index) => {
      element[1] = "Image" +  index
    });
    this.setState({Images: Image});
  };

  handleVideos = async (e) =>{
    let Video = this.state.Videos;
    Video.forEach((element, index) => {
      if(element[1] === e.target.name ){
        Video.splice(index, 1);
      }
    });
    Video.forEach((element, index) => {
      element[1] = "Video" +  index
    });
    await this.setState({Videos: Video});};

  append_media = formData => {
    this.state.Images.forEach((image, index) => {
      formData.append(`images[${index}]`, image[0] );
    });
    this.state.Videos.forEach((video, index) => {
      formData.append(`videos[${index}]`, video[0]);
    });
    return formData;
  };

  matchCurrentInput = (currentInput, item) => {
    const yourLogic = item.key;
    return (yourLogic.substr(0, currentInput.length).toUpperCase() === currentInput.toUpperCase());
  };

  onMakeRequest = async () => {
    let Data = {
      service_name: this.state.service,
      equipment_name: this.state.machineString,
      specialty_name: this.state.specialty,
      brand_name: this.state.brandString,
      model_name: this.state.model,
      equipment_year: this.state.year,
      description: this.state.description,
      dates: this.state.calendar,
    };
    let formData = new FormData();
    formData.append('service_name', Data.service_name);
    formData.append('equipment_name', Data.equipment_name);
    formData.append('specialty_name', Data.specialty_name);
    formData.append('brand_name', Data.brand_name);
    formData.append('model_name', Data.model_name);
    formData.append('equipment_year', parseInt(Data.equipment_year));
    formData.append('description', Data.description);
    formData.append('dates', JSON.stringify(Data.dates));
    this.state.Images.forEach((image, index) => {
      formData.append(`images[${index}]`, image[2] );
    });
    this.state.Videos.forEach((video, index) => {
      formData.append(`videos[${index}]`, video[2]);
    });

    try {
      const reqService = await axios.post(API_CONSTANT_MAP.post_service, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
      if(reqService.status === 201){
        console.log(reqService.data);
        this.props.route.history.push("/c/service/1");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

    render() {
      const { messages } = this.props;
      let options = [];
      let service = [];
      let equipment = [];
      let brand = [];
      if(this.props.Options !== null){
        options = this.props.Options;
        service = options.Service.map((item, key) => {
          return {
              label: item.name,
              key: item.name,
              name: "service"
          }
        });
        equipment = options.Equipment.map((item, key) => {
          return {
              label: item.name,
              key: item.name,
              id: item.id,
              type: "machine"
          }
        });
        brand = options.Brand.map((item, key) => {
          return {
              label: item.name,
              key: item.name,
              id: item.id,
              type: "brand"
          }
        });
      }
        return (
            <div className="min-h-full flex flex-col justify-center align-items-center">
                <Form>
                    <Form.Group>
                        <Form.Label className="white_title" >{messages['kind_of_service']}</Form.Label>
                        {service!== null &&
                          <DataListInput 
                            items={service}
                            onSelect={this.onChangeDataList}
                            onInput={this.onInputService}
                            match={this.matchCurrentInput}
                          />
                        }
                        <Row>
                            <Col>
                                <Form.Label className="white_title" >{messages['kind_of_machine']}</Form.Label>
                                {equipment!== null &&
                                  <DataListInput 
                                    items={equipment}
                                    onSelect={this.onChangeForModel}
                                    onInput={this.onInputMachine}
                                    match={this.matchCurrentInput}
                                  />
                                }
                            </Col>
                            <Col>
                                <Form.Label className="white_title">{messages['kind_of_brand']}</Form.Label>
                                {brand!== null &&
                                  <DataListInput 
                                    items={brand}
                                    onSelect={this.onChangeForModel}
                                    onInput={this.onInputBrand}
                                    match={this.matchCurrentInput}
                                  />
                                }
                            </Col>
                            <Col>
                                <Form.Label className="white_title">{messages['kind_of_model']}</Form.Label>
                                {this.state.modelOptions!== null &&
                                  <DataListInput 
                                    items={this.state.modelOptions}
                                    onSelect={this.onChangeDataList}
                                    onInput={this.onInputModel}
                                    match={this.matchCurrentInput}
                                  />
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="white_title">{messages['year']}</Form.Label>
                                <Form.Control type="number"  min="1900" max="2020"  name="year" onChange={ this.onChange } />  
                            </Col> 
                            <Col>
                                <Form.Label className="white_title">{messages['specialty']}</Form.Label>
                                <Form.Control as="select"  name="specialty" onChange={ this.onChange } custom>
                                    <option></option>
                                    {options.Specialty && options.Specialty.map(function(item,key) {
                                        return(
                                            <option value={item.name}>{item.name}</option>
                                    )})}
                                </Form.Control>
                            </Col>
                        </Row>
                        <Form.Label className="white_title">{messages['description']}</Form.Label>
                        <Form.Control as="textarea" rows="5" cols="100" name="description"  onChange= {this.onChange}/>
                        <Form.Label className="white_title" >{messages['video']}</Form.Label>
                         <Dropzone onDrop={this.onDropVideo}
                      maxSize={imageMaxSize}
                      accept="video/mp4"
                      >
                          
                      {({getRootProps, getInputProps}) => (
                          <section>
                          <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Jumbotron>
                              <h1 className="black_title">{messages['upload_videos']}</h1>
                            </Jumbotron>
                          </div>
                          </section>
                      )}


                      </Dropzone>

                      <Row>
                      {this.state.Videos && this.state.Videos.map((item,key) =>{
                        return(
                          <Col>
                              <Player  fluid = {false} width="200" heigth="200">
                                <source src={item[0]}/>
                              </Player>
                              <Button name={item[1]}  onClick={e => this.handleVideos(e)} variant="outline-light" className="Button_Padding">{messages['delete']}</Button>
                          </Col>
                      )})}
                      </Row>

                        <Form.Label className="white_title" >{messages['images']}</Form.Label>
                          <Dropzone onDrop={this.onDropImage}
                      maxSize={imageMaxSize}
                      accept="image/*"
                      >
                          
                      {({getRootProps, getInputProps}) => (
                          <section>
                          <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Jumbotron>
                              <h1 className="black_title">{messages['upload_images']}</h1>
                            </Jumbotron>
                          </div>
                          </section>
                      )}

                      </Dropzone>
                      <Row>
                      {this.state.Images && this.state.Images.map((item,key) =>{
                                        return(
                                          <Col>
                                            <Image src={item[0]} className="Passport" />
                                            <Button name={item[1]}  onClick={e => this.handleImages(e)} variant="outline-light" className="Button_Padding">{messages['delete']}</Button>
                                          </Col>
                                    )})}
                        </Row>
                        <Form.Label className="white_title" >{messages['calendar']}</Form.Label>
                    </Form.Group>
                    </Form>
                    <Container>
                <Calendar
                  locale={this.props.locale}
                  dates={this.state.calendar}
                  showSidebar={false}
                  onDayClick={this.onDayClick}/>
                </Container>
                <Button 
                  variant="outline-light"
                  className="Button_Padding"
                  onClick={this.onMakeRequest}>
                  {messages['submit']}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    Options: state.client.Service.options,
    locale: state.lang.locale,
    messages: state.lang.messages,
});

export default connect(mapStateToProps, {
    getRequestOptions
})(ServiceForm);