import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import ChangeLanguage from "@components/shared/ChangeLanguage";
import ChangePassword from "@components/shared/ChangePassword";
// import Passport from "@components/shared/Passport";
import LikeLanguages from "@components/shared/LikeLanguages";
import { resetSkills, resetServices, resetExperiences } from "@actions/technician";

class Settings extends React.Component {
    onLanguageSave = () => {
        this.props.resetSkills();
        this.props.resetServices();
        this.props.resetExperiences();
    };

    render() {
        return (
            <Container>
                <Row>
                    {/* <Col>
                        <Passport />
                    </Col> */}
                    <Col>
                        <ChangePassword />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <ChangeLanguage onSave={this.onLanguageSave} />
                    </Col>
                    <Col>
                        <LikeLanguages />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default connect(null, {
    resetSkills,
    resetServices,
    resetExperiences
})(Settings);
