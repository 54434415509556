import axios from "axios";


export const SERVICE = {
    ADMIN_GET_SERVICES: "ADMIN_GET_SERVICES",
    ADMIN_SET_STATUS: "ADMIN_SET_STATUS",
    ADMIN_GET_REQUEST_OPTIONS: "ADMIN_GET_REQUEST_OPTIONS",
    ADMIN_GET_TECHNICIANS: "ADMIN_GET_TECHNICIANS",
    ADMIN_CANCEL_SERVICE: "ADMIN_CANCEL_SERVICE",
    ADMIN_GET_SPECIFIC_MODEL: "ADMIN_GET_SPECIFIC_MODEL",
    ADMIN_SEE_TECHNICIAN_DETAILS: "ADMIN_SEE_TECHNICIAN_DETAILS",
    ERROR: "ERROR"
};

import { API_CONSTANT_MAP } from "@utils/routes";

const error = error => ({
    type: SERVICE.ERROR,
    error
});

export const getServices = (service) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.admin_get_services(service));
        dispatch({
            type: SERVICE.ADMIN_GET_SERVICES,
            service: response.data
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};


export const setStatus = (status) => async dispatch => {
    try {
        dispatch({
            type: SERVICE.ADMIN_SET_STATUS,
            status: status
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};


export const getRequestOptions = () => async dispatch => {
    try {
        const service = await axios.get(API_CONSTANT_MAP.admin_service_options);
        const equipment = await axios.get(API_CONSTANT_MAP.admin_machine_options);
        const specialty = await axios.get(API_CONSTANT_MAP.admin_specialty_options);
        const brand = await axios.get(API_CONSTANT_MAP.admin_brand_options);

        const Data = {
            Service: service.data,
            Equipment: equipment.data,
            Specialty: specialty.data,
            Brand: brand.data
        };
        dispatch({
            type: SERVICE.ADMIN_GET_REQUEST_OPTIONS,
            options: Data
        });

    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getModels = (equipment, brand) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.experiences.get_models(equipment, brand));
        let modelOptions = response.data.map((item, key) => {
            return {
                label: item.name,
                key: item.name,
                name: "model"
            }
          });
        dispatch({
            type: SERVICE.ADMIN_GET_SPECIFIC_MODEL,
            models: modelOptions
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const getTechnicians = (formData) => async dispatch => {
    try {
        const clients = await axios.get(API_CONSTANT_MAP.get_profiles("client", 1));
        const clientId = clients.data.data[0].id;
        const response = await axios.post(API_CONSTANT_MAP.post_admin_service(clientId), formData, {
          headers: {
              "Content-Type": "multipart/form-data"
          }
      });
        let data = JSON.stringify(response.data);
        localStorage.setItem('technicianList', data);
      } catch (error) {
        console.log(error.message);
      }
}

export const getTechnicianList = (id) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.admin_technician_list(id));
        dispatch({
            type: SERVICE.ADMIN_GET_TECHNICIANS,
            technicians: response.data
        });
      } catch (error) {
        console.log(error.message);
      }
}

export const cancelService = (id) => async dispatch => {
    try {
        const response = await axios.delete(API_CONSTANT_MAP.cancel_service(id));
        dispatch({
            type: SERVICE.ADMIN_CANCEL_SERVICE,
            responce: response.data
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const seeTechnicianDetails = (item) => async dispatch => {
    try {
        dispatch({
            type: SERVICE.ADMIN_SEE_TECHNICIAN_DETAILS,
            technician: item
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};
