import axios from "axios";
import { API_CONSTANT_MAP } from "@utils/routes";
import { toJson, merge, extractColorsFromServices, randomColor } from "@utils/calendar";

export const REQUEST_SERVICE = {
    GET_SCHEDULE: "GET_SCHEDULE",
    GET_SERVICES: "GET_SERVICES",
    GET_SERVICE: "GET_SERVICE",
    SAVE_SCHEDULE: "SAVE_SCHEDULE",
    ACCEPT_SERVICE: "ACCEPT_SERVICE",
    REJECT_SERVICE: "REJECT_SERVICE",
    RESET: "RESET_SERVICES",
    ERROR: "ERROR"
};

const error = error => ({
    type: REQUEST_SERVICE.ERROR,
    error
});

export const fetchSchedule = loaded => async dispatch => {
    if (loaded) return;

    try {
        const response = await axios.get(API_CONSTANT_MAP.schedule);
        const schedule = toJson(response.data);
        const colors = extractColorsFromServices(JSON.stringify(schedule));

        dispatch({
            type: REQUEST_SERVICE.GET_SCHEDULE,
            schedule,
            colors
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const fetchServices = (status = 2, loaded) => async dispatch => {
    if (loaded) return;

    try {
        const response = await axios.get(API_CONSTANT_MAP.services(status));

        dispatch({
            type: REQUEST_SERVICE.GET_SERVICES,
            services: response.data,
            status
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const fetchService = (id) => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.service(id));

        dispatch({
            type: REQUEST_SERVICE.GET_SERVICE,
            service: response.data
        });
    } catch (err) {
        dispatch(error(err.message));
    }
}

export const saveSchedule = (schedule, calendar) => async dispatch => {
    try {
        const response = await axios.post(
            API_CONSTANT_MAP.schedule,
            {
                data: calendar
            },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );

        dispatch({
            type: REQUEST_SERVICE.SAVE_SCHEDULE,
            schedule: merge(schedule, toJson(response.data))
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const acceptService = id => async dispatch => {
    try {
        const response = await axios.get(API_CONSTANT_MAP.accept_service(id));
        const color = { [id]: randomColor() };

        dispatch({
            type: REQUEST_SERVICE.ACCEPT_SERVICE,
            id,
            service: response.data,
            color
        });
    } catch (err) {
        dispatch(error(err.message));
    }
};

export const rejectService = id => async dispatch => {
    try {
        await axios.get(API_CONSTANT_MAP.reject_service(id));

        dispatch({
            type: REQUEST_SERVICE.REJECT_SERVICE,
            id
        });
    } catch (err) {
        dispatch(error(err.response.data));
    }
};

export const resetServices = () => dispatch => {
    dispatch({ type: REQUEST_SERVICE.RESET });
};
