import React from "react";
import { connect } from "react-redux";
import { Button, Spinner, Image } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { updateUser } from "@actions/auth";

const imageMaxSize = 10000000;

class Passport extends React.Component {
    state = {
        image: null,
        is_saving: false
    };

    onDrop = files => {
        files = files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        );
        this.setState({
            image: files[0]
        });
    };

    onSave = async () => {
        this.setState({ is_saving: true });
        const { image } = this.state;

        const formData = new FormData();
        formData.append("passport_doc", this.state.image, this.state.image.name);

        await this.props.updateUser(formData, this.props.rol);

        this.setState({ is_saving: false, image: null });
    };

    render() {
        const { messages, publicToken } = this.props;
        const { status, url } = this.props.passport;
        const message = this.props.passport.messages;
        const { is_saving, image } = this.state;

        let src = undefined;
        if (image !== null) {
            src = image.preview;
        } else {
            if (status === 0) {
                src = undefined;
            } else if (status === 1 || status === 2) {
                src = url + "/" + publicToken;
            }
        }

        return (
            <>
                <h1 className="mt-4 mb-2">{messages["passport"]}</h1>
                {/* Messages */}
                {status === 0 && (
                    <p className="text-white">
                        {messages["status"]}: {messages["passport.0"]}
                    </p>
                )}
                {status === 1 && (
                    <p className="text-white">
                        {messages["status"]}:{" "}
                        {message.length > 0 ? message : messages["passport.1"]}
                    </p>
                )}
                {status === 2 && (
                    <p className="text-white">
                        {messages["status"]}: {messages["passport.2"]}
                    </p>
                )}

                {/* Upload File */}
                {(status === 0 || status === 1) && (
                    <Dropzone onDrop={this.onDrop} maxSize={imageMaxSize} accept="image/*">
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    <p className="mb-0">{messages["dragAndDrop"]}</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )}

                {/* Display Image when is in server */}
                {src && (
                    <div className="text-center mt-3">
                        <Image src={src} className="Passport" />
                    </div>
                )}

                {/* Button to upload image to server */}
                <div className="text-right pt-2">
                    {is_saving && <Spinner variant="light" animation="border" size="sm" />}
                    {!is_saving && (status === 0 || status === 1) && (
                        <Button
                            size="sm"
                            disabled={image === null}
                            variant="outline-light"
                            onClick={this.onSave}
                        >
                            {messages["uploadFile"]}
                        </Button>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    messages: state.lang.messages,
    passport: state.auth.user.passport,
    publicToken: state.auth.publicToken,
    rol: state.auth.rol
});

export default connect(mapStateToProps, {
    updateUser
})(Passport);
