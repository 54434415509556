import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { closeSession } from "@actions/auth";
import profilePicture from "@images/profilePicture.png";
import { resetSkills, resetServices, resetExperiences } from "@actions/technician";

class PrivateLayout extends Component {
    state = {
        is_open: false
    };

    onLogout = async () => {
        const { rol } = this.props;

        if (rol === "technician") {
            this.props.resetSkills();
            this.props.resetServices();
            this.props.resetExperiences();
        }

        await this.props.closeSession(rol);
    };

    onToggleSidebar = () => {
        this.setState({
            is_open: !this.state.is_open
        });
    };

    render() {
        const Component = this.props.component;
        const { is_open } = this.state;
        const { route, user, links, messages } = this.props;

        if (
            user &&
            user.force_change_of_password &&
            user.force_change_of_password === 1 &&
            route.location.pathname !== "/t/update_password"
        ) {
            return <Redirect to="/t/update_password" />;
        }

        return (
            <div className="full-screen">
                <header className="sidebar max-h flex flex-col">
                    <nav className="profile">
                        <div className="hamburger" onClick={this.onToggleSidebar}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="information">
                            <div className="image">
                                <img src={profilePicture} alt="User Logo" />
                            </div>
                            <div className="username">
                                <p className="tags">
                                    {user !== null && user.firstName}
                                    <br />
                                    {user !== null && user.lastName}
                                </p>
                            </div>
                        </div>
                    </nav>
                    <div className={`links ${is_open ? "open" : ""}`}>
                        <ul className="list">
                            {links.map(link => (
                                <li key={link.key}>
                                    <Link to={link.to}>{messages[link.key]}</Link>
                                </li>
                            ))}
                        </ul>
                        <button
                            className="logout"
                            onClick={() => {
                                this.onLogout();
                            }}
                        >
                            {messages["log_out"]}
                        </button>
                    </div>
                </header>
                <div className="flex-1 max-h scroll-y">
                    <Component route={route} messages={messages} />
                </div>
            </div>
        );
    }
}

export default connect(null, {
    closeSession,
    resetSkills,
    resetServices,
    resetExperiences
})(PrivateLayout);
